import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import BreadCrumb from '../components/elements/breadCrumb'
import {default as ClientModal} from '../components/Modals/generalModal'
import { setBreadCrumb } from '../config/slice/others.slice'
import { resolveUrl } from '../config/helpers'
import DimensionContent from '../components/score/dimensions'
import SummaryContent from '../components/score/summary'
import { toast } from 'react-toastify'
import { getTeam } from '../actions/team'
import { getMembersByIdPromise } from '../actions/members'
import teamService from '../services/team.service'

function Profile() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { search: urlSearch } = useLocation()
    let params = new URLSearchParams(urlSearch)
    let profileId = params.get('id')
    let frm = params.get('frm')
    const [loading, setLoading] = useState(false)
    const { loading: userLoading, user: user } = useSelector(
        state => state.user
    )
    const { query: searchQuery } = useSelector(state => state.search)
    const urlSearchParams = new URLSearchParams(searchQuery)
    const { loading: memberLoading, member: member } = useSelector(
        state => state.member
    )
    const {
        loading: teamLoading,
        team: myTeam,
        subProviders
    } = useSelector(state => state.team)
    const subs = useSelector(state => state.subs.value)
    const [showClientModal, setShowClientModal] = useState(false)

    const m = member
    const providerCheck =
        m.intent == 'Client' &&
        user.intent == 'Provider' &&
        myTeam?.members?.find(x => x?.id == m.id)
    const myTeamCheck =
        m.intent != 'Provider' && !myTeam?.members?.find(x => x?.id == m.id)

    const clientInviteCheck =
        myTeam?.members?.length <
        parseInt(user.cSeats) + parseInt(subs?.subscription?.seatCount)

    const title = 'Profile | Cardeon - Member Profile'
    const url = 'https://cardeon.io/profile'
    const desc = 'View member profile.'

    const belongTeamPromoState = () => {
        if (!user?.appliedTeamPromo) {
            return false
        }
        return user?.appliedTeamPromoState
    }

    const teamCheck =
        (user?.intent !== m?.intent && user?.dimensions != null) ||
        (((myTeam?.agreement && !subs?.subscription?.delinquent) ||
            belongTeamPromoState()) &&
            (m?.dimensions != null ||
                (m?.team?.agreement && !m?.team?.subscription?.delinquent)) &&
            ((user?.intent == 'Client' &&
                (myTeam === null || (myTeam?.id === m?.id ? false : true))) ||
                (user?.intent == 'Provider' &&
                    (m?.team === null ||
                        (m?.team?.id === myTeam?.id ? false : true)))))

    async function goBack() {
        navigate(-1)
    }

    useEffect(() => {
        if (m?.firstname) {
            if (frm == 'search') {
                dispatch(
                    setBreadCrumb([
                        { name: 'My Team', path: '/my-team' },
                        {
                            name: 'Member Search',
                            path: `/search`
                        },
                        {
                            name: 'Search Results',
                            path: `/search/memberresults?${urlSearchParams}`
                        },
                        {
                            name: `Profile: ${m.firstname} ${m.lastname}.`,
                            path: `/profile?id=${m.id}`
                        }
                    ])
                )
            } else {
                dispatch(
                    setBreadCrumb([
                        { name: 'My Team', path: '/team' },
                        {
                            name: `Profile: ${m.firstname} ${m.lastname}.`,
                            path: `/profile?id=${m.id}`
                        }
                    ])
                )
            }
        }
    }, [profileId, m?.firstname])

    useEffect(() => {
        async function init() {
            setLoading(true)
            dispatch(getTeam())
            dispatch(
                getMembersByIdPromise({
                    id: profileId,
                    callBacks: {
                        success: () => {
                            setLoading(false)
                        },
                        err: () => {
                            setLoading(false)
                            toast.error('Error fetching profile.', {
                                position: 'top-right',
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined
                            })
                        }
                    }
                })
            )
        }
        init()
    }, [])

    const sendInvite = async () => {
        setLoading(true)
        setShowClientModal(false)
        try {
            let prettyJson = {
                client: m?.id
            }
            let res = await teamService.inviteClient(user.team.id, prettyJson)
            if (res) {
                toast.success('Invite sent.', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
            toast.error('Error Inviting Client.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    }

    if (loading || memberLoading) {
        return (
            <div className="page-account flex-c flex-grow-1 align-items-center justify-content-center bckg-white">
                <span className="gray-600 font-28">
                    <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                </span>
            </div>
        )
    } else {
        return (
            <>
                <BreadCrumb />
                <div className="page-content flex-c flex-grow-1 interior-view">
                    <Helmet>
                        <title>{title}</title>
                        <meta name="description" content={desc} />
                        <link rel="canonical" href={url} />
                        <meta property="og:url" content={url} />
                        <meta property="og:title" content={title} />
                        <meta property="og:description" content={desc} />
                        <meta name="twitter:title" content={title} />
                        <meta name="twitter:description" content={desc} />
                    </Helmet>
                    <div className="flex-c container">
                        <div className="flex-c row align-items-center mt-3">
                            <div className="flex-c col-md-8 col-lg-6 mt-4">
                                <div className="flex-c flex-lg-r align-items-lg-center mt-4">
                                    <div className="flex-c flex-1">
                                        <button
                                            className="touch touch-pop"
                                            onClick={goBack}
                                            type="button"
                                            title="Return">
                                            <i className="fa-solid fa-arrow-left"></i>
                                        </button>
                                    </div>
                                    <div
                                        className={`pill pill-sm pill-sm-sq bckg-g-l m-0 ${
                                            m.visible ? 'green' : 'red'
                                        }`}>
                                        <span>
                                            {m.visible ? 'Active' : 'Inactive'}
                                        </span>
                                    </div>
                                    <div className="flex-c flex-1 align-items-end">
                                        {myTeamCheck &&
                                            teamCheck &&
                                            !m.blocked?.includes(user.id) &&
                                            !user.blocked?.includes(m.id) &&
                                            clientInviteCheck && (
                                                <button
                                                    className="touch touch-pop"
                                                    onClick={e => {
                                                        setShowClientModal(true)
                                                    }}
                                                    type="button"
                                                    title="Invite Member">
                                                    <i className="fa-solid fa-user-plus font-14"></i>
                                                </button>
                                            )}
                                    </div>
                                </div>
                                <div className="flex-c mt-4">
                                    <div
                                        className="flex-c align-self-center img-user img-user-xl img-frame"
                                        style={{
                                            backgroundColor: m.gravatar
                                        }}>
                                        {m.profilemedia ? (
                                            <img
                                                src={resolveUrl(m.profilemedia)}
                                                alt={m.name}
                                                title="Profile Image"
                                            />
                                        ) : (
                                            <div className="flex-r align-items-center justify-content-center flex-grow-1 white fw-500">
                                                <span className="font-64 fw-500">
                                                    {m.firstname &&
                                                        m.firstname[0]}
                                                    {m.firstname &&
                                                        m.lastname[0]}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <h1 className="flex-3 text-lg-c font-28 mt-3">
                                        {m?.intent == 'Provider' &&
                                        m?.team != null ? (
                                            <span className="fw-500">
                                                {m.team?.name}
                                            </span>
                                        ) : (
                                            <>
                                                {m.firstname} {m.lastname},{' '}
                                                <span className="fw-300">
                                                    {m.age}
                                                </span>
                                            </>
                                        )}
                                    </h1>
                                </div>
                                <div className="flex-c mt-5">
                                    <span className="fw-500 font-20 mb-3">
                                        Details
                                    </span>
                                    <div className="flex-r">
                                        <div className="flex-r pill pill-sm border-g300">
                                            <i
                                                className="fa-solid fa-user-circle font-16"
                                                title="Team Member User Type"></i>
                                            <span className="font-14 ml-2">
                                                {m.intent}
                                            </span>
                                        </div>
                                        {m.state && (
                                            <div className="flex-r pill pill-sm border-g300 ml-1">
                                                <i
                                                    className="fa-solid fa-user-circle font-16"
                                                    title="Team Member Location"></i>
                                                <span className="font-14 ml-2">
                                                    USA: {m.state}
                                                </span>
                                            </div>
                                        )}
                                        {m.gender !== 'undefined' && (
                                            <div className="flex-r pill pill-sm border-g300 ml-1">
                                                <i
                                                    className={`fa-solid font-16 ${
                                                        m.gender === 'male'
                                                            ? 'fa-mars'
                                                            : 'fa-venus'
                                                    }`}
                                                    title="Team Member Gender"></i>
                                                <span className="font-14 ml-2">
                                                    {m.gender}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="flex-c mt-5">
                                    <span className="fw-500 font-20 mb-3">
                                        About
                                    </span>
                                    <div className="basic-wrapper">
                                        {m.summary
                                            ? m.summary
                                            : `Hello! Welcome to my profile, my name is ${m.firstname}.`}
                                    </div>
                                </div>

                                {(m.scoreTeams || providerCheck) &&
                                    m.team?.length > 0 &&
                                    m.intent == 'Client' && (
                                        <div className="flex-c mt-5">
                                            <span className="fw-500 font-20 mb-3">
                                                Teams
                                            </span>
                                            {m.team.map((item, index) => (
                                                <div
                                                    key={index}
                                                    className={`flex-r align-items-center basic-wrapper ${
                                                        m.team.length - 1 !==
                                                        index
                                                            ? 'mb-2'
                                                            : ''
                                                    }`}>
                                                    <div
                                                        className="flex-c img-user img-user-md mr-3"
                                                        style={{
                                                            backgroundColor:
                                                                item.gravatar
                                                        }}>
                                                        {item.profilemedia ? (
                                                            <img
                                                                src={resolveUrl(
                                                                    item.profilemedia
                                                                )}
                                                                className="white fw-500"
                                                                alt={
                                                                    item.initial
                                                                }
                                                                title="Profile Image"
                                                            />
                                                        ) : (
                                                            <div className="flex-r align-items-center justify-content-center flex-grow-1 white fw-500">
                                                                <span>
                                                                    {
                                                                        item.initial
                                                                    }
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <span className="fw-500">
                                                        {item.name}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    )}

                                {(m.scoreBadges || providerCheck) &&
                                    m.dimensions &&
                                    m.intent == 'Client' && (
                                        <div className="flex-c mt-5">
                                            <span className="fw-500 font-20 mb-3">
                                                Badges
                                            </span>
                                            {m.badges.map((item, index) => (
                                                <div
                                                    key={index}
                                                    className={`flex-r align-items-center basic-wrapper ${
                                                        m.badges.length - 1 !==
                                                        index
                                                            ? 'mb-2'
                                                            : ''
                                                    }`}>
                                                    <div className="flex-c img-user img-user-md mr-3">
                                                        <img
                                                            src={resolveUrl(
                                                                item.url
                                                            )}
                                                            className="white fw-500"
                                                            alt={item.initial}
                                                            title="Profile Image"
                                                        />
                                                    </div>
                                                    <div className="flex-c">
                                                        <span className="fw-500 mb-1">
                                                            {item.box}
                                                        </span>
                                                        <span className="font-14">
                                                            {item.pack}
                                                        </span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}

                                {(m.scoreEngagements || providerCheck) &&
                                    m.dimensions !== null &&
                                    m.intent == 'Client' && (
                                        <div className="flex-c mt-5">
                                            <span className="fw-500 font-20 mb-3">
                                                Engagements
                                            </span>
                                            <div className="flex-r basic-wrapper">
                                                <div className="flex-r flex-1 justify-content-center align-items-center">
                                                    <div className="flex-c align-items-center justify-content-center bckg-yellow circle-sm">
                                                        <i
                                                            className="fa-solid fa-star font-12 white"
                                                            title="Team Member User Type"></i>
                                                    </div>
                                                    <span className="fw-500 font-18 ml-2 mr-1">
                                                        {m.cards?.score}
                                                    </span>
                                                    <span className="gray-700 font-18">
                                                        Points
                                                    </span>
                                                </div>
                                                <span className="flex-r divider-y bckg-g400"></span>
                                                <div className="flex-r flex-1 justify-content-center align-items-center">
                                                    <div className="flex-c align-items-center justify-content-center bckg-secondary circle-sm">
                                                        <i
                                                            className="fa-solid fa-layer-group font-12 white"
                                                            title="Team Member User Type"></i>
                                                    </div>
                                                    <span className="fw-500 font-18 ml-2 mr-1">
                                                        {m.cards?.count}
                                                    </span>
                                                    <span className="gray-700 font-18">
                                                        Cards
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                {(m.scoreEngagements || providerCheck) &&
                                    m.dimensions !== null &&
                                    m.intent == 'Client' && (
                                        <>
                                            <div className="flex-c mt-5">
                                                <span className="fw-500 font-20 mb-3">
                                                    Dimensions
                                                </span>
                                            </div>
                                            <DimensionContent />
                                        </>
                                    )}

                                {(m.scoreVisible || providerCheck) &&
                                    m.dimensions != null &&
                                    m.intent == 'Client' && (
                                        <>
                                            <div className="flex-c mt-5">
                                                <span className="fw-500 font-20 mb-3">
                                                    Summary
                                                </span>
                                            </div>
                                            <SummaryContent />
                                        </>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
                {showClientModal && (
                    <ClientModal
                        showModal={setShowClientModal}
                        title={'Invite Member'}
                        children={
                            <>
                                <p className="lh-24 mt-3 mb-4">
                                    Are you sure you want to invite{' '}
                                    <span className="fw-500">
                                        {m?.firstname}.
                                    </span>
                                    ? You can revoke this invitation at any
                                    time. Do you want to proceed?
                                </p>
                                <button
                                    className="btn btn-secondary mb-4"
                                    onClick={() => {
                                        sendInvite()
                                    }}>
                                    Yes, Invite
                                </button>
                                <div className="text-c mb-3">
                                    <a
                                        className="gray-700 hover fw-500"
                                        onClick={e => {
                                            setShowClientModal(false)
                                            e.stopPropagation()
                                        }}>
                                        Cancel
                                    </a>
                                </div>
                            </>
                        }
                    />
                )}
            </>
        )
    }
}

export default Profile
