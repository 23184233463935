import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import authService from '../../services/auth.service'
import { userSubs } from '../../config/slice/subs.slice'
import { selectedPlan } from '../../config/slice/selectedPlan.slice'
import { modalState } from '../../config/slice/component.slice'
import { toast } from 'react-toastify'
import { useNavigate, Link, useParams, useLocation } from 'react-router-dom'
import moment from 'moment'
import 'react-toastify/dist/ReactToastify.css'
import { getSingleBox, getTemplates } from '../../actions/Content/box'
import { createPack, createPackFromTemplate } from '../../actions/Content/pack'
import TextInput from '../../components/inputs/TextInput'
import DropDownInput from '../../components/inputs/DropDownInput'
import { getTeam } from '../../actions/team'
import { AssigneeSelectionModal } from '../../components/Modals/assigneeSelection'
import { resolveUrl, formatData } from '../../config/helpers'
import BreadCrumb from '../../components/elements/breadCrumb'
import { setBreadCrumb } from '../../config/slice/others.slice'
import { PACK_TYPE, PACK_TYPE_ARR } from '../../config/helpers/constants'

function PackCreate() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { search } = useLocation()
    let params = new URLSearchParams(search)
    let boxId = params.get('boxId')
    const [loading, setLoading] = useState(false)
    const { loading: userLoading, user: user } = useSelector(
        state => state.user
    )
    const modal = useSelector(state => state.modal.value)
    const subs = useSelector(state => state.subs.value)
    const {
        loading: boxLoading,
        singleBox,
        templates
    } = useSelector(state => state.boxes)
    const { loading: packLoading } = useSelector(state => state.pack)
    const { team } = useSelector(state => state.team)
    const others = useSelector(state => state.others)
    const typeList = PACK_TYPE_ARR
    const formatList = ['New', 'Template']
    const [inputValue, setInput] = useState({
        format: formatList[0],
        type: typeList[0],
        name: '',
        category: others?.packCategoryList[0],
        difficulty: others?.difficultyList[0],
        lockSelfTotal: '',
        removable: true,
        dimension: others?.dimensionList[0],
        summary: '',
        order: 0,
        lockId: null,
        lockName: null,
        price: 0,
        boxTemplate: null,
        packTemplate: null
    })
    const [assigneesValue, setAssigneesValue] = useState({})
    const [templatePacks, setTemplatePacks] = useState([])
    const [showAssigneeModal, setShowAssigneeModal] = useState(false)
    const { breadCrumbs } = useSelector(state => state.others)

    const setAssignee = assignee => {
        if (!assignee) {
            setAssigneesValue({})
        } else if (assignee == 'all') {
            setAssigneesValue(getAllAssignees())
        } else {
            setAssigneesValue({ ...assigneesValue, [assignee.id]: assignee })
        }
    }

    const getAllAssignees = () => {
        const all = {}
        team?.members?.forEach(m => {
            all[m.id] = m
        })
        return all
    }

    const removeAssignee = assignee => {
        const newAssignees = { ...assigneesValue }
        delete newAssignees[assignee.id]
        setAssigneesValue(newAssignees)
    }

    const getAssignees = () => {
        const assigneesArr = []
        for (const key in assigneesValue) {
            assigneesArr.push(assigneesValue[key])
        }
        return assigneesArr
    }

    const prepareAssignees = () => {
        let finalAssignees = {}
        for (const key in assigneesValue) {
            finalAssignees[key] = {}
        }
        return finalAssignees
    }

    const AssigneeComponent = () => {
        return (
            <div className="input-wrapper">
                <div className="mb-2">
                    <label>Assignee(s)</label>
                </div>
                {getAssignees().length > 0 ? (
                    getAssignees().length != 1 ? (
                        <div className="file-upload-done-wrapper bckg-white border-bottom-g300 border-r-6 flex-r justify-content-between align-items-center">
                            <div className="flex-r align-items-center">
                                <span className="font-16 fw-500">Multiple</span>
                                <span className="font-16 fw-300 gray-700 ml-1">
                                    ({getAssignees().length})
                                </span>
                            </div>
                            <a
                                className="brand-secondary fw-500 hover"
                                onClick={() => {
                                    setShowAssigneeModal(true)
                                }}
                                title="Change Assignee(s)">
                                Change Assignee(s)
                            </a>
                        </div>
                    ) : (
                        getAssignees().map((a, i) => (
                            <div
                                key={i}
                                className="file-upload-done-wrapper bckg-white border-bottom-g300 border-r-6 flex-r justify-content-between align-items-center">
                                <div className="flex-r align-items-center">
                                    {a?.profilemedia ? (
                                        <div className="img-file">
                                            <img
                                                src={resolveUrl(
                                                    a?.profilemedia
                                                )}
                                                alt="User profile image"
                                                title="User profile Image"
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            className="flex-c img-file"
                                            style={{
                                                backgroundColor: a?.gravatar
                                            }}>
                                            <div className="flex-r align-items-center justify-content-center flex-grow-1 white fw-500 font-20">
                                                <span>{`${a?.firstname[0]}${a?.lastname[0]}`}</span>
                                            </div>
                                        </div>
                                    )}
                                    <span className="font-20 fw-500 ml-3">{`${a?.firstname} ${a?.lastname[0]}.`}</span>
                                </div>
                                <a
                                    className="brand-secondary fw-500 hover mr-3"
                                    onClick={() => {
                                        setShowAssigneeModal(true)
                                    }}
                                    title="Change Assignee(s)">
                                    Change Assignee(s)
                                </a>
                            </div>
                        ))
                    )
                ) : (
                    <>
                        <div
                            className="form-control flex-or-r align-items-center justify-content-between hover-itm"
                            onClick={() => {
                                setShowAssigneeModal(true)
                            }}>
                            <p className="m-0">Please Select</p>
                            <i className="fa-solid fa-users-viewfinder font-20 gray-700"></i>
                        </div>
                    </>
                )}
                {showAssigneeModal && (
                    <AssigneeSelectionModal
                        showModal={setShowAssigneeModal}
                        members={team?.members}
                        assigneesValue={assigneesValue}
                        removeAssignee={removeAssignee}
                        setAssignee={setAssignee}
                    />
                )}
            </div>
        )
    }

    const handleOnChange = e => {
        setInput({
            ...inputValue,
            [e.target.name]: e.target.value
        })
    }

    const handleBoxTemplateChange = e => {
        const [selected] = templates.filter(t => t.id == e.target.value)
        setInput({ ...inputValue, boxTemplate: e.target.value })
        setTemplatePacks(selected.packs)
    }

    const getLockedByPacks = () => {
        const filteredPack = singleBox?.packs.filter(
            p => (p?.order || 0) < (inputValue.order || 0)
        )
        return [
            { value: '', label: 'None' },
            ...formatData(filteredPack, 'name', 'id')
        ]
    }

    const defineDifficulty = item => {
        let difficulty

        if (item == 1) {
            difficulty = 'Easy'
        } else if (item == 2) {
            difficulty = 'Moderate'
        } else if (item == 3) {
            difficulty = 'Difficult'
        } else if (item == 4) {
            difficulty = 'Hard'
        } else {
            difficulty = 'Extreme'
        }

        return difficulty
    }

    const getDifficultyOptions = options => {
        return options.map(i => {
            return {
                value: i,
                label: defineDifficulty(i)
            }
        })
    }

    const formatPriceList = data => {
        const list = data.map(d => {
            return {
                label: d,
                value: d
            }
        })
        return [{ value: '0', label: 'None' }, ...list]
    }

    const title = 'Content | Cardeon - My Content'
    const url = 'https://cardeon.io/content'
    const desc = 'Review and manage your client content.'

    useEffect(() => {
        async function init() {
            setLoading(true)
            if (Object.keys(user).length > 0) {
                if (!user.agreement) {
                    document.body.classList.add('o-flow-y-hide')
                    dispatch(modalState(true))
                }
                dispatch(getSingleBox({ boxId: boxId, callBacks: {} }))
                dispatch(getTeam())
                dispatch(getTemplates())
                setLoading(false)
            }
        }
        init()
    }, [user])

    useEffect(() => {
        if (boxId && singleBox?.name) {
            const crumbsToAdd = [
                {
                    name: 'Boxes',
                    path: '/content'
                },
                {
                    name: singleBox?.name,
                    path: `/content/box/${singleBox?.id}`
                },
                {
                    name: 'New Pack',
                    path: `/content/pack/create?boxId=${boxId}`
                }
            ]
            dispatch(setBreadCrumb(crumbsToAdd))
        }
    }, [boxId, singleBox?.name])

    const handleCreateFromTemplate = () => {
        if (
            inputValue.type != PACK_TYPE.MY_TEAM &&
            Object.keys(assigneesValue).length < 1
        ) {
            toast.error('Assignee Required', { autoClose: 1000 })
            return
        }
        if (inputValue.boxTemplate == '' || inputValue.boxTemplate == null) {
            toast.error('Invalid Box Template', { autoClose: 1000 })
            return
        }
        if (inputValue.packTemplate == '' || inputValue.packTemplate == null) {
            toast.error('Invalid Pack Template', { autoClose: 1000 })
            return
        }
        if (inputValue.name == '') {
            toast.error('Invalid Name', { autoClose: 1000 })
            return
        }
        let prettyJson = {
            packName: inputValue.name,
            packTemplateId: inputValue.packTemplate,
            boxId: singleBox.id,
            type: inputValue.type
        }
        if (inputValue.type != PACK_TYPE.MY_TEAM) {
            prettyJson.assignees = prepareAssignees()
        }
        dispatch(
            createPackFromTemplate({
                payload: prettyJson,
                callBacks: {
                    success: () => {
                        setLoading(false)
                        navigate(`/content/box/${boxId}`)
                    },
                    err: () => {
                        setLoading(false)
                    }
                }
            })
        )
    }

    const handleCreate = () => {
        if (
            inputValue.type != PACK_TYPE.MY_TEAM &&
            Object.keys(assigneesValue).length < 1
        ) {
            toast.error('Assignee Required', { autoClose: 1000 })
            return
        }
        if (inputValue.format != 'New') {
            handleCreateFromTemplate()
            return
        }
        if (inputValue.name == '') {
            toast.error('Invalid Name', { autoClose: 1000 })
            return
        }
        if (!inputValue.lockSelfTotal) {
            toast.error('Invalid Required Cards', { autoClose: 1000 })
            return
        }
        if (!inputValue.summary) {
            toast.error('Invalid Summary', { autoClose: 1000 })
            return
        }

        let prettyJson = {
            name: inputValue.name,
            category: inputValue.category,
            box: singleBox.name,
            boxId: singleBox.id,
            order: inputValue.order,
            difficulty: parseInt(inputValue.difficulty),
            lockSelfTotal: parseInt(inputValue.lockSelfTotal) || 28,
            dimension: inputValue?.dimension.toLowerCase(),
            removable: inputValue.removable === 'true',
            summary: inputValue.summary,
            type: inputValue.type,
            ...(inputValue.lockId != null &&
            inputValue.lockId != '' &&
            inputValue.order != 0
                ? {
                      lockId: inputValue.lockId,
                      lockName:
                          singleBox?.packs?.find(
                              el => el?.id === inputValue.lockId
                          )?.name || ''
                  }
                : {}),
            price: parseInt(inputValue.price)
        }
        if (inputValue.type != PACK_TYPE.MY_TEAM) {
            prettyJson.assignees = prepareAssignees()
        }
        setLoading(true)
        dispatch(
            createPack({
                payload: prettyJson,
                callBacks: {
                    success: () => {
                        setLoading(false)
                        navigate(`/content/box/${boxId}`)
                    },
                    err: () => {
                        setLoading(false)
                    }
                }
            })
        )
    }

    async function goBack() {
        navigate(`/content/box/${boxId}`)
    }

    if (loading || packLoading || userLoading) {
        return (
            <div className="page-content flex-c flex-grow-1 align-items-center justify-content-center bckg-white">
                <span className="gray-600 font-28">
                    <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                </span>
            </div>
        )
    } else {
        return (
            <>
                <BreadCrumb />
                <div className="page-content flex-c flex-grow-1 interior-view">
                    <Helmet>
                        <title>{title}</title>
                        <meta name="description" content={desc} />
                        <link rel="canonical" href={url} />
                        <meta property="og:url" content={url} />
                        <meta property="og:title" content={title} />
                        <meta property="og:description" content={desc} />
                        <meta name="twitter:title" content={title} />
                        <meta name="twitter:description" content={desc} />
                    </Helmet>
                    <div className="flex-c container">
                        <div className="flex-c row align-items-center mt-3">
                            <div className="flex-c col-md-8 col-lg-6 mt-4">
                                <div className="flex-c flex-lg-r align-items-lg-center mt-4">
                                    <div className="flex-c flex-1">
                                        <button
                                            className="touch touch-pop"
                                            onClick={goBack}
                                            type="button"
                                            title="Cancel">
                                            <i className="fa-solid fa-arrow-left"></i>
                                        </button>
                                    </div>
                                    <h1 className="flex-3 text-lg-c mt-3 mt-lg-0">
                                        {inputValue.name
                                            ? inputValue.name
                                            : 'Pack Details'}
                                    </h1>
                                    <div className="flex-1" />
                                </div>
                                <p className="text-lg-c lh-20 mt-1 mb-3">
                                    Add details for your new pack.
                                </p>
                                <h3 className="mb-2 mt-4">Pack Details</h3>
                                <span className="divider mb-3" />

                                <form onSubmit={e => e.preventDefault()}>
                                    <TextInput
                                        name={'name'}
                                        label={'Name'}
                                        value={inputValue.name}
                                        placeholder={
                                            'e.g Beginners - max 25 characters'
                                        }
                                        maxLength={'25'}
                                        required={true}
                                        onChange={handleOnChange}
                                    />
                                    <DropDownInput
                                        name={'format'}
                                        label={'Format'}
                                        required={false}
                                        value={inputValue.format}
                                        onChange={handleOnChange}
                                        options={formatData(formatList)}
                                    />
                                    <DropDownInput
                                        name={'type'}
                                        label={'Type'}
                                        required={false}
                                        disabled={team?.members?.length < 1}
                                        value={inputValue.type}
                                        onChange={handleOnChange}
                                        options={formatData(typeList)}
                                        title={
                                            !team?.members?.length < 1
                                                ? 'Type'
                                                : 'Disabled'
                                        }
                                        marginBottom={
                                            team?.members?.length < 1 ||
                                            inputValue.type ===
                                                PACK_TYPE.ASSIGNED
                                        }
                                    />
                                    {team?.members?.length < 1 && (
                                        <span className="flex-r lh-24 font-14 gray-700 mb-4">
                                            Add members to your team in order to
                                            assign packs.
                                        </span>
                                    )}
                                    {inputValue.type === PACK_TYPE.ASSIGNED && (
                                        <span className="flex-r lh-xl font-xs gray-700 mb-4">
                                            Pack type can be updated at any
                                            time. Assignees can also be added or
                                            removed at any time.
                                        </span>
                                    )}
                                    {inputValue.type == PACK_TYPE.ASSIGNED && (
                                        <AssigneeComponent />
                                    )}
                                    {inputValue.format != 'New' && (
                                        <>
                                            <DropDownInput
                                                name={'boxTemplate'}
                                                label={'Box Template'}
                                                required={true}
                                                value={inputValue.boxTemplate}
                                                onChange={
                                                    handleBoxTemplateChange
                                                }
                                                options={[
                                                    {
                                                        value: '',
                                                        label: 'Please Select'
                                                    },
                                                    ...formatData(
                                                        templates,
                                                        'name',
                                                        'id'
                                                    )
                                                ]}
                                            />
                                            <DropDownInput
                                                name={'packTemplate'}
                                                label={'Pack Template'}
                                                required={true}
                                                value={inputValue.packTemplate}
                                                onChange={handleOnChange}
                                                lastItem={true}
                                                options={[
                                                    {
                                                        value: '',
                                                        label: 'Please Select'
                                                    },
                                                    ...formatData(
                                                        templatePacks || [],
                                                        'templateName',
                                                        'id'
                                                    )
                                                ]}
                                            />
                                        </>
                                    )}
                                    {inputValue.format == 'New' && (
                                        <>
                                            <DropDownInput
                                                name={'category'}
                                                label={'Category'}
                                                value={inputValue.category}
                                                onChange={handleOnChange}
                                                options={formatData(
                                                    others?.packCategoryList
                                                )}
                                            />
                                            <DropDownInput
                                                name={'difficulty'}
                                                label={'Difficulty'}
                                                value={inputValue.difficulty}
                                                onChange={handleOnChange}
                                                options={getDifficultyOptions(
                                                    others?.difficultyList
                                                )}
                                            />
                                            <DropDownInput
                                                name={'order'}
                                                label={'Order'}
                                                value={inputValue.order}
                                                onChange={handleOnChange}
                                                options={formatData(
                                                    others?.itemOrderList
                                                )}
                                            />
                                            {inputValue.order != 0 && (
                                                <DropDownInput
                                                    name={'lockId'}
                                                    label={'Locked By'}
                                                    value={inputValue.lockId}
                                                    onChange={handleOnChange}
                                                    options={getLockedByPacks()}
                                                />
                                            )}
                                            <TextInput
                                                type={'number'}
                                                name={'lockSelfTotal'}
                                                label={'Minimum Required'}
                                                value={inputValue.lockSelfTotal}
                                                placeholder={
                                                    'e.g Number: 1 - 28'
                                                }
                                                required={true}
                                                onChange={handleOnChange}
                                            />
                                            <DropDownInput
                                                name={'removable'}
                                                label={'Removable'}
                                                value={inputValue.removable}
                                                onChange={handleOnChange}
                                                options={[
                                                    {
                                                        value: 'true',
                                                        label: 'Yes'
                                                    },
                                                    {
                                                        value: 'false',
                                                        label: 'No'
                                                    }
                                                ]}
                                            />
                                            <DropDownInput
                                                name={'dimension'}
                                                label={'Dimension'}
                                                value={inputValue.dimension}
                                                onChange={handleOnChange}
                                                options={formatData(
                                                    others?.dimensionList
                                                )}
                                            />
                                            {inputValue.dimension !==
                                                'balanced' && (
                                                <DropDownInput
                                                    name={'price'}
                                                    label={'Price'}
                                                    value={inputValue.price}
                                                    onChange={handleOnChange}
                                                    options={formatPriceList(
                                                        others?.priceList
                                                    )}
                                                />
                                            )}
                                            <TextInput
                                                name={'summary'}
                                                label={'Summary'}
                                                value={inputValue.summary}
                                                placeholder={
                                                    'Describe the contents of this pack in less than 1,000 characters'
                                                }
                                                maxLength={'1000'}
                                                required={true}
                                                onChange={handleOnChange}
                                                textarea={true}
                                                lastItem={true}
                                            />
                                        </>
                                    )}
                                    <div
                                        onClick={handleCreate}
                                        className="btn btn-secondary mb-4"
                                        title="Create Pack">
                                        Create Pack
                                    </div>
                                    <div className="text-c">
                                        <Link
                                            className="gray-700 hover fw-500"
                                            to={`/content/box/${boxId}`}
                                            title="Cancel">
                                            Cancel
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default PackCreate
