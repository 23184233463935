import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { modalState } from '../../config/slice/component.slice'
import { toast } from 'react-toastify'
import { useNavigate, Link, useParams } from 'react-router-dom'
import moment from 'moment'
import 'react-toastify/dist/ReactToastify.css'
import subsService from '../../services/subs.service'
import memberService from '../../services/members.service'
import { getSingleBox } from '../../actions/Content/box'
import BoxPackCard from '../../components/cards/boxPackCard'
import { default as DeleteConfirmModal } from '../../components/Modals/generalModal'
import { copyPack, deletePack, updatePack } from '../../actions/Content/pack'
import BreadCrumb from '../../components/elements/breadCrumb'
import { setBreadCrumb } from '../../config/slice/others.slice'

function BoxContent() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const [loading, setLoading] = useState(false)
    const { loading: userLoading, user: user } = useSelector(
        state => state.user
    )
    const subs = useSelector(state => state.subs.value)
    const { loading: boxLoading, singleBox } = useSelector(state => state.boxes)
    const planSelection = useSelector(state => state.planSelection.value)
    const [currDel, setCurrDel] = useState(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [searchVal, setSearchVal] = useState('')
    const [filteredPacks, setFilteredPacks] = useState([])
    const [activeMenuIndex, setActiveMenuIndex] = useState(-1)
    const title = 'Content | Cardeon - My Content'
    const url = 'https://cardeon.io/content'
    const desc = 'Review and manage your client content.'

    const menuStateFunc = index => {
        setActiveMenuIndex(index)
    }

    useEffect(() => {
        async function init() {
            setLoading(true)
            if (Object.keys(user).length > 0) {
                if (!user.agreement) {
                    document.body.classList.add('o-flow-y-hide')
                    dispatch(modalState(true))
                }
                dispatch(getSingleBox({ boxId: params?.id, callBacks: {} }))
            }
            setLoading(false)
        }
        init()
    }, [user, params.id])

    useEffect(() => {
        if (singleBox?.name) {
            dispatch(
                setBreadCrumb([
                    {
                        name: 'Boxes',
                        path: '/content'
                    },
                    {
                        name: singleBox?.name,
                        path: `/content/box/${singleBox?.id}`
                    }
                ])
            )
        }
    }, [singleBox])

    useEffect(() => {
        if (searchVal == '') {
            setFilteredPacks(singleBox?.packs)
        }
        if (singleBox && singleBox?.packs) {
            const splittedSearch = searchVal.toLowerCase().split(',')
            const filtered = singleBox?.packs?.filter(p => {
                const words = p.name.toLowerCase().split(' ')
                return words.some(v => {
                    return (
                        splittedSearch.some(s => v.startsWith(s)) ||
                        v.startsWith(searchVal.toLowerCase())
                    )
                })
            })
            setFilteredPacks(filtered)
        }
    }, [searchVal])

    useEffect(() => {
        setFilteredPacks(singleBox?.packs)
    }, [singleBox?.packs])

    const searchHandler = e => {
        setSearchVal(e.target.value)
    }

    const copyPackFunc = packId => {
        setLoading(true)
        const payload = { packId: packId }
        dispatch(
            copyPack({
                payload: payload,
                callBacks: {
                    success: () => {
                        dispatch(getSingleBox({ boxId: params.id }))
                        setLoading(false)
                    },
                    err: () => {
                        setLoading(false)
                    }
                }
            })
        )
    }

    const deletePackFunc = packId => {
        setLoading(true)
        dispatch(
            deletePack({
                packId: packId,
                callBacks: {
                    success: () => {
                        dispatch(getSingleBox({ boxId: params.id }))
                        setLoading(false)
                    },
                    err: () => {
                        setLoading(false)
                    }
                }
            })
        )
    }

    const deleteConfirmModal = boxId => {
        setCurrDel(boxId)
        setShowDeleteModal(true)
    }

    const reassignPackFunc = pack => {
        let prettyJson = {
            type: pack.type,
            assignee: pack.assignee
        }
        const formData = new FormData()
        for (var key in prettyJson) formData.append(key, prettyJson[key])
        setLoading(true)
        dispatch(
            updatePack({
                packId: pack.id,
                payload: formData,
                callBacks: {
                    success: () => {
                        dispatch(getSingleBox({ boxId: params.id }))
                        setLoading(false)
                    },
                    err: () => {
                        setLoading(false)
                    }
                }
            })
        )
    }

    const activatePackFunc = packId => {
        setLoading(true)
        dispatch(
            updatePack({
                packId: packId,
                payload: { active: true },
                callBacks: {
                    success: () => {
                        dispatch(
                            getSingleBox({ boxId: params?.id, callBacks: {} })
                        )
                        toast.success('Pack activated', {
                            autoClose: 2000,
                            closeOnClick: true
                        })
                        setLoading(false)
                    },
                    err: err => {
                        toast.error(err.message, {
                            autoClose: 2000,
                            closeOnClick: true
                        })
                        setLoading(false)
                    }
                }
            })
        )
    }

    const deactivatePackFunc = packId => {
        setLoading(true)
        dispatch(
            updatePack({
                packId: packId,
                payload: { active: false },
                callBacks: {
                    success: () => {
                        dispatch(
                            getSingleBox({ boxId: params?.id, callBacks: {} })
                        )
                        toast.success('Pack deactivated', {
                            autoClose: 2000,
                            closeOnClick: true
                        })
                        setLoading(false)
                    },
                    err: err => {
                        toast.error(err.message, {
                            autoClose: 2000,
                            closeOnClick: true
                        })
                        setLoading(false)
                    }
                }
            })
        )
    }

    async function goBack() {
        navigate('/content')
    }

    if (loading || boxLoading || userLoading) {
        return (
            <div className="page-content flex-c flex-grow-1 align-items-center justify-content-center bckg-white">
                <span className="gray-600 font-28">
                    <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                </span>
            </div>
        )
    } else {
        return (
            <>
                <BreadCrumb />
                <div className="page-content flex-c flex-grow-1 interior-view">
                    <Helmet>
                        <title>{title}</title>
                        <meta name="description" content={desc} />
                        <link rel="canonical" href={url} />
                        <meta property="og:url" content={url} />
                        <meta property="og:title" content={title} />
                        <meta property="og:description" content={desc} />
                        <meta name="twitter:title" content={title} />
                        <meta name="twitter:description" content={desc} />
                    </Helmet>
                    <div className="flex-c px-4 mt-5">
                        <div className="flex-r align-items-center">
                            <button
                                className="touch touch-pop mr-3"
                                onClick={goBack}
                                type="button"
                                title="Go Back">
                                <i className="fa-solid fa-arrow-left"></i>
                            </button>
                            <div className="flex-r align-items-end">
                                <h1 className="flex-r font-24 lh-20 pr-1">
                                    {singleBox?.name}{' '}
                                </h1>
                                <span className="fw-300 font-16 gray-700 pl-2 lh-20">
                                    ({singleBox?.packs?.length})
                                </span>
                            </div>
                        </div>
                        <div className="flex-r my-4">
                            <div className="input-wrapper flex-1 mb-0">
                                <input
                                    onChange={searchHandler}
                                    type="text"
                                    className="form-control"
                                    id="search"
                                    name="search"
                                    value={searchVal}
                                    placeholder="Search"
                                    required
                                />
                            </div>
                            <Link
                                className="btn btn-primary-alt flex-0 ml-3"
                                to={`/content/pack/create?boxId=${singleBox?.id}`}
                                title="New Pack">
                                <span className="display-hide display-sm-flex">
                                    New Pack
                                </span>
                                <i className="display-sm-hide fa-solid fa-plus"></i>
                            </Link>
                        </div>

                        <div className="flex-c">
                            {filteredPacks?.length > 0 ? (
                                filteredPacks.map((pack, index) => (
                                    <BoxPackCard
                                        key={index}
                                        data={pack}
                                        type={'pack'}
                                        index={index}
                                        menuStateFunc={menuStateFunc}
                                        menuState={index == activeMenuIndex}
                                        actionPaths={{
                                            view: `/content/pack/${pack.id}`,
                                            edit: `/content/pack/edit?id=${pack.id}&boxId=${singleBox.id}`
                                        }}
                                        status={pack.active}
                                        statusFunc={
                                            pack.active
                                                ? deactivatePackFunc
                                                : activatePackFunc
                                        }
                                        copyFunction={copyPackFunc}
                                        reassignFunction={reassignPackFunc}
                                        deleteFunction={deleteConfirmModal}
                                        style={`border-g300 ${
                                            filteredPacks.length - 1 === index
                                                ? 'border-bottom-g300'
                                                : 'border-bottom-reset'
                                        } ${
                                            index === 0 ? 'border-r-6-top' : ''
                                        } ${
                                            filteredPacks.length - 1 === index
                                                ? 'border-r-6-bottom'
                                                : ''
                                        }`}
                                    />
                                ))
                            ) : (
                                <h2>No packs found.</h2>
                            )}
                            {showDeleteModal && (
                                <DeleteConfirmModal
                                    showModal={setShowDeleteModal}
                                    title={"Confirm delete"}
                                    children={
                                        <>
                                            <p className="lh-24 mt-3 mb-4">
                                                Are you sure you want to delete
                                                this pack? Deleting this pack
                                                will also delete all of its
                                                cards. This action can not be
                                                undone. Would you like to
                                                proceed?
                                            </p>
                                            <button
                                                className="btn btn-cancel mb-4"
                                                onClick={() => {
                                                    deletePackFunc(currDel)
                                                    setShowDeleteModal(false)
                                                }}>
                                                Delete
                                            </button>
                                            <div className="text-c mb-3">
                                                <a
                                                    className="gray-700 hover fw-500"
                                                    onClick={e => {
                                                        setShowDeleteModal(
                                                            false
                                                        )
                                                        e.stopPropagation()
                                                    }}>
                                                    Return
                                                </a>
                                            </div>
                                        </>
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default BoxContent
