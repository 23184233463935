import React from 'react'
import { useNavigate, Link } from 'react-router-dom'
import graphic from '../img/error.png'
import { Helmet } from 'react-helmet'

const title = 'Error | Cardeon'
const url = 'https://cardeon.io/error'
const desc = 'Something is broken at Cardeon.'

function Error() {
    const navigate = useNavigate()

    return (
        <div className="error flex-c flex-grow-1 interior-view">
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={desc} />
                <link rel="canonical" href={url} />
                <meta property="og:url" content={url} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={desc} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={desc} />
            </Helmet>
            <div className="flex-c container">
                <div className="flex-c row">
                    <div className="flex-c col-12 pt-5">
                        <div className="flex-c align-items-center">
                            <div className="flex-c text-c">
                                <h1 className="mt-4">Whoops!</h1>
                                <p className="lh-20 mt-1 mb-3">
                                    Something is definitely not right! Let's try
                                    that again.
                                </p>
                            </div>
                            <div className="flex-c col-md-8 col-lg-6 mt-4">
                                <img
                                    src={graphic}
                                    title="Unknown Error"
                                    alt="Unknown Error"
                                    className="align-self-center img-centered"
                                />
                                <div className="flex-c flex-lg-r mt-5">
                                    <Link
                                        to="/"
                                        className="btn btn-primary-alt mb-3 mb-lg-0 mr-lg-2"
                                        title="Go Home">
                                        Home
                                    </Link>
                                    <Link
                                        to={'..'}
                                        onClick={e => {
                                            e.preventDefault()
                                            navigate(-1)
                                        }}
                                        className="btn btn-secondary ml-lg-2"
                                        title="Go Back">
                                        Go Back
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Error
