import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { modalState } from '../../config/slice/component.slice'
import { toast } from 'react-toastify'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import moment from 'moment'
import 'react-toastify/dist/ReactToastify.css'
import { getSingleBox } from '../../actions/Content/box'
import { getSinglePack, updatePack } from '../../actions/Content/pack'
import TextInput from '../../components/inputs/TextInput'
import DropDownInput from '../../components/inputs/DropDownInput'
import { AssigneeSelectionModal } from '../../components/Modals/assigneeSelection'
import { resolveUrl, formatData } from '../../config/helpers'
import { createCard } from '../../actions/Content/card'
import { getLookups } from '../../actions/others'
import BreadCrumb from '../../components/elements/breadCrumb'
import { setBreadCrumb } from '../../config/slice/others.slice'

function CardCreate() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { search } = useLocation()
    let params = new URLSearchParams(search)
    let packId = params.get('packId')
    let dim = params.get('dim')
    const [loading, setLoading] = useState(false)
    const { loading: userLoading, user: user } = useSelector(
        state => state.user
    )
    const { loading: cardLoading } = useSelector(state => state.card)
    const { loading: packLoading, singlePack } = useSelector(
        state => state.pack
    )
    const others = useSelector(state => state.others)
    const [inputValue, setInput] = useState({
        name: '',
        type: others?.cardTypeList[0] || '',
        level: others?.difficultyList[0] || '',
        description: '',
        timelimit: others?.timeLimitList[0] || '',
        dataCompleted: false
    })
    const [answers, setAnswers] = useState({
        1: {
            name: '',
            description: '',
            value: 1
        },
        2: {
            name: '',
            description: '',
            value: 2
        },
        3: {
            name: '',
            description: '',
            value: 3
        },
        4: {
            name: '',
            description: '',
            value: 4
        },
        5: {
            name: '',
            description: '',
            value: 5
        }
    })

    const title = 'Content | Cardeon - My Content'
    const url = 'https://cardeon.io/content'
    const desc = 'Review and manage your client content.'

    const setAnswersFunc = options => {
        const answ = {}
        options.forEach(option => {
            answ[option.value] = option
        })
        setAnswers(answ)
    }

    useEffect(() => {
        async function init() {
            setLoading(true)
            if (Object.keys(user).length > 0) {
                if (!user.agreement) {
                    document.body.classList.add('o-flow-y-hide')
                    dispatch(modalState(true))
                }
                dispatch(
                    getSinglePack({
                        packId: packId,
                        callBacks: {
                            success: data => {
                                setLoading(false)
                            },
                            err: () => {
                                setLoading(false)
                            }
                        }
                    })
                )
            }
            dispatch(getLookups())
            setLoading(false)
        }
        init()
    }, [user])

    useEffect(() => {
        if (packId && singlePack?.name) {
            const crumbsToAdd = [
                {
                    name: 'Boxes',
                    path: '/content'
                },
                {
                    name: singlePack?.box,
                    path: `/content/box/${singlePack?.boxId}`
                },
                {
                    name: singlePack?.name,
                    path: `/content/pack/${singlePack.id}`
                },
                {
                    name: `New Card`,
                    path: `/content/card/create?packId=${packId}&dim=${dim}`
                }
            ]
            dispatch(setBreadCrumb(crumbsToAdd))
        }
    }, [packId, singlePack?.name])

    const handleOnChange = e => {
        setInput({
            ...inputValue,
            [e.target.name]: e.target.value
        })
    }

    const defineDifficulty = item => {
        let difficulty

        if (item == 1) {
            difficulty = 'Easy'
        } else if (item == 2) {
            difficulty = 'Moderate'
        } else if (item == 3) {
            difficulty = 'Difficult'
        } else if (item == 4) {
            difficulty = 'Hard'
        } else {
            difficulty = 'Extreme'
        }

        return difficulty
    }

    const getDifficultyOptions = options => {
        return options.map(i => {
            return {
                value: i,
                label: defineDifficulty(i)
            }
        })
    }

    const getTimeLimitOptions = options => {
        return options.map(i => {
            return {
                value: i,
                label: `${i} Hours`
            }
        })
    }

    const getAnswers = answers => {
        const answerArr = []
        for (const key in answers) {
            answerArr.push(answers[key])
        }
        return answerArr
    }

    const handleOnNameChange = e => {
        const targetName = Number(e.target.name)
        setAnswers({
            ...answers,
            [targetName]: { ...answers[targetName], name: e.target.value }
        })
    }

    const handleCreate = () => {
        if (inputValue.name == '') {
            toast.error('Invalid Name', { autoClose: 1000 })
            return
        }

        if (!inputValue.description) {
            toast.error('Invalid Description', { autoClose: 1000 })
            return
        }
        if (inputValue.dataCompleted) {
            for (const key in answers) {
                if (answers[key].name == '' || answers[key].description == '') {
                    toast.error('Invalid Answers & Descriptions', {
                        autoClose: 1000
                    })
                    return
                }
            }
        }
        let prettyJson = {
            name: inputValue.name,
            type: inputValue.type,
            level: parseInt(inputValue.level),
            description: inputValue.description,
            answers: getAnswers(answers),
            timelimit: inputValue.timelimit,
            dimension: dim,
            packId: packId
        }
        setLoading(true)
        dispatch(
            createCard({
                payload: prettyJson,
                callBacks: {
                    success: () => {
                        setLoading(false)
                        navigate(`/content/pack/${packId}`)
                    },
                    err: err => {
                        setLoading(false)
                    }
                }
            })
        )
    }

    const handleOnDescriptionChange = e => {
        const targetName = Number(e.target.name)
        setAnswers({
            ...answers,
            [targetName]: {
                ...answers[targetName],
                description: e.target.value
            }
        })
    }

    async function goBack() {
        navigate(`/content/pack/${packId}`)
    }

    if (loading || cardLoading || userLoading) {
        return (
            <div className="page-content flex-c flex-grow-1 align-items-center justify-content-center bckg-white">
                <span className="gray-600 font-28">
                    <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                </span>
            </div>
        )
    } else {
        return (
            <>
                <BreadCrumb />
                <div className="page-content flex-c flex-grow-1 interior-view">
                    <Helmet>
                        <title>{title}</title>
                        <meta name="description" content={desc} />
                        <link rel="canonical" href={url} />
                        <meta property="og:url" content={url} />
                        <meta property="og:title" content={title} />
                        <meta property="og:description" content={desc} />
                        <meta name="twitter:title" content={title} />
                        <meta name="twitter:description" content={desc} />
                    </Helmet>
                    <div className="flex-c container">
                        <div className="flex-c row align-items-center mt-3">
                            <div className="flex-c col-md-8 col-lg-6 mt-4">
                                <div className="flex-c flex-lg-r align-items-lg-center mt-4">
                                    <div className="flex-c flex-1">
                                        <button
                                            className="touch touch-pop"
                                            onClick={goBack}
                                            type="button"
                                            title="Cancel">
                                            <i className="fa-solid fa-arrow-left"></i>
                                        </button>
                                    </div>
                                    <h1 className="flex-3 text-lg-c mt-3 mt-lg-0">
                                        {inputValue.name
                                            ? inputValue.name
                                            : 'Card Details'}
                                    </h1>
                                    <div className="flex-1" />
                                </div>
                                <p className="text-lg-c lh-20 mt-1 mb-3">
                                    Add details for your new card.
                                </p>
                                <h3 className="mb-2 mt-4">Card Details</h3>
                                <span className="divider mb-3" />
                                <form onSubmit={e => e.preventDefault()}>
                                    <TextInput
                                        name={'name'}
                                        label={'Name'}
                                        value={inputValue.name}
                                        placeholder={
                                            'e.g Beginners - max 25 characters'
                                        }
                                        maxLength={'25'}
                                        required={true}
                                        onChange={handleOnChange}
                                    />
                                    <DropDownInput
                                        name={'type'}
                                        label={'Type'}
                                        required={false}
                                        value={inputValue.type}
                                        onChange={handleOnChange}
                                        options={formatData(
                                            others?.cardTypeList
                                        )}
                                    />
                                    <DropDownInput
                                        name={'level'}
                                        label={'Difficulty'}
                                        value={inputValue.level}
                                        onChange={handleOnChange}
                                        options={getDifficultyOptions(
                                            others?.difficultyList
                                        )}
                                    />
                                    <TextInput
                                        name={'description'}
                                        label={'Description'}
                                        value={inputValue.description}
                                        placeholder={
                                            'Describe the goal of this card in less than 500 characters'
                                        }
                                        maxLength={'500'}
                                        required={true}
                                        onChange={handleOnChange}
                                        textarea={true}
                                    />
                                    <DropDownInput
                                        name={'timelimit'}
                                        label={'Time Limit'}
                                        value={inputValue.timelimit}
                                        onChange={handleOnChange}
                                        options={getTimeLimitOptions(
                                            others?.timeLimitList
                                        )}
                                    />
                                    {getAnswers(answers)?.map((a, index) => (
                                        <div key={index}>
                                            <TextInput
                                                name={a.value}
                                                label={`Answer ${a.value} Name`}
                                                required={true}
                                                value={answers[a.value]?.name}
                                                placeholder={
                                                    'Answer Name - max 25 characters'
                                                }
                                                maxLength={'25'}
                                                onChange={handleOnNameChange}
                                            />
                                            <TextInput
                                                name={a.value}
                                                label={`Answer ${a.value} Description`}
                                                required={true}
                                                value={
                                                    answers[a.value]
                                                        ?.description
                                                }
                                                placeholder={
                                                    'Answer Description - max 100 characters'
                                                }
                                                maxLength={'100'}
                                                onChange={
                                                    handleOnDescriptionChange
                                                }
                                            />
                                        </div>
                                    ))}
                                    <div
                                        onClick={handleCreate}
                                        className="btn btn-secondary mb-4 mt-5"
                                        title="Create Card">
                                        Create Card
                                    </div>
                                    <div className="text-c">
                                        <Link
                                            className="gray-700 hover fw-500"
                                            to={`/content/pack/${packId}`}
                                            title="Cancel">
                                            Cancel
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default CardCreate
