import React from 'react'
import { useSelector } from 'react-redux'
import { PieChart } from 'react-minimal-pie-chart'
import { useLocation } from 'react-router-dom'

function SummaryContent() {
    const location = useLocation()
    const { pathname } = location
    const { loading: userLoading, user: user } = useSelector(
        state => state.user
    )
    const { loading: memberLoading, member: member } = useSelector(
        state => state.member
    )
    const m = pathname === '/profile' ? member : user

    return (
        <div className="flex-c basic-wrapper p-4">
            <div className="flex-c align-self-center circle-xl mt-4 mb-5">
                <PieChart
                    data={m.dimensions.items.map(item => ({
                        title:
                            item.type.charAt(0).toUpperCase() +
                            item.type.slice(1),
                        value: (item.score / item.total) * 100,
                        color: item.clrP
                    }))}
                    startAngle={270}
                    lineWidth={40}
                />
            </div>
            {[...m.dimensions.items]
                .sort((a, b) => {
                    if (
                        Math.round((a.score / m.cards.score) * 100) <
                        Math.round((b.score / m.cards.score) * 100)
                    ) {
                        return -1
                    }
                    return 1
                })
                .map((item, index) => (
                    <div
                        key={index}
                        className={`flex-r align-items-center ${
                            m.dimensions.items.length - 1 !== index
                                ? 'mb-4'
                                : 'mb-1'
                        }`}>
                        <i
                            className="fa-solid fa-circle font-12"
                            style={{ color: item.clrP }}></i>

                        <div className="flex-c flex-1 ml-2">
                            <span className="fw-500">
                                {item.type.charAt(0).toUpperCase() +
                                    item.type.slice(1)}
                            </span>
                        </div>

                        <span>
                            {`${Math.round(
                                (item.score / m.cards.score) * 100
                            )}` + '%'}
                        </span>
                    </div>
                ))}
        </div>
    )
}

export default SummaryContent
