import { createAsyncThunk } from '@reduxjs/toolkit'
import boxService from '../../services/box.service'

export const getAllBoxes = createAsyncThunk(
    'boxes/getAllBoxes',
    async (options, { rejectWithValue }) => {
        try {
            const data = await boxService.getAllBoxes()
            options?.callBacks?.success?.(data)
            return data
        } catch (error) {
            options?.callBacks?.err?.()
            return rejectWithValue(error)
        }
    }
)

export const getSingleBox = createAsyncThunk(
    'boxes/getSingleBox',
    async (options, { rejectWithValue }) => {
        try {
            const [data] = await boxService.getSingleBox(options.boxId)
            options?.callBacks?.success?.(data)
            return data
        } catch (error) {
            options?.callBacks?.err?.()
            return rejectWithValue(error)
        }
    }
)

export const updateBox = createAsyncThunk(
    'boxes/updateBox',
    async (options, { rejectWithValue }) => {
        try {
            const res = await boxService.update(options.boxId, options.payload)
            const data = await boxService.getAllBoxes()
            options?.callBacks?.success?.(data)
            return data
        } catch (error) {
            options?.callBacks?.err?.(error)
            return rejectWithValue(error?.response)
        }
    }
)

export const createBox = createAsyncThunk(
    'boxes/createBox',
    async (options, { rejectWithValue }) => {
        try {
            const res = await boxService.create(options.payload)
            const data = await boxService.getAllBoxes()
            options?.callBacks?.success?.(data)
            return data
        } catch (error) {
            options?.callBacks?.err?.()
            return rejectWithValue(error)
        }
    }
)

export const getTemplates = createAsyncThunk(
    'boxes/getTemplates',
    async (options, { rejectWithValue }) => {
        try {
            const data = await boxService.getTemplates()
            options?.callBacks?.success?.(data)
            return data
        } catch (error) {
            options?.callBacks?.err?.()
            return rejectWithValue(error)
        }
    }
)

export const copyBox = createAsyncThunk(
    'boxes/copyBox',
    async (options, { rejectWithValue }) => {
        try {
            const res = await boxService.copyBox(options.payload)
            const data = await boxService.getAllBoxes()
            options?.callBacks?.success?.(data)
            return data
        } catch (error) {
            options?.callBacks?.err?.()
            return rejectWithValue(error)
        }
    }
)

export const deleteBox = createAsyncThunk(
    'boxes/deleteBox',
    async (options, { rejectWithValue }) => {
        try {
            const res = await boxService.delete(options.boxId)
            const data = await boxService.getAllBoxes()
            options?.callBacks?.success?.(data)
            return data
        } catch (error) {
            options?.callBacks?.err?.()
            return rejectWithValue(error)
        }
    }
)
