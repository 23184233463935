import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { applyTeamPromo } from '../../actions/team'
import TextInput from '../inputs/TextInput'

function TeamCode() {
    const dispatch = useDispatch()
    const [teamCode, setTeamCode] = useState('')
    const [loading, setLoading] = useState(false)

    const { loading: userLoading, user: user } = useSelector(
        state => state.user
    )

    const copyToClipboard = async str => {
        try {
            await navigator.clipboard.writeText(str)
            toast.success('Code successfully copied to clipboard', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        } catch (error) {
            toast.error('Error copying code', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    }

    const applyCode = async () => {
        if (teamCode.length != 8) {
            toast.error('Invalid Team Code', { autoClose: 1000 })
            return
        }
        setLoading(true)
        dispatch(
            applyTeamPromo({
                teamPromo: teamCode,
                callBacks: {
                    success: data => {
                        setLoading(false)
                        toast.success(data?.message, {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        })
                    },
                    err: err => {
                        setLoading(false)
                        toast.error(err?.message, {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        })
                    }
                }
            })
        )
    }

    if (loading || userLoading) {
        return (
            <div className="page-content flex-c flex-grow-1 align-items-center justify-content-center bckg-white">
                <span className="gray-600 font-28">
                    <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                </span>
            </div>
        )
    }

    return (
        <>
            <div className="pt-4">
                <label htmlFor="">My Code</label>
                <div className="file-upload-done-wrapper bckg-g100 border-bottom-g300 border-r-6 flex-r justify-content-between align-items-center mt-2">
                    <div className="flex-r align-items-center">
                        <span className="font-16">{user?.teamPromo}</span>
                        <span className="font-16 fw-300 gray-700 ml-1"></span>
                    </div>
                    <a
                        className="brand-secondary fw-500 hover"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            copyToClipboard(user?.teamPromo)
                        }}
                        title="Copy">
                        Copy
                    </a>
                </div>

                <div className="input-desc">
                    You may share your team code with other Providers or Clients
                    to automatically join your team.
                </div>
            </div>
            <div className="py-4">
                <TextInput
                    name={'teamCode'}
                    label={'Team Code'}
                    value={teamCode}
                    placeholder={'Team Promo'}
                    maxLength={'8'}
                    style={'mb-0'}
                    onChange={e => {
                        setTeamCode(e.target.value)
                    }}
                />
                <div className="input-desc">
                    You may automatically join another team by entering the
                    corresponding Team Code.
                </div>
                <div
                    onClick={() => {
                        applyCode()
                    }}
                    className="btn btn-secondary mt-4"
                    title="Apply Code">
                    Apply Code
                </div>
            </div>
        </>
    )
}

export default TeamCode
