import { createAsyncThunk } from '@reduxjs/toolkit'
import teamService from '../services/team.service'

export const getRequests = createAsyncThunk(
    '/team/requests',
    async (options, { rejectWithValue }) => {
        try {
            const data = await teamService.allRequests()
            options?.callBacks?.success?.(data)
            return data
        } catch (error) {
            options?.callBacks?.err?.()
            return rejectWithValue('An error occured')
        }
    }
)
