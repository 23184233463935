import React from 'react'
import { useNavigate, Link } from 'react-router-dom'

function CardContentCard({
    title,
    required,
    cards,
    currActive,
    index,
    setCurrActive,
    pack,
    copyFunction,
    deleteFunction
}) {
    const capitalize = str => {
        return `${str[0].toUpperCase()}${str.substring(1)}`
    }
    return (
        <div
            className={`border-r-6 border-g300 hover-itm my-2 ${
                cards.length != required && 'border-error'
            }`}
            onClick={() => {
                if (currActive == index) {
                    setCurrActive(null)
                } else {
                    setCurrActive(index)
                }
            }}>
            <div
                className={`flex-r justify-content-between align-items-center bckg-g100 border-r-6-top px-3 py-2 ${
                    currActive == index && 'border-bottom-g300'
                } ${currActive != index && 'border-r-6'}`}>
                <div className="flex-r align-items-center">
                    <span
                        className={`fw-500 ${
                            cards.length != required && 'red'
                        }`}>
                        {capitalize(title)}
                    </span>
                    <span className="gray-600 ml-2">
                        {`${cards.length}/${required}`}
                    </span>
                </div>
                <i
                    className={`fa ${
                        currActive == index ? 'fa-angle-up' : 'fa-angle-down'
                    }`}></i>
            </div>
            {currActive == index && (
                <>
                    {cards?.length == 0 ? (
                        <div className="flex-c align-items-center pt-3">
                            <h3 className="m-0 mt-2">No Cards</h3>
                            <p className="m-0">Add cards to this dimension</p>
                        </div>
                    ) : (
                        cards?.map((card, index) => (
                            <div
                                key={index}
                                to={`/content/card/edit?id=${card.id}`}
                                onClick={e => {
                                    e.stopPropagation()
                                }}
                                className={`flex-c flex-lg-r justify-content-between align-lg-center gray-900 ${
                                    index != cards.length - 1
                                        ? 'border-bottom-g300'
                                        : ''
                                }`}>
                                <Link
                                    className="flex-c px-3 pt-3 pb-lg-3 gray-900"
                                    to={`/content/card/edit?id=${card.id}`}
                                    style={{ flex: 1 }}>
                                    <span className="font-16 fw-500 mb-1">
                                        {card.name}
                                    </span>
                                    <span>{card.description}</span>
                                </Link>
                                <div className="flex-r justify-content-lg-end align-items-center mt-3 mt-lg-0 px-3 pb-3 pb-lg-0">
                                    <Link
                                        className="brand-secondary fw-500 hover mr-3 mr-lg-4"
                                        to={`/content/card/edit?id=${card.id}`}
                                        title={'Edit Card'}>
                                        Edit
                                    </Link>
                                    <a
                                        className="brand-secondary fw-500 hover mr-3 mr-lg-4"
                                        onClick={e => {
                                            copyFunction(card.id)
                                            e.stopPropagation()
                                        }}
                                        title={'Copy Card'}>
                                        Copy
                                    </a>
                                    <a
                                        className="red hover fw-500 mr-3"
                                        onClick={e => {
                                            deleteFunction(card.id)
                                            e.stopPropagation()
                                        }}
                                        title={'Delete Card'}>
                                        Delete
                                    </a>
                                </div>
                            </div>
                        ))
                    )}
                    {cards.length < required && (
                        <div
                            className={`flex-c align-items-center ${
                                cards.length > 0 && 'border-top-g300'
                            }`}>
                            <Link
                                className="btn btn-secondary my-4"
                                to={`/content/card/create?packId=${pack?.id}&dim=${title}`}>
                                Add a card
                            </Link>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default CardContentCard
