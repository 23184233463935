import { createSlice } from '@reduxjs/toolkit'
import { getInvites } from '../../actions/invites'

const initialState = {
    loading: false,
    invites: {}
}

const pendingCase = state => {
    state.loading = true
}

const rejectedCase = (state, action) => {
    state.loading = false
}

const invitesSlice = createSlice({
    name: 'invites',
    initialState,
    reducers: {},
    extraReducers: builder => {
        // Get getInvites
        builder
            .addCase(getInvites.pending, pendingCase)
            .addCase(getInvites.fulfilled, (state, action) => {
                state.loading = false
                state.invites = action.payload
            })
            .addCase(getInvites.rejected, rejectedCase)
    }
})

export default invitesSlice.reducer
