import { configureStore } from '@reduxjs/toolkit'
import authReducer from './config/slice/auth.slice'
import userReducer from './config/slice/user.slice'
import memberReducer from './config/slice/member.slice'
import subsReducer from './config/slice/subs.slice'
import plansReducer from './config/slice/plans.slice'
import selectedPlan from './config/slice/selectedPlan.slice'
import componentReducer from './config/slice/component.slice'
import modalConversionReducer from './config/slice/modalConversion.slice'
import boxReducer from './config/slice/box.slice'
import packReducer from './config/slice/pack.slice'
import othersReducer from './config/slice/others.slice'
import teamReducer from './config/slice/team.slice'
import invitesReducer from './config/slice/invites.slice'
import requestsReducer from './config/slice/requests.slice'
import cardReducer from './config/slice/card.slice'
import searchReducer from './config/slice/search.slice'

const store = configureStore({
    reducer: {
        auth: authReducer,
        user: userReducer,
        member: memberReducer,
        subs: subsReducer,
        plans: plansReducer,
        planSelection: selectedPlan,
        modal: componentReducer,
        modalConv: modalConversionReducer,
        boxes: boxReducer,
        pack: packReducer,
        others: othersReducer,
        team: teamReducer,
        invites: invitesReducer,
        requests: requestsReducer,
        card: cardReducer,
        search: searchReducer
    }
})

export default store
