import { axiosInstance } from '../config/helpers/axios'

const searchService = {
    postSearchPeoplePromise: params => {
        const searchParams = new URLSearchParams()
        Object.keys(params).forEach(key =>
            searchParams.append(key, params[key])
        )
        return axiosInstance.get(`/search/members?${searchParams}`)
    }
}

export default searchService
