import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

function Sidebar() {
    const { loading: userLoading, user: user } = useSelector(
        state => state.user
    )
    const location = useLocation()
    const { pathname } = location
    const rootPathName = `/${pathname.split('/')[1]}`
    const privatePaths = {
        '/account': '/account',
        '/content': '/content',
        '/my-team': '/my-team',
        '/profile': '/profile',
        '/search': '/search',
        '/admin': '/admin'
    }

    return privatePaths[rootPathName] ? (
        <div className="side-bar flex-c">
            <div className="logo-sm">
                {user.role !== 'Admin' ? (
                    <div className="logo" />
                ) : (
                    <i className="fa-solid fa-user-shield white"></i>
                )}
            </div>

            <div className="flex-c flex-1">
                {user && user.intent == 'Provider' && (
                    <Link className="tab" to="content" title="Content">
                        <div
                            className={`nav-square ${
                                rootPathName === '/content' ? 'selected' : ''
                            }`}>
                            <i className="fa-solid fa-layer-group"></i>
                        </div>
                    </Link>
                )}
                {user && user.intent == 'Provider' && (
                    <Link className="tab" to="team" title="Team">
                        <div
                            className={`nav-square ${
                                rootPathName === '/team' ? 'selected' : ''
                            }`}>
                            <i className="fa-solid fa-users"></i>
                        </div>
                    </Link>
                )}
                {/* <Link
                    className="tab"
                    to="inbox"
                    title="Inbox">
                    <div className={
                        `nav-square ${
                            rootPathName === '/inbox'
                                ? 'selected'
                                : ''
                        }`}>
                        <i className="fa-solid fa-comment"></i>
                    </div>
                </Link> */}
            </div>
            {user.role !== 'Admin' && (
                <Link className="tab mb-1" to="account" title="Account">
                    <div
                        className={`nav-square ${
                            rootPathName === '/account' ? 'selected' : ''
                        }`}>
                        <i className="fa-solid fa-users-gear"></i>
                    </div>
                </Link>
            )}
        </div>
    ) : (
        <></>
    )
}

export default Sidebar
