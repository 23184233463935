import * as main from './main.service'
import { HandleUIErrors } from '../services/main.service'
import { axiosInstance } from '../config/helpers/axios'

const subsService = {
    getPlans: () => {
        return fetch(main.API + '/get-plans')
            .then(response => {
                if (response.status === !200) {
                    return response.status
                }
                return response.json()
            })
            .catch(HandleUIErrors)
    },

    getSubs: () => {
        return axiosInstance.get('/get-subscription')
    },

    startTrial: () => {
        return axiosInstance.get('/start-trial')
    },

    postCreateSub: params => {
        return axiosInstance.post('/create-subscription', params)
    },

    cancelSub: params => {
        return axiosInstance.get('/cancel-subscription/' + params)
    },

    removeSub: () => {
        return axiosInstance.get('/remove-subscription')
    }
}

export default subsService
