import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { selectedPlan } from '../../config/slice/selectedPlan.slice'
import { modalConvState } from '../../config/slice/modalConversion.slice'

export function ConversionModal() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const resetModal = async () => {
        document.body.classList.remove('o-flow-y-hide')
        dispatch(modalConvState(false))
        dispatch(selectedPlan(null))
    }

    const hideModal = async () => {
        document.body.classList.remove('o-flow-y-hide')
        dispatch(modalConvState(false))
    }

    const toAccount = async () => {
        await hideModal()
        navigate('/account')
    }

    return (
        <div className="modal-background" role="dialog">
            <div className="col-sm-8 col-md-6 col-lg-4" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Attention</h5>
                        <i
                            className="fa-solid fa-xmark gray-800"
                            onClick={resetModal}
                            title="Close Modal"></i>
                    </div>
                    <div className="modal-body pb-3">
                        <p className="lh-24 mt-2 mb-4">
                            Selecting a paid subscription will end your free
                            trial. This action can not be undone.
                        </p>
                    </div>
                    <div className="flex-c text-c modal-footer">
                        <Link
                            className="btn btn-cancel mb-4"
                            onClick={toAccount}
                            title="Continue">
                            Continue
                        </Link>
                        <Link
                            className="gray-700 fw-500 mb-3"
                            onClick={resetModal}
                            title="Return">
                            Return
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
