import { createSlice } from '@reduxjs/toolkit'
import { getRequests } from '../../actions/requests'

const initialState = {
    loading: false,
    requests: {}
}

const pendingCase = state => {
    state.loading = true
}

const rejectedCase = (state, action) => {
    state.loading = false
}

const requestsSlice = createSlice({
    name: 'requests',
    initialState,
    reducers: {},
    extraReducers: builder => {
        // Get getRequests
        builder
            .addCase(getRequests.pending, pendingCase)
            .addCase(getRequests.fulfilled, (state, action) => {
                state.loading = false
                state.requests = action.payload
            })
            .addCase(getRequests.rejected, rejectedCase)
    }
})

export default requestsSlice.reducer
