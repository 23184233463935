import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

function BreadCrumb() {
    const { breadCrumbs } = useSelector(state => state.others)
    const { loading: userLoading, user: user } = useSelector(
        state => state.user
    )
    return (
        <div className="crumbs border-bottom-g300 px-4 py-2">
            <div className="flex-r align-items-center">
                {user.role !== 'Admin' && (
                    <>
                        <Link
                            className="flex-c align-items-center justify-centent-center hover"
                            to={'/content'}
                            title="Return">
                            <i className="fa-solid fa-house gray-700 font-12"></i>
                        </Link>
                        <span className="gray-700 px-2">/</span>
                    </>
                )}
                {breadCrumbs.map((c, i) => (
                    <span key={i}>
                        {i != 0 && <span className="gray-700 px-2">/</span>}
                        {i == breadCrumbs.length - 1 ? (
                            <span className="gray-900" href={c.path}>
                                {`${c.name.slice(-5)}` === ':edit' ? (
                                    <>
                                        <span className="fw-500">
                                            {c.name.slice(0, -4)}
                                        </span>
                                        <span className="gray-700 ml-1">
                                            edit
                                        </span>
                                    </>
                                ) : `${c.name.slice(0, 8)}` === 'Profile:' ? (
                                    <>
                                        <span className="fw-500">
                                            Profile:{' '}
                                        </span>
                                        <span className="gray-700 ml-1">
                                            {c.name.slice(8)}
                                        </span>
                                    </>
                                ) : (
                                    <span className="fw-500">{c.name}</span>
                                )}
                            </span>
                        ) : (
                            <Link
                                className="gray-900 hover"
                                to={c.path}
                                title={`${c.name} `}>
                                {`${c.name}`}
                            </Link>
                        )}
                    </span>
                ))}
            </div>
        </div>
    )
}

export default BreadCrumb
