import { createSlice } from '@reduxjs/toolkit'
import {
    getAllBoxes,
    getSingleBox,
    updateBox,
    createBox,
    getTemplates,
    copyBox,
    deleteBox
} from '../../actions/Content/box'

const initialState = {
    loading: false,
    allBoxes: [],
    singleBox: {},
    templates: []
}

const pendingCase = state => {
    state.loading = true
}

const rejectedCase = (state, action) => {
    state.loading = false
}

const boxSlice = createSlice({
    name: 'boxes',
    initialState,
    reducers: {},
    extraReducers: builder => {
        // Get All Boxes
        builder
            .addCase(getAllBoxes.pending, pendingCase)
            .addCase(getAllBoxes.fulfilled, (state, action) => {
                state.loading = false
                state.allBoxes = action.payload
            })
            .addCase(getAllBoxes.rejected, rejectedCase)

        // Get Single Box
        builder
            .addCase(getSingleBox.pending, pendingCase)
            .addCase(getSingleBox.fulfilled, (state, action) => {
                state.loading = false
                state.singleBox = action.payload
            })
            .addCase(getSingleBox.rejected, rejectedCase)

        // Update box
        builder
            .addCase(updateBox.pending, pendingCase)
            .addCase(updateBox.fulfilled, (state, action) => {
                state.loading = false
                state.allBoxes = action.payload
            })
            .addCase(updateBox.rejected, rejectedCase)

        // Update box
        builder
            .addCase(createBox.pending, pendingCase)
            .addCase(createBox.fulfilled, (state, action) => {
                state.loading = false
                state.allBoxes = action.payload
            })
            .addCase(createBox.rejected, rejectedCase)

        // Get templates
        builder
            .addCase(getTemplates.pending, pendingCase)
            .addCase(getTemplates.fulfilled, (state, action) => {
                state.loading = false
                state.templates = action.payload
            })
            .addCase(getTemplates.rejected, rejectedCase)

        // Copy box
        builder
            .addCase(copyBox.pending, pendingCase)
            .addCase(copyBox.fulfilled, (state, action) => {
                state.loading = false
                state.allBoxes = action.payload
            })
            .addCase(copyBox.rejected, rejectedCase)

        // Copy box
        builder
            .addCase(deleteBox.pending, pendingCase)
            .addCase(deleteBox.fulfilled, (state, action) => {
                state.loading = false
                state.allBoxes = action.payload
            })
            .addCase(deleteBox.rejected, rejectedCase)
    }
})

export default boxSlice.reducer
