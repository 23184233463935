import { createSlice } from '@reduxjs/toolkit'
import {
    getMemberSelf,
    updateMemberSelf,
    sendConfirmCode,
    verifyConfirmCode,
    togglePromo,
    updatememberLocation,
    getBlockedMembers,
    removeBlockedMembers,
    confirmResetPasswordCode,
    sendResetPasswordCode,
    resetPassword,
    updateProfileMedia
} from '../../actions/members'

const initialState = {
    loading: false,
    user: {},
    code: ''
}

const pendingCase = state => {
    state.loading = true
}

const rejectedCase = (state, action) => {
    state.loading = false
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetUser: (state, action) => {
            state.user = {}
        },
        setCode: (state, action) => {
            state.code = action.payload
        }
    },
    extraReducers: builder => {
        // Get user
        builder
            .addCase(getMemberSelf.pending, pendingCase)
            .addCase(getMemberSelf.fulfilled, (state, action) => {
                state.loading = false
                state.user = action.payload
            })
            .addCase(getMemberSelf.rejected, rejectedCase)

        // Update user
        builder
            .addCase(updateMemberSelf.pending, pendingCase)
            .addCase(updateMemberSelf.fulfilled, (state, action) => {
                state.loading = false
                state.user = action.payload
            })
            .addCase(updateMemberSelf.rejected, rejectedCase)

        // Send Confirm code
        builder
            .addCase(sendConfirmCode.pending, pendingCase)
            .addCase(sendConfirmCode.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(sendConfirmCode.rejected, rejectedCase)
        // Send Confirm code
        builder
            .addCase(sendResetPasswordCode.pending, pendingCase)
            .addCase(sendResetPasswordCode.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(sendResetPasswordCode.rejected, rejectedCase)

        // Verify Confirm code
        builder
            .addCase(verifyConfirmCode.pending, pendingCase)
            .addCase(verifyConfirmCode.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(verifyConfirmCode.rejected, rejectedCase)

        // Confirm reset password code
        builder
            .addCase(confirmResetPasswordCode.pending, pendingCase)
            .addCase(confirmResetPasswordCode.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(confirmResetPasswordCode.rejected, rejectedCase)

        // Reset password
        builder
            .addCase(resetPassword.pending, pendingCase)
            .addCase(resetPassword.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(resetPassword.rejected, rejectedCase)

        // Toggle Promo
        builder
            .addCase(togglePromo.pending, pendingCase)
            .addCase(togglePromo.fulfilled, (state, action) => {
                state.loading = false
                // state.team = action.payload
            })
            .addCase(togglePromo.rejected, rejectedCase)

        // update member location
        builder
            .addCase(updatememberLocation.pending, pendingCase)
            .addCase(updatememberLocation.fulfilled, (state, action) => {
                state.loading = false
                state.zip = action.payload
            })
            .addCase(updatememberLocation.rejected, rejectedCase)

        // Get blocked lists
        builder
            .addCase(getBlockedMembers.pending, pendingCase)
            .addCase(getBlockedMembers.fulfilled, (state, action) => {
                state.loading = false
                state.user.blocked = action.payload
            })
            .addCase(getBlockedMembers.rejected, rejectedCase)

        // Remove blocked
        builder
            .addCase(removeBlockedMembers.pending, pendingCase)
            .addCase(removeBlockedMembers.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(removeBlockedMembers.rejected, rejectedCase)


        builder
            .addCase(updateProfileMedia.pending, pendingCase)
            .addCase(updateProfileMedia.fulfilled, (state, action) => {
                state.loading = false
                state.user.profilemedia = action.payload
            })
            .addCase(updateProfileMedia.rejected, rejectedCase)
    }
})

export const { resetUser, setCode } = userSlice.actions

export default userSlice.reducer
