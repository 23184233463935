import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        value: '',
        check: ''
    },
    reducers: {
        userContact: (state, action) => {
            state.value = action.payload
        },
        disclaimerCheck: (state, action) => {
            state.check = action.payload
        }
    }
})

export const { userContact, disclaimerCheck } = authSlice.actions

export default authSlice.reducer
