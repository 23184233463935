import React from 'react'
import { Helmet } from 'react-helmet'

const title = 'Disclaimer Policy | Cardeon'
const url = 'https://cardeon.io/disclaimer'
const desc = "Welcome to Cardeon's Disclaimer Policy."

function Disclaimer() {
    return (
        <div className="privacy flex-c flex-grow-1 interior-view">
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={desc} />
                <link rel="canonical" href={url} />
                <meta property="og:url" content={url} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={desc} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={desc} />
            </Helmet>
            <div className="flex-c container">
                <div className="flex-c row">
                    <div className="flex-c col-12 pt-5">
                        <h1 className="mt-4">
                            Liability Waiver and Disclaimer
                        </h1>
                        <p>Last Updated: 10/15/2024</p>
                        <p>
                            Please carefully review this Liability Waiver and Disclaimer ("Agreement") before accessing or using the Cardeon mobile application (the "Service" or the "Cardeon App"), operated by Eva, LLC ("Company," "us," "we," or "our"). This Agreement outlines the terms and conditions under which you may use our Service and establishes important legal protections for both you and the Company.
                        </p>
                        <p>
                            By accessing, downloading, installing, or using the Service, you ("User") agree to be legally bound by the terms of this Agreement. If you do not agree with these terms, you must immediately cease using the Service. This Agreement is a legally binding contract between you and the Company, governing your use of the Service and its related features, content, and offerings. Your continued use of the Service constitutes your ongoing acceptance of any changes or updates to this Agreement.
                        </p>
                        <p className="mb-5">
                            This page informs you of the terms under which you use our mental health and wellness services, which are optional, used at your own discretion, and not a substitute for professional therapy or crisis intervention. By accessing our services, you acknowledge that the Company assumes no liability for any outcomes or events that may occur while using them.
                        </p>
                        <strong>Optional Nature of Services</strong>
                        <p className="mb-5">The Services provided by Cardeon are intended as supplemental tools designed to support mental health and wellness. These Services are entirely optional and are made available for discretionary use by the User. It is important to understand that these Services are not, and should not be considered, a substitute for professional therapeutic treatment, medical advice, diagnosis, or intervention. The Services are not intended to replace the expertise, judgment, or care provided by licensed healthcare professionals, including but not limited to licensed therapists, counselors, psychiatrists, psychologists, or physicians. Users are strongly encouraged to seek the advice of qualified healthcare providers for any medical or psychological concerns or conditions. The Services are intended to complement, not replace, traditional therapeutic approaches and should be used with caution and in conjunction with professional guidance when necessary.</p>
                        <strong>Use at Your Own Discretion</strong>
                        <p className="mb-5">The use of the Services provided by Cardeon is entirely at the User's own discretion and risk. The Company makes no guarantees, representations, or warranties, whether express or implied, regarding the effectiveness, suitability, reliability, accuracy, or completeness of the Services. Users are advised that the outcomes and experiences derived from using the Services may vary significantly from one individual to another. The Company expressly disclaims any and all liability related to the use of the Services, including but not limited to any implied warranties of merchantability, fitness for a particular purpose, or non-infringement. Users acknowledge and accept that no specific results or improvements in mental health or overall well-being are promised or guaranteed by the Company. The User assumes full responsibility for any decisions or actions taken based on their use of the Services, and the Company shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from such use.</p>
                        <strong>Limitation of Liability</strong>
                        <p className="mb-5">TO THE FULLEST EXTENT PERMITTED BY LAW, CARDEON AND ITS PARENT COMPANY, EVA, LLC, INCLUDING ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, AND AFFILIATES (COLLECTIVELY, THE "COMPANY"), SHALL NOT BE HELD RESPONSIBLE OR LIABLE FOR ANY ACTIONS, OUTCOMES, INJURIES, OR DAMAGES OF ANY KIND ARISING FROM OR RELATED TO THE USE OF, OR INABILITY TO USE, THE SERVICES. THE USER EXPRESSLY AGREES THAT THE COMPANY SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, EMOTIONAL DISTRESS, PHYSICAL HARM, FINANCIAL LOSS, LOSS OF DATA, LOSS OF PROFITS, OR ANY OTHER DAMAGES, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, TORT, OR ANY OTHER LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH THE USE, MISUSE, OR INABILITY TO USE THE SERVICES, EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS LIMITATION OF LIABILITY APPLIES REGARDLESS OF THE FORM OF ACTION, WHETHER BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR ANY OTHER LEGAL OR EQUITABLE THEORY, AND WHETHER OR NOT THE COMPANY WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE USER ACKNOWLEDGES AND AGREES THAT THEIR SOLE AND EXCLUSIVE REMEDY FOR ANY DISSATISFACTION WITH THE SERVICES IS TO DISCONTINUE USE OF THE SERVICES.</p>
                        <strong>Acknowledgment of Risks</strong>
                        <p className="mb-5">The User expressly acknowledges and agrees that participation in mental health and wellness activities, including those offered through the Services, carries inherent risks. These risks may include, but are not limited to, emotional distress, psychological discomfort, exacerbation of existing mental health conditions, and other unforeseen or unintended consequences. By using the Services, the User voluntarily assumes all risks, known and unknown, associated with such activities. The User accepts full responsibility for their physical, emotional, and mental well-being while engaging with the Services. The Company makes no representations, warranties, or guarantees regarding the safety, suitability, or effectiveness of the Services for any particular user or purpose. The User acknowledges that their participation in the Services is entirely voluntary and at their own discretion, and they agree to proceed with a full understanding of the potential risks involved. The Company shall not be liable for any adverse effects or outcomes that may result from the User's engagement with the Services.</p>
                        <strong>User Responsibility and Honesty in Self-Assessment</strong>
                        <p className="mb-5">As part of our commitment to promoting mental health and wellness, we ask Users to provide truthful and accurate responses during the pre-assessment process, including the question related to thoughts, intentions, or actions of self-harm or harming others. You, the User, acknowledge and agree that providing false, misleading, or incomplete information during this assessment or any other part of the registration process is a violation of this Agreement and may result in restricted access to the Services, including account suspension or termination. If your account is flagged as "At Risk" based on your responses, any attempt to bypass this status by creating a new account with different information, or by providing false responses in subsequent assessments, is strictly prohibited. The Company reserves the right to monitor and review account activity to ensure compliance with this provision and to take appropriate action, including but not limited to account suspension, termination, or reporting to appropriate authorities if necessary. Your honest participation is critical to ensuring your safety and the safety of others, and to the effective provision of our Services. By agreeing to this clause, you affirm your understanding of the importance of providing truthful and accurate information and your commitment to doing so.</p>
                        <strong>No Crisis Intervention</strong>
                        <p className="mb-5">The Services provided by Cardeon are not designed or intended to serve as a substitute for crisis intervention, emergency care, or any form of urgent mental health support. The Company expressly disclaims any responsibility for providing immediate assistance in emergency situations or crises. If you are experiencing a mental health crisis, severe emotional distress, or any other urgent mental health need, it is imperative that you seek help from appropriate emergency services, a licensed mental health professional, or a crisis intervention hotline immediately. The Services offered by Cardeon are not equipped to handle such situations and should not be relied upon in cases of emergency. In the event of a medical or mental health emergency, you must call 911 or your local emergency number without delay. The Company assumes no liability for any consequences arising from your reliance on the Services during an emergency or crisis situation.</p>
                        <strong>Indemnification</strong>
                        <p className="mb-5">To the fullest extent permitted by law, you, the User, agree to indemnify, defend, and hold harmless Eva, LLC, including its subsidiaries, affiliates, officers, directors, advisors, employees, agents, and representatives (collectively, the "Indemnified Parties"), from and against any and all claims, liabilities, damages, losses, costs, or expenses (including reasonable attorneys' fees and court costs) arising out of or related to your use or misuse of the Services provided by Cardeon, including any actions you take based on the information, content, or services provided through the Cardeon App. This indemnification obligation also applies to any breach or violation of the terms of this Liability Waiver and Disclaimer, including any other agreements or policies incorporated by reference, as well as any violation of applicable laws, regulations, or third-party rights, including but not limited to intellectual property rights, privacy rights, and confidentiality obligations. Furthermore, you agree to indemnify the Indemnified Parties against any claim brought by a third party due to your actions or omissions that cause harm, whether intentional or unintentional, to another user or any other person related to your use of the Services.You agree to cooperate fully with the Indemnified Parties in the defense of any claim subject to indemnification. The Indemnified Parties reserve the right to assume exclusive defense and control of any matter otherwise subject to indemnification by you, and you agree not to settle any such matter without the prior written consent of the Indemnified Parties.</p>
                        <strong>Waiver of Claims</strong>
                        <p className="mb-5">By using the Services provided by Cardeon, you, the User, expressly waive and relinquish any and all claims, rights, and causes of action, whether known or unknown, against Eva, LLC, its subsidiaries, affiliates, officers, advisors, directors, employees, agents, and representatives (collectively, the "Company") arising out of or in connection with your use or inability to use the Services. This waiver includes, but is not limited to, claims for any direct, indirect, incidental, special, consequential, or punitive damages, emotional distress, physical harm, financial loss, loss of data, or any other damages, regardless of the legal theory upon which such claims may be based, whether in contract, tort (including negligence), strict liability, or otherwise. You acknowledge and agree that this waiver of claims is a material condition of your use of the Services and that the Company would not provide the Services without your agreement to this waiver. To the extent that any jurisdiction does not allow the exclusion or limitation of certain warranties or the waiver of certain claims, the scope and duration of such exclusions, limitations, and waivers shall be the minimum permitted under applicable law.</p>
                        <strong>Voluntary Participation</strong>
                        <p className="mb-5">Your participation in the Services provided by Cardeon is entirely voluntary. You, the User, acknowledge and agree that you are not required to use the Services and that your decision to engage with the Services is made of your own free will and at your sole discretion. The Company makes no representations or guarantees regarding the necessity or effectiveness of the Services for any individual user, and you accept full responsibility for any decisions or actions you take in relation to your use of the Services. You further understand that you are free to discontinue your use of the Services at any time without penalty or obligation, and that such discontinuation will not affect any rights or obligations accrued prior to your decision to stop using the Services.</p>
                        <strong>Consent to Electronic Signature</strong>
                        <p className="mb-5">By selecting the checkbox and clicking "Continue" or similar buttons within the Cardeon mobile application, you, the User, acknowledge and agree that your electronic signature is intended to authenticate this Liability Waiver and Disclaimer and that it carries the same legal effect as a handwritten signature. You further agree that your electronic signature signifies your full and informed consent to be bound by the terms and conditions of this Agreement, as well as any other related agreements or policies incorporated by reference. You understand and agree that your electronic signature applies to all interactions, transactions, and agreements conducted through the Services provided by Cardeon. Additionally, you acknowledge that this consent to electronic signature extends to any future modifications, updates, or revisions to this Agreement, which will also require your electronic re-acceptance to remain in compliance with the terms of use.</p>
                        <strong>Medical Disclaimer</strong>
                        <p className="mb-5">The Services provided by Cardeon are intended for general informational and educational purposes only and are not a substitute for professional medical advice, diagnosis, or treatment. You, the User, acknowledge and agree that the Company does not provide medical, psychological, or therapeutic advice or services, and that any content or information available through the Services should not be relied upon as such. The Company strongly recommends that you consult with a licensed healthcare professional before making any decisions or taking any actions that could affect your health or well-being, particularly if you have or suspect that you may have a medical or psychological condition.</p>
                        <strong>No Professional Relationship</strong>
                        <p className="mb-5">Your use of the Services provided by Cardeon does not create a doctor-patient, therapist-client, or any other type of professional relationship between you, the User, and Eva, LLC, or its affiliates, officers, employees, agents, or representatives (collectively, the "Company"). The information, content, and services offered through the Cardeon App are intended for general informational and educational purposes only and are not intended to constitute professional advice, diagnosis, or treatment of any kind. You acknowledge and agree that no fiduciary, confidential, or privileged relationship is established through your use of the Services, and that any interactions or communications with the Company or its representatives do not constitute a professional or therapeutic relationship. You further agree that the Company is not acting in any professional capacity and that you should seek advice, diagnosis, or treatment from a licensed healthcare provider or other qualified professionals for any specific medical, psychological, legal, or financial issues you may have. The Company expressly disclaims any liability for any actions or decisions you make based on the information or services provided, and you acknowledge that your use of the Services is entirely at your own risk.</p>
                        <strong>Governing Law</strong>
                        <p className="mb-5">This waiver and disclaimer shall be governed by and construed in accordance with the laws of North Carolina, without regard to its conflict of law principles.</p>
                        <strong>Acceptance</strong>
                        <p className="mb-5">By accessing, using, or interacting with the Services provided by Cardeon, you, the User, acknowledge that you have read, understood, and agree to be bound by the terms and conditions set forth in this Liability Waiver and Disclaimer. Your continued use of the Cardeon App constitutes your ongoing acceptance of this Agreement, including any future modifications or updates that may be made. If you do not agree with any part of this Agreement, you must immediately cease using the Services. You further acknowledge that your acceptance of this Agreement is a condition of your use of the Services and that the Company would not provide access to the Services without your agreement to these terms. By selecting the checkbox and clicking "Continue" or any similar button within the Cardeon App, you affirm your voluntary and informed consent to be legally bound by this Agreement, as well as any related agreements, policies, or terms of service that are incorporated by reference. Your electronic acceptance of this Agreement has the same legal effect as a physical signature on a written contract.</p>
                        <strong>Contact Us</strong>
                        <p className="my-0 mb-2">
                            For any questions or concerns regarding this privacy
                            policy, you may contact us using the following
                            details:
                        </p>
                        <a
                            href="mailto: abc@example.com"
                            title="Email Support"
                            className="brand-secondary fw-500 mb-5">
                            support@cardeon.io
                        </a>
                        <p className="mb-5">By agreeing, you (“User") confirm that you have read and understand the Agreement and agree to the terms as described above.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Disclaimer
