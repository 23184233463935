import { axiosInstance } from '../config/helpers/axios'

const memberService = {
    getMembersPromise: () => {
        return axiosInstance.get(`/members/self`)
    },

    postMembersPromise: params => {
        return axiosInstance.put('/members/self', params)
    },

    postCreateMemberPromise: params => {
        return axiosInstance.post('/members/create', params)
    },

    postBecomeProvider: params => {
        return axiosInstance.post('/become-provider', params)
    },

    deleteMemberSelf: () => {
        return axiosInstance.put('/members/delete', {})
    },
    sendConfirmCode: params => {
        return axiosInstance.post('/send-confirm-code', params)
    },
    sendResetPasswordCode: params => {
        return axiosInstance.post('/send-reset-pass-code', params)
    },
    verifyConfirmCode: params => {
        return axiosInstance.post('/verify-confirm-code', params)
    },
    confirmResetPasswordCode: params => {
        return axiosInstance.post('/confirm-reset-pass-code', params)
    },
    resetPassword: params => {
        return axiosInstance.post('/reset-password', params)
    },
    togglePromoEmail: status => {
        return axiosInstance.get(`/promo-email?status=${status}`)
    },
    updateMemberLocation: params => {
        return axiosInstance.put('/members/location', params)
    },
    getBlockedMembers: () => {
        return axiosInstance.get('/blocked')
    },
    removeBlockedMembers: params => {
        return axiosInstance.put('/blocked/remove', params)
    },
    getMembersByIdPromise: id => {
        return axiosInstance.get('/members/' + id)
    }
}

export default memberService
