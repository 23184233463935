import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getLookups } from '../../actions/others'

const initialState = {
    loading: false,
    packCategoryList: [],
    boxTypeList: [],
    difficultyList: [],
    priceList: [],
    dimensionList: [],
    cardTypeList: [],
    timeLimitList: [],
    itemOrderList: [],
    breadCrumbs: []
}

const pendingCase = state => {
    state.loading = true
}

const rejectedCase = (state, action) => {
    state.loading = false
}

const othersSlice = createSlice({
    name: 'others',
    initialState,
    reducers: {
        setBreadCrumb: (state, action) => {
            state.breadCrumbs = action.payload
        },
        resetBreadCrumb: state => {
            state.breadCrumbs = []
        }
    },
    extraReducers: builder => {
        // Get Lookups
        builder
            .addCase(getLookups.pending, pendingCase)
            .addCase(getLookups.fulfilled, (state, action) => {
                state.loading = false
                state.packCategoryList = action.payload.packCategory
                state.boxTypeList = action.payload.boxType
                state.difficultyList = action.payload.difficulty
                state.priceList = action.payload.price
                state.dimensionList = action.payload.dimension
                state.cardTypeList = action.payload.cardType
                state.timeLimitList = action.payload.timeLimit
                state.itemOrderList = action.payload.itemOrder
            })
            .addCase(getLookups.rejected, rejectedCase)
    }
})

export const { setBreadCrumb, resetBreadCrumb } = othersSlice.actions

export default othersSlice.reducer
