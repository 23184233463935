import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import 'react-toastify/dist/ReactToastify.css'

const title = 'Thank You | Cardeon'
const url = 'https://cardeon.io/thanks'
const desc = 'Thank you, from all of us at Cardeon.'

function Thanks() {
    return (
        <div className="signin flex-c flex-grow-1 interior-view">
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={desc} />
                <link rel="canonical" href={url} />
                <meta property="og:url" content={url} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={desc} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={desc} />
            </Helmet>
            <div className="flex-c container">
                <div className="flex-c row">
                    <div className="flex-c col-12 align-items-center pt-5">
                        <div className="flex-c align-items-center text-c">
                            <h1 className="mt-4">Thank You</h1>
                            <p className="lh-20 mt-1 mb-5">
                                You have successfully subscribed to Cardeon.
                                Continue to review your account.
                            </p>
                        </div>
                        <div className="flex-r col-12 col-md-3 mb-3">
                            <Link
                                type="button"
                                className="btn btn-secondary"
                                to="/account"
                                title="My Account">
                                My Account
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Thanks
