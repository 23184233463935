import { axiosInstance } from '../config/helpers/axios'

const packService = {
    getSinglePack: packId => {
        return axiosInstance.get(`/pack/get-pack/${packId}`)
    },
    update: async (packId, payload) => {
        return axiosInstance.put(`/pack/update/${packId}`, payload)
    },
    create: payload => {
        return axiosInstance.post(`/pack/create`, payload)
    },
    createFromTemplate: payload => {
        return axiosInstance.post('/pack/create-from-template', payload)
    },
    copyPack: async payload => {
        return axiosInstance.post('/pack/copy', payload)
    },
    delete: packId => {
        return axiosInstance.put(`/pack/delete/${packId}`)
    }
}

export default packService
