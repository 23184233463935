import React, { useState } from 'react'
import { useLocation, Link } from 'react-router-dom'

function Footer() {
    const location = useLocation()
    const { pathname } = location
    const rootPathName = `/${pathname.split('/')[1]}`
    const privatePaths = {
        '/account': '/account',
        '/content': '/content',
        '/my-team': '/my-team',
        '/profile': '/profile',
        '/search': '/search',
        '/admin': '/admin'
    }

    const [isCookies, setCookies] = useState(true)
    const toggleclassName = () => {
        setCookies(!isCookies)
    }
    const version = '1.7.71'
    const thisYear = new Date().getFullYear()

    return (
        <>
            {!privatePaths[rootPathName] ? (
                <footer className="flex-c pt-5">
                    <div className="flex-c container pt-2 pb-4 pt-md-5">
                        <div className="flex-c row">
                            <div className="flex-c">
                                <div className="flex-c flex-sm-r">
                                    <div className="flex-c col-6 align-items-start">
                                        <Link
                                            className="mb-3"
                                            to="/"
                                            title="Home">
                                            Home
                                        </Link>
                                        <Link
                                            className="mb-3"
                                            to="/about"
                                            title="About">
                                            About
                                        </Link>
                                        <Link
                                            className="mb-3"
                                            to="/team"
                                            title="Team">
                                            Team
                                        </Link>
                                    </div>
                                    <div className="flex-c col-6 align-items-start">
                                        <Link
                                            className="mb-3"
                                            to="/disclaimer"
                                            title="Disclaimer Policy">
                                            Disclaimer
                                        </Link>
                                        <Link
                                            className="mb-3"
                                            to="/privacy"
                                            title="Privacy Policy">
                                            Privacy Policy
                                        </Link>
                                        <Link
                                            className="mb-3"
                                            to="/terms"
                                            title="Terms of Service">
                                            Terms of Service
                                        </Link>
                                    </div>
                                </div>
                                <div className="flex-c flex-sm-r mb-4">
                                    <div className="flex-c col-sm-6 align-items-start">
                                        <Link className="mb-3" to="/pricing" title="Pricing">
                                            Pricing
                                        </Link>
                                        <Link
                                            className="mb-3"
                                            to="/contact"
                                            title="Contact Us">
                                            Contact Us
                                        </Link>
                                        <Link
                                            className="mb-3"
                                            to="https://calendly.com/cardeonapp/demo"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="Pricing">
                                            Demo
                                        </Link>
                                    </div>
                                    <div className="flex-c col-sm-6 align-items-start">
                                        <Link
                                            className="mb-3"
                                            to="/cookies"
                                            title="Cookie Policy">
                                            Cookie Policy
                                        </Link>
                                        <Link
                                            className="mb-3"
                                            to="/acceptable"
                                            title="Acceptable Use Policy">
                                            Acceptable Use Policy
                                        </Link>
                                    </div>
                                </div>

                                <div className="flex-c col mb-md-4">
                                    <div className="flex-c flex-md-r">
                                        <Link
                                            type="link"
                                            className="btn btn-small bckg-g900 white mr-md-3 mb-3 mb-md-0"
                                            to="https://apps.apple.com/us/app/cardeon/id1353714513"
                                            title="Download on the App Store"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <i className="fa-brands fa-apple pr-2"></i>
                                            App Store
                                        </Link>
                                        <Link
                                            type="link"
                                            className="btn btn-small bckg-g900 white mb-3 mb-md-0"
                                            to="https://play.google.com/store/apps/details?id=com.eva.support"
                                            title="Download on Google Play"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <i className="fa-brands fa-android pr-2"></i>
                                            Google Play
                                        </Link>
                                    </div>
                                </div>

                                <div className="flex-r">
                                    <div className="flex-r col-lg-6">
                                        <span className="font-16 gray-700 mr-4">
                                            Eva{' '}
                                            <i className="fa-regular fa-copyright"></i>{' '}
                                            {thisYear}
                                        </span>
                                    </div>
                                    <div className="flex-r col-lg-6 justify-content-end justify-content-md-start">
                                        <a
                                            className="mr-4"
                                            href="https://www.linkedin.com/company/cardeon-app"
                                            title="Cardeon on LinkedIn"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <i className="gray-700 fa-brands fa-linkedin-in"></i>
                                        </a>
                                        <a
                                            href="https://twitter.com/Cardeon_App"
                                            title="Cardeon on X"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <i className="gray-700 fa-brands fa-x-twitter"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex-r bckg-g400 py-3 mt-3">
                        <div className="flex-c container">
                            <div className="flex-c row">
                                <div className="flex-r col">
                                    <Link
                                        className="font-14 gray-900"
                                        to="/releases"
                                        title={`Version: ${version}`}>
                                        <span className="font-14 fw-500 gray-900 pr-1">
                                            Version:
                                        </span>
                                        {version}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className={`cookie-robot ${
                            isCookies ? 'flex-r' : 'd-none'
                        }`}>
                        <p className="white">
                            We use cookies on our website to provide you with
                            the best possible user experience. By continuing to
                            use our website or services, you agree to their use.
                        </p>
                        <i
                            className="white hover fas fa-times ml-3"
                            onClick={toggleclassName}
                            title="Close"></i>
                    </div>
                </footer>
            ) : (
                <></>
            )}
        </>
    )
}

export default Footer
