import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { modalState } from '../../config/slice/component.slice'
import { getMemberSelf, updateMemberSelf } from '../../actions/members'
import memberService from '../../services/members.service'

export function TermsModal() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const hideModal = async () => {
        let prettyJson = {
            agreement: true
        }
        dispatch(
            updateMemberSelf({
                payload: prettyJson,
                callBacks: {
                    success: () => {
                        dispatch(getMemberSelf())
                        document.body.classList.remove('o-flow-y-hide')
                        dispatch(modalState(false))
                    },
                    err: () => {
                        document.body.classList.remove('o-flow-y-hide')
                        dispatch(modalState(false))
                    }
                }
            })
        )
    }

    const toTerms = async () => {
        await hideModal()
        navigate('/terms')
    }

    const toPrivacy = async () => {
        await hideModal()
        navigate('/privacy')
    }

    return (
        <div className="modal-background" role="dialog">
            <div className="col-sm-8 col-md-6 col-lg-4" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Service Update</h5>
                        <i
                            className="fa-solid fa-xmark gray-800"
                            onClick={hideModal}
                            title="View Documents"></i>
                    </div>
                    <div className="modal-body pb-3">
                        <p className="lh-24 mt-2 mb-4">
                            We've updated our{' '}
                            <Link
                                className="brand-secondary"
                                onClick={toTerms}
                                title="Terms of Service">
                                Terms of Service
                            </Link>{' '}
                            and{' '}
                            <Link
                                className="brand-secondary"
                                onClick={toPrivacy}
                                title="Privacy Policy">
                                Privacy Policy
                            </Link>
                            , please review the updated documents immediately.
                            By viewing this message you are agreeing to the most
                            recent Cardeon privacy policy and terms of service
                            set forth by Eva, LLC.
                        </p>
                    </div>
                    <div className="modal-footer">
                        <Link
                            className="btn btn-secondary"
                            onClick={hideModal}
                            title="Close Modal">
                            Close
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
