import Axios from 'axios'
import authService from '../../services/auth.service'
import {
    API,
    HandleUIErrors,
    HandleResponse
} from '../../services/main.service'

export const axiosInstance = Axios.create({
    baseURL: API
})

//add token to all request
axiosInstance.interceptors.request.use(async function (config) {
    let token = ''
    try {
        token = await authService.verifyToken()
    } catch (e) {
        // crashlytics().log(e)
        console.log('Axios Error')
    }
    if (!!token) {
        config.headers['Authorization'] = `Bearer ${token}`
    }
    config.headers['Accept'] = 'application/json'
    return config
})

axiosInstance.interceptors.response.use(
    //handle on success
    function (response) {
        const checkedRes = HandleResponse(response)
        const responseData = checkedRes.data || checkedRes
        return responseData
    },

    //handle on error
    function (error) {
        // HandleUIErrors(error)
        if (error?.response?.data) {
            return Promise.reject(error?.response?.data)
        } else {
            return Promise.reject({
                message:
                    error?.message ||
                    'Looks like internet is not available, please try again'
            })
        }
    }
)
