import React from 'react'

function SubmitButton({ title, type, style, submitFunction }) {
    return (
        <span className="btn btn-secondary p-relative mt-5">
            <button
                type={type}
                aria-label={title}
                className={style}
                onClick={e => {
                    submitFunction()
                }}
                title={title}
            />
            <span>{title}</span>
        </span>
    )
}

export default SubmitButton
