import React from 'react'
import { Helmet } from 'react-helmet'
import graphic from '../img/404.png'

const title = '404 | Cardeon - Not Found'
const url = 'https://cardeon.io/404'
const desc = "Cardeon can not find the page you're looking for."

function NotFound() {
    return (
        <div className="404 flex-c flex-grow-1 interior-view">
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={desc} />
                <link rel="canonical" href={url} />
                <meta property="og:url" content={url} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={desc} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={desc} />
            </Helmet>
            <div className="flex-c container">
                <div className="flex-c row">
                    <div className="flex-c col-12 align-items-center text-c pt-5">
                        <h1 className="mt-4">404 - Page Not Found</h1>
                        <p className=" mb-5 mt-1">Well that's not good...</p>
                        <img
                            src={graphic}
                            title="404 Not Found"
                            alt="404 Not Found"
                            className="align-self-center img-centered"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound
