import { useState, useCallback } from 'react'
import Cropper from 'react-easy-crop'
// import getCroppedImg from './Crop'

export const createImage = url => {
    return new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', error => reject(error))
        image.setAttribute('crossOrigin', 'anonymous')
        image.src = url
    })
}

async function getCroppedImg(imageSrc, pixelCrop) {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    if (!ctx) {
        return null
    }

    // set canvas size to match the bounding box
    canvas.width = image.width
    canvas.height = image.height

    ctx.drawImage(image, 0, 0)

    const data = ctx.getImageData(
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height
    )

    // setSelectedBadge(croppedImage)

    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    ctx.putImageData(data, 0, 0)

    return new Promise((resolve, reject) => {
        canvas.toBlob(file => {
            resolve({ data: URL.createObjectURL(file), blobData: file })
        }, 'image/png')
    })
}

const Crop = ({ showModal, image, setSelectedBadge }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const getImgSrc = imgData => {
        return URL.createObjectURL(imgData)
    }
    image = image ? getImgSrc(image) : ''
    const onCropComplete = useCallback(
        async (croppedArea, croppedAreaPixels) => {
            setCroppedAreaPixels(croppedAreaPixels)
        },
        []
    )

    const showCroppedImage = useCallback(async () => {
        try {
            const { blobData, data } = await getCroppedImg(
                image,
                croppedAreaPixels
            )
            setSelectedBadge(blobData)
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, image])

    return (
        <div
            className="modal-background"
            role="dialog"
            onClick={() => showModal(false)}>
            <div
                className="col-sm-8 col-md-6 col-lg-8"
                role="document"
                onClick={e => e.stopPropagation()}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Crop</h5>
                        <i
                            className="fa-solid fa-xmark gray-800"
                            onClick={() => showModal(false)}
                            title="Close Modal"></i>
                    </div>
                    <div className="modal-body mb-3 mt-2">
                        <div>
                            <div
                                style={{
                                    position: 'relative',
                                    height: '400px',
                                    width: '100%'
                                }}>
                                <Cropper
                                    image={image}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={3 / 3}
                                    onCropChange={setCrop}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                />
                            </div>
                            <div
                                className="btn btn-secondary mb-4 mt-4"
                                onClick={() => {
                                    showCroppedImage()
                                    showModal(false)
                                }}>
                                Apply
                            </div>
                            <div
                                className="text-c mb-3"
                                style={{ cursor: 'pointer' }}>
                                <div
                                    className="gray-700 hover fw-500"
                                    onClick={e => {
                                        setSelectedBadge(null)
                                        showModal(false)
                                        e.stopPropagation()
                                    }}>
                                    Return
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Crop
