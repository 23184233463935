import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { modalState } from '../../config/slice/component.slice'
import { toast } from 'react-toastify'
import { useNavigate, Link, useParams, useLocation } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { getAllBoxes, getSingleBox, updateBox } from '../../actions/Content/box'
import TextInput from '../../components/inputs/TextInput'
import DropDownInput from '../../components/inputs/DropDownInput'
import BadgeInput from '../../components/inputs/BadgeInput'
import BreadCrumb from '../../components/elements/breadCrumb'
import { setBreadCrumb } from '../../config/slice/others.slice'
import Crop from '../../components/elements/Crop'
import { formatData } from '../../config/helpers'

function BoxEdit() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { search } = useLocation()
    let params = new URLSearchParams(search)
    let id = params.get('id')
    const [loading, setLoading] = useState(false)
    const { loading: userLoading, user: user } = useSelector(
        state => state.user
    )
    const { loading: boxLoading, singleBox } = useSelector(state => state.boxes)
    const { allBoxes: boxes } = useSelector(state => state.boxes)
    const others = useSelector(state => state.others)
    const [selectedBadge, setSelectedBadge] = useState(null)
    const [showCropModal, setShowCropModal] = useState(false)
    const [inputValue, setInput] = useState({
        name: '',
        type: '',
        order: 0,
        lockId: null,
        lockName: null
    })

    const handleOnChange = e => {
        setInput({
            ...inputValue,
            [e.target.name]: e.target.value
        })
    }

    const getLockedByBoxes = () => {
        const filteredBox = boxes.filter(
            b =>
                (b?.order || 0) < (inputValue.order || 0) &&
                singleBox?.id != b?.id
        )
        return [
            { value: '', label: 'None' },
            ...formatData(filteredBox, 'name', 'id')
        ]
    }

    const title = 'Content | Cardeon - My Content'
    const url = 'https://cardeon.io/content'
    const desc = 'Review and manage your client content.'

    useEffect(() => {
        async function init() {
            setLoading(true)
            if (Object.keys(user).length > 0) {
                if (!user.agreement) {
                    document.body.classList.add('o-flow-y-hide')
                    dispatch(modalState(true))
                }
                dispatch(getAllBoxes())
                dispatch(
                    getSingleBox({
                        boxId: id,
                        callBacks: {
                            success: data => {
                                setInput({
                                    name: data?.name,
                                    type: data?.type,
                                    order: data?.order,
                                    lockId: data?.lockId,
                                    lockName: data?.lockName
                                })
                                setSelectedBadge(data?.badge)
                                setLoading(false)
                            },
                            err: () => {
                                setLoading(false)
                            }
                        }
                    })
                )
            }
        }
        init()
    }, [id, user])

    useEffect(() => {
        if (id && singleBox?.name) {
            dispatch(
                setBreadCrumb([
                    {
                        name: 'Boxes',
                        path: '/content'
                    },
                    {
                        name: `${singleBox?.name}:edit`,
                        path: `/content/box/edit?id=${id}`
                    }
                ])
            )
        }
    }, [id, singleBox?.name])

    const handleUpdate = () => {
        let prettyJson = {
            name: inputValue.name,
            order: inputValue.order,
            type: inputValue.type,
            ...(inputValue.lockId != null &&
            inputValue.lockId != '' &&
            inputValue.order != 0
                ? {
                      lockId: inputValue.lockId,
                      lockName:
                          boxes?.find(el => el?.id === inputValue.lockId)
                              ?.name || ''
                  }
                : {})
        }
        const formData = new FormData()

        for (var key in prettyJson) formData.append(key, prettyJson[key])

        if (selectedBadge) {
            if (selectedBadge[0] != '/' && selectedBadge[0] != '.') {
                formData.append(
                    'badge',
                    selectedBadge,
                    `${new Date().getTime()}.png`
                )
            } else {
                formData.append('badge', selectedBadge)
            }
        } else {
            formData.append('badge', selectedBadge)
        }
        setLoading(true)
        dispatch(
            updateBox({
                boxId: singleBox?.id,
                payload: formData,
                callBacks: {
                    success: () => {
                        setLoading(false)
                        navigate('/content')
                    },
                    err: () => {
                        setLoading(false)
                    }
                }
            })
        )
    }

    const handleFileChange = e => {
        setSelectedBadge(e.target.files[0])
        setShowCropModal(true)
    }

    async function goBack() {
        navigate('/content')
    }

    if (loading || boxLoading || userLoading) {
        return (
            <div className="page-content flex-c flex-grow-1 align-items-center justify-content-center bckg-white">
                <span className="gray-600 font-28">
                    <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                </span>
            </div>
        )
    } else {
        return (
            <>
                <BreadCrumb />
                <div className="page-content flex-c flex-grow-1 interior-view">
                    <Helmet>
                        <title>{title}</title>
                        <meta name="description" content={desc} />
                        <link rel="canonical" href={url} />
                        <meta property="og:url" content={url} />
                        <meta property="og:title" content={title} />
                        <meta property="og:description" content={desc} />
                        <meta name="twitter:title" content={title} />
                        <meta name="twitter:description" content={desc} />
                    </Helmet>
                    <div className="flex-c container">
                        <div className="flex-c row align-items-center mt-3">
                            <div className="flex-c col-md-8 col-lg-6 mt-4">
                                <div className="flex-c flex-lg-r align-items-lg-center mt-4">
                                    <div className="flex-c flex-1">
                                        <button
                                            className="touch touch-pop"
                                            onClick={goBack}
                                            type="button"
                                            title="Cancel">
                                            <i className="fa-solid fa-arrow-left"></i>
                                        </button>
                                    </div>
                                    <h1 className="flex-3 text-lg-c mt-3 mt-lg-0">
                                        {inputValue.name || singleBox?.name}
                                    </h1>
                                    <div className="flex-1" />
                                </div>
                                <p className="text-lg-c lh-20 mt-1 mb-3">
                                    Edit the details of the selected box.
                                </p>
                                <h3 className="mb-2 mt-4">Box Details</h3>
                                <span className="divider mb-3" />
                                <form onSubmit={e => e.preventDefault()}>
                                    <TextInput
                                        name={'name'}
                                        label={'Name'}
                                        value={inputValue.name}
                                        placeholder={
                                            'e.g Beginners - max 25 characters'
                                        }
                                        maxLength={'25'}
                                        required={true}
                                        onChange={handleOnChange}
                                    />
                                    <DropDownInput
                                        name={'type'}
                                        label={'Category'}
                                        value={inputValue.type}
                                        onChange={handleOnChange}
                                        options={formatData(
                                            others?.boxTypeList
                                        )}
                                    />
                                    <DropDownInput
                                        name={'order'}
                                        label={'Order'}
                                        value={inputValue.order}
                                        onChange={handleOnChange}
                                        options={formatData(
                                            others?.itemOrderList
                                        )}
                                    />
                                    {inputValue.order != 0 && (
                                        <DropDownInput
                                            name={'lockId'}
                                            label={'Locked By'}
                                            value={inputValue.lockId}
                                            onChange={handleOnChange}
                                            options={getLockedByBoxes()}
                                        />
                                    )}
                                    <BadgeInput
                                        title={'Badge'}
                                        content={`Upload a unique image for your box. You may
                                    update this at a later time.`}
                                        selectedBadge={selectedBadge}
                                        handleFileChange={handleFileChange}
                                        setSelectedBadge={setSelectedBadge}
                                    />
                                    {showCropModal && (
                                        <Crop
                                            setSelectedBadge={setSelectedBadge}
                                            image={selectedBadge}
                                            showModal={setShowCropModal}
                                        />
                                    )}
                                    <div
                                        onClick={handleUpdate}
                                        className="btn btn-secondary mb-4"
                                        title="Update Box">
                                        Update Box
                                    </div>
                                    <div className="text-c">
                                        <Link
                                            className="gray-700 hover fw-500"
                                            to={'/content'}
                                            title="Cancel">
                                            Cancel
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default BoxEdit
