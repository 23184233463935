import { axiosInstance } from '../config/helpers/axios'

const teamService = {
    //for providers
    inviteClient: (teamId, payload) => {
        return axiosInstance.post('/team/' + teamId + '/send-invite', payload)
    },
    removeClient: (teamId, payload) => {
        return axiosInstance.post('/team/' + teamId + '/remove-client', payload)
    },
    deleteInvite: (teamId, payload) => {
        return axiosInstance.post('/team/' + teamId + '/delete-invite', payload)
    },
    acceptRequest: (teamId, payload) => {
        return axiosInstance.post(
            '/team/' + teamId + '/accept-request',
            payload
        )
    },
    declineRequest: (teamId, payload) => {
        return axiosInstance.post(
            '/team/' + teamId + '/decline-request',
            payload
        )
    },
    removeProvider: (teamId, payload) => {
        return axiosInstance.post(
            '/team/' + teamId + '/remove-provider',
            payload
        )
    },
    updateProviderTeam: params => {
        return axiosInstance.put('/provider-team', params)
    },

    //all users
    allInvites: () => {
        return axiosInstance.get('/team/invitations')
    },
    allRequests: () => {
        return axiosInstance.get('/team/requests')
    },
    clearAll: payload => {
        return axiosInstance.post('/clear-data', payload)
    },
    getTeam: () => {
        return axiosInstance.get('/team')
    },
    applyTeamPromo: teamPromo => {
        return axiosInstance.get(`/apply-team-promo?teamPromo=${teamPromo}`)
    },
    getSubProviders: () => {
        return axiosInstance.get('/sub-providers')
    }
}

export default teamService
