import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { resolveUrl } from '../config/helpers'

const title = 'Admin | Cardeon - Admin'
const url = 'https://cardeon.io/admin'
const desc = 'Cardeon Admin.'

function Admin() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    // const user = useSelector(state => state.user.value)
    const { loading: userLoading, user: user } = useSelector(
        state => state.user
    )
    const [loading, setLoading] = useState(false)
    const [searchVal, setSearchVal] = useState('')
    const [filteredMembers, setFilteredMembers] = useState([])

    // TODO
    // Temp Test Data - to be replaced with endpoint returned data

    const providerList = [
        {
            id: '123',
            firstname: 'John',
            lastname: 'Doe',
            gravatar: 'rgb(255, 117, 25)',
            profileMedia: null
        },
        {
            id: '456',
            firstname: 'Sarah',
            lastname: 'Longs',
            gravatar: 'rgb(255, 117, 25)',
            profileMedia: null
        },
        {
            id: '789',
            firstname: 'Bob',
            lastname: 'Bricks',
            gravatar: 'rgb(255, 117, 25)',
            profileMedia: null
        },
        {
            id: '101',
            firstname: 'Lauren',
            lastname: 'Sticks',
            gravatar: 'rgb(255, 117, 25)',
            profileMedia: null
        },
        {
            id: '321',
            firstname: 'Jordan',
            lastname: 'Tacks',
            gravatar: 'rgb(255, 117, 25)',
            profileMedia: null
        }
    ]

    useEffect(() => {
        async function init() {
            setLoading(true)
            if (user.role === 'Admin') {
                setLoading(false)
            }
        }
        init()
    }, [user])

    useEffect(() => {
        if (searchVal == '') {
            setFilteredMembers(providerList)
        }
        if (providerList && providerList?.length) {
            const splittedSearch = searchVal.toLowerCase().split(',')
            const filtered = providerList?.filter(b => {
                const teamWords = b.firstname.toLowerCase().split(' ')
                return teamWords.some(v => {
                    return (
                        splittedSearch.some(s => v.startsWith(s)) ||
                        v.startsWith(searchVal.toLowerCase())
                    )
                })
            })
            setFilteredMembers(filtered)
        }
    }, [searchVal])

    useEffect(() => {
        setFilteredMembers(providerList)
        //TODO
        //Compare this to content & teams with real data
    }, [])

    const searchHandler = e => {
        setSearchVal(e.target.value)
    }

    if (loading) {
        return (
            <div className="page-content flex-c flex-grow-1 align-items-center justify-content-center bckg-white">
                <span className="gray-600 font-28">
                    <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                </span>
            </div>
        )
    } else {
        return (
            <div className="page-content flex-c flex-grow-1 interior-view">
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={desc} />
                    <link rel="canonical" href={url} />
                    <meta property="og:url" content={url} />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={desc} />
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={desc} />
                </Helmet>
                <div className="flex-c px-4 mt-3">
                    <div className="flex-r align-items-end mb-1">
                        <h1 className="flex-r font-24 lh-20 pr-1">Providers</h1>
                        <span className="fw-300 font-16 gray-700 pl-2 lh-20">
                            (4)
                        </span>
                    </div>
                    <div className="flex-c input-wrapper my-4 mb-0">
                        <input
                            onChange={searchHandler}
                            type="text"
                            className="form-control"
                            id="search"
                            value={searchVal}
                            placeholder="Search existing members"
                            required
                        />
                    </div>
                    <div className="flex-c">
                        {filteredMembers?.length > 0 ? (
                            filteredMembers.map((item, index) => (
                                <div
                                    key={index}
                                    onClick={() => {
                                        navigate(`/admin/edit?id=${item.id}`)
                                    }}
                                    title={`View ${item.firstname} ${item.lastname[0]}.`}
                                    className={`flex-r data-list align-items-center border-g300 ${
                                        filteredMembers.length - 1 === index
                                            ? 'border-bottom-g300'
                                            : 'border-bottom-reset'
                                    } ${index === 0 ? 'border-r-6-top' : ''} ${
                                        filteredMembers.length - 1 === index
                                            ? 'border-r-6-bottom'
                                            : ''
                                    }`}>
                                    <div
                                        className={`flex-c img-data-item ${
                                            item?.profileMedia
                                                ? 'bckg-white'
                                                : 'bckg-gravatar'
                                        }`}>
                                        {item?.profileMedia ? (
                                            <img
                                                src={resolveUrl(
                                                    item?.profileMedia
                                                )}
                                                alt="User Image"
                                                title="User Image"
                                            />
                                        ) : (
                                            <div className="flex-r align-items-center justify-content-center flex-grow-1 white fw-500">
                                                <span>
                                                    {item?.firstname[0]}
                                                    {item?.lastname[0]}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex-r flex-1 dl-item-title fw-500 ml-3">
                                        {item?.firstname} {item?.lastname[0]}
                                    </div>
                                    <div
                                        type="button"
                                        className="d-flex btn-menu"
                                        onClick={e => {
                                            e.stopPropagation()
                                        }}>
                                        <i className="fa-solid fa-chevron-right font-14"></i>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <h2>No members found.</h2>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default Admin
