import React from 'react'
import { useSelector } from 'react-redux'
import { PieChart } from 'react-minimal-pie-chart'
import { useLocation } from 'react-router-dom'

function DimensionContent() {
    const location = useLocation()
    const { pathname } = location
    const { loading: userLoading, user: user } = useSelector(
        state => state.user
    )
    const { loading: memberLoading, member: member } = useSelector(
        state => state.member
    )
    const m = pathname === '/profile' ? member : user

    return (
        <div className="flex-c">
            {m.dimensions.items.map((item, index) => (
                <div
                    key={index}
                    className={`flex-r align-items-center basic-wrapper ${
                        m.dimensions.items.length - 1 !== index ? 'mb-2' : ''
                    }`}>
                    <div className="flex-c circle-md mr-3">
                        <PieChart
                            data={[
                                {
                                    title:
                                        item.type.charAt(0).toUpperCase() +
                                        item.type.slice(1),
                                    value: (item.score / item.total) * 100,
                                    color: item.clrP
                                }
                            ]}
                            background={item.clrS}
                            startAngle={270}
                            totalValue={100}
                        />
                    </div>
                    <div className="flex-c flex-1">
                        <span className="fw-500">
                            {item.type.charAt(0).toUpperCase() +
                                item.type.slice(1)}
                        </span>
                        <div className="flex-r font-14">
                            <span>{item.score}</span>
                            <span className="gray-700 mx-1">/</span>
                            <span>{item.total}</span>
                        </div>
                    </div>
                    <span>
                        {`${Math.round((item.score / item.total) * 100)}` + '%'}
                    </span>
                </div>
            ))}
        </div>
    )
}

export default DimensionContent
