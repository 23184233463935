import { createSlice } from '@reduxjs/toolkit'

const modalConvSlice = createSlice({
    name: 'modalConv',
    initialState: {
        value: ''
    },
    reducers: {
        modalConvState: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { modalConvState } = modalConvSlice.actions

export default modalConvSlice.reducer
