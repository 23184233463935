import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail
} from 'firebase/auth'
import { auth } from '../firebase'
import secureLocalStorage from 'react-secure-storage'
import jwt_decode from 'jwt-decode'
import store from '../store'
import { resetUser } from '../config/slice/user.slice'
import { selectedPlan } from '../config/slice/selectedPlan.slice'
import { userSubs } from '../config/slice/subs.slice'

const authService = {
    // Token management
    setLockToken: token => {
        return secureLocalStorage.setItem('accessToken', token)
    },
    getLockToken: async () => {
        const token = await secureLocalStorage.getItem('accessToken')
        return token
    },
    deleteLockToken: () => {
        return secureLocalStorage.removeItem('accessToken')
    },
    setTokenExpiration: expiredAt => {
        return secureLocalStorage.setItem('expiredAt', expiredAt)
    },
    getTokenExpiration: async () => {
        const expiredAt = await secureLocalStorage.getItem('expiredAt')
        return expiredAt
    },
    deleteTokenExpiration: () => {
        return secureLocalStorage.removeItem('expiredAt')
    },
    setToken: async token => {
        try {
            let jwt = jwt_decode(token)
            let expiredAt = jwt.exp
            await authService.setLockToken(token)
            await authService.setTokenExpiration(expiredAt)
        } catch (e) {
            authService.logout().then(() => {
                store.dispatch(resetUser())
                store.dispatch(selectedPlan(''))
                store.dispatch(userSubs(''))
            })
            console.error(e)
        }
    },
    getNewToken: async () => {
        try {
            const idTokenResult = await auth.currentUser?.getIdTokenResult()
            authService.setToken(idTokenResult)
            return idTokenResult?.token
        } catch (e) {
            authService.logout().then(() => {
                store.dispatch(resetUser())
                store.dispatch(selectedPlan(''))
                store.dispatch(userSubs(''))
            })
            console.error(e)
        }
    },
    verifyToken: async () => {
        try {
            const expiredAt = await authService.getTokenExpiration()
            const today = Date.now() / 1000
            if (expiredAt !== null) {
                if (today < expiredAt) {
                    return authService.getLockToken()
                } else {
                    return authService.getNewToken()
                }
            } else {
                return false
            }
        } catch (e) {
            authService.logout().then(() => {
                store.dispatch(resetUser())
                store.dispatch(selectedPlan(''))
                store.dispatch(userSubs(''))
            })
            console.error(e)
        }
    },

    // Auth methods
    loginEmail: async (email, password) => {
        try {
            let response = await signInWithEmailAndPassword(
                auth,
                email,
                password
            )
            if (response.user.accessToken) {
                await authService.setToken(response.user.accessToken)

                return {
                    token: response.user.accessToken,
                    currentUser: response.user
                }
            } else {
                return response
            }
        } catch (e) {
            return e.code
        }
    },

    signupEmail: async (email, password) => {
        try {
            let response = await createUserWithEmailAndPassword(
                auth,
                email,
                password
            )
            const user = response.user
            if (response) {
                await authService.setToken(user.accessToken)
                return user.uid
            }
        } catch (e) {
            return e.code
        }
    },

    passwordResetEmail: async email => {
        try {
            let response = await sendPasswordResetEmail(auth, email)
            return response
        } catch (e) {
            return e.code
        }
    },

    logout: async () => {
        try {
            await authService.deleteLockToken()
            await authService.deleteTokenExpiration()
            await auth.signOut()
        } catch (e) {
            throw Error(e)
        }
    }
}

export default authService
