import { createSlice } from '@reduxjs/toolkit'
import {
    getSingleCard,
    updateCard,
    createCard,
    copyCard,
    deleteCard
} from '../../actions/Content/card'

const initialState = {
    loading: false,
    singleCard: {}
}

const pendingCase = state => {
    state.loading = true
}

const rejectedCase = (state, action) => {
    state.loading = false
}

const cardSlice = createSlice({
    name: 'cards',
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        // Get Single Card
        builder
            .addCase(getSingleCard.pending, pendingCase)
            .addCase(getSingleCard.fulfilled, (state, action) => {
                state.loading = false
                state.singleCard = action.payload
            })
            .addCase(getSingleCard.rejected, rejectedCase)

        // Update Card
        builder
            .addCase(updateCard.pending, pendingCase)
            .addCase(updateCard.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(updateCard.rejected, rejectedCase)

        // Create Card
        builder
            .addCase(createCard.pending, pendingCase)
            .addCase(createCard.fulfilled, (state, action) => {
                state.loading = false
                state.singleCard = action.payload
            })
            .addCase(createCard.rejected, rejectedCase)

        // Copy card
        builder
            .addCase(copyCard.pending, pendingCase)
            .addCase(copyCard.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(copyCard.rejected, rejectedCase)

        // Copy card
        builder
            .addCase(deleteCard.pending, pendingCase)
            .addCase(deleteCard.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(deleteCard.rejected, rejectedCase)
    }
})

export default cardSlice.reducer
