import { createAsyncThunk } from '@reduxjs/toolkit'
import memberService from '../services/members.service'
import mediaService from '../services/media.service'

export const getMemberSelf = createAsyncThunk(
    'members/getMemberSelf',
    async (options, { rejectWithValue }) => {
        try {
            const data = await memberService.getMembersPromise()
            options?.callBacks?.success?.(data)
            return data
        } catch (error) {
            options?.callBacks?.err?.(error)
            return rejectWithValue('An error occured')
        }
    }
)

export const updateMemberSelf = createAsyncThunk(
    'members/updateMemberSelf',
    async (options, { rejectWithValue }) => {
        try {
            const data = await memberService.postMembersPromise(options.payload)
            options?.callBacks?.success?.(data)
            return data
        } catch (error) {
            options?.callBacks?.err?.()
            return rejectWithValue('An error occured')
        }
    }
)

export const sendConfirmCode = createAsyncThunk(
    'members/sendConfirmCOde',
    async (options, { rejectWithValue }) => {
        try {
            const res = await memberService.sendConfirmCode(options.payload)
            options?.callBacks?.success?.()
            return res
        } catch (error) {
            options?.callBacks?.err?.()
            return rejectWithValue(error)
        }
    }
)
export const sendResetPasswordCode = createAsyncThunk(
    'members/sendResetPasswordCode',
    async (options, { rejectWithValue }) => {
        try {
            const res = await memberService.sendResetPasswordCode(
                options.payload
            )
            options?.callBacks?.success?.()
            return res
        } catch (error) {
            options?.callBacks?.err?.(error)
            return rejectWithValue(error)
        }
    }
)

export const verifyConfirmCode = createAsyncThunk(
    'members/verifyConfirmCOde',
    async (options, { rejectWithValue }) => {
        try {
            const res = await memberService.verifyConfirmCode(options.payload)
            options?.callBacks?.success?.(res)
            return res
        } catch (error) {
            options?.callBacks?.err?.()
            return rejectWithValue(error)
        }
    }
)
export const confirmResetPasswordCode = createAsyncThunk(
    'members/confirmResetPasswordCode',
    async (options, { rejectWithValue }) => {
        try {
            const res = await memberService.confirmResetPasswordCode(
                options.payload
            )
            options?.callBacks?.success?.(res)
            return res
        } catch (error) {
            options?.callBacks?.err?.(error)
            return rejectWithValue(error)
        }
    }
)
export const resetPassword = createAsyncThunk(
    'members/resetPassword',
    async (options, { rejectWithValue }) => {
        try {
            const res = await memberService.resetPassword(options.payload)
            options?.callBacks?.success?.(res)
            return res
        } catch (error) {
            options?.callBacks?.err?.(error)
            return rejectWithValue(error)
        }
    }
)

export const togglePromo = createAsyncThunk(
    'members/togglePromoEmail',
    async (options, { rejectWithValue }) => {
        try {
            const res = await memberService.togglePromoEmail(options.status)
            options?.callBacks?.success?.(res)
            return res
        } catch (error) {
            options?.callBacks?.err?.()
            return rejectWithValue(error)
        }
    }
)
export const getBlockedMembers = createAsyncThunk(
    'members/getBlockedMembers',
    async (options, { rejectWithValue }) => {
        try {
            const res = await memberService.getBlockedMembers()
            options?.callBacks?.success?.(res)
            return res
        } catch (error) {
            options?.callBacks?.err?.()
            return rejectWithValue(error)
        }
    }
)

export const removeBlockedMembers = createAsyncThunk(
    'members/removeBlockedMembers',
    async (options, { rejectWithValue }) => {
        try {
            const res = await memberService.removeBlockedMembers(
                options.payload
            )
            options?.callBacks?.success?.(res)
            return res
        } catch (error) {
            options?.callBacks?.err?.()
            return rejectWithValue(error)
        }
    }
)

export const updatememberLocation = createAsyncThunk(
    'members/updatememberLocation',
    async (options, { rejectWithValue }) => {
        try {
            const res = await memberService.updateMemberLocation(
                options.payload
            )
            if (!res?.found) {
                throw new Error('Invalid Zip code')
            }
            options?.callBacks?.success?.(res)
            return res?.zip ?? ''
        } catch (error) {
            options?.callBacks?.err?.(error?.message ?? 'Error updating zip')
            return rejectWithValue(error)
        }
    }
)

export const getMembersByIdPromise = createAsyncThunk(
    'member/getMemberById',
    async (options, { rejectWithValue }) => {
        try {
            const data = await memberService.getMembersByIdPromise(options.id)
            options?.callBacks?.success?.(data)
            return data
        } catch (error) {
            options?.callBacks?.err?.()
            return rejectWithValue('An error occured')
        }
    }
)

export const updateProfileMedia = createAsyncThunk(
    'members/uploadProfileMedia',
    async (options, { rejectWithValue }) => {
        try {
            const data = await mediaService.uploadProfileMedia(options.params)
            options?.callBacks?.success?.(data)
            return `/usr/src/app/media/${data.id}_${data.filename}`
        } catch (error) {
            options?.callBacks?.err?.(error)
            return rejectWithValue('An error occured')
        }
    }
)

export const deleteProfileMedia = createAsyncThunk(
    'members/deleteProfileMedia',
    async (options, { rejectWithValue }) => {
        try {
            const data = await mediaService.deleteProfileMedia(options.params)
            options?.callBacks?.success?.(data)
            return data
        } catch (error) {
            options?.callBacks?.err?.(error)
            return rejectWithValue('An error occured')
        }
    }
)
