import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import TextInput from '../../components/inputs/TextInput'
import 'react-toastify/dist/ReactToastify.css'
import BreadCrumb from '../../components/elements/breadCrumb'
import { setBreadCrumb } from '../../config/slice/others.slice'

function AdminEdit() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    // const user = useSelector(state => state.user.value)

    const { loading: userLoading, user: user } = useSelector(
        state => state.user
    )

    // TODO
    // Tie in the correct key value pairs with real data
    const [inputValue, setInput] = useState({
        teamPromo: 'H4CD73JB',
        teamPromoState: true,
        teamPromoteamPromoExpiration: '2023-12-31',
        referPromo: '53HD91E3L7',
        referPromoState: false,
        referPromoExpiration: null,
        pSeats: 0,
        cSeats: 0,
        appliedTeamPromo: 'N/A',
        isTrialing: false,
        trialExpiration: '2022-12-31',
        subStatus: 'Active'
    })

    const [promoStateValue, setPromoState] = useState('')
    const [trialingStateValue, setTrialingState] = useState('')

    const title = 'Admin | Cardeon - Admin'
    const url = 'https://cardeon.io/admin'
    const desc = 'Cardeon Admin.'

    // Temp Test Data - to be replaced with endpoint returned data

    useEffect(() => {
        async function init() {
            setLoading(true)
            dispatch(
                setBreadCrumb([
                    {
                        name: 'Providers',
                        path: `/admin`
                    },
                    {
                        name: 'John D:edit',
                        path: `/admin/edit/John D.`
                    }
                ])
            )
            if (user.role === 'Admin') {
                setLoading(false)
            }
        }
        init()
    }, [user])

    const handleOnChange = e => {
        setInput({
            ...inputValue,
            [e.target.name]: e.target.value
        })
    }

    async function goBack() {
        navigate('/admin')
    }

    const handleUpdate = () => {
        // TODO
        // Tie in the update endpoint with payload
        let prettyJson = {}
    }

    if (loading) {
        return (
            <div className="page-content flex-c flex-grow-1 align-items-center justify-content-center bckg-white">
                <span className="gray-600 font-28">
                    <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                </span>
            </div>
        )
    } else {
        return (
            <>
                <BreadCrumb />
                <div className="page-content flex-c flex-grow-1 interior-view">
                    <Helmet>
                        <title>{title}</title>
                        <meta name="description" content={desc} />
                        <link rel="canonical" href={url} />
                        <meta property="og:url" content={url} />
                        <meta property="og:title" content={title} />
                        <meta property="og:description" content={desc} />
                        <meta name="twitter:title" content={title} />
                        <meta name="twitter:description" content={desc} />
                    </Helmet>
                    <div className="flex-c container">
                        <div className="flex-c row align-items-center mt-3">
                            <div className="flex-c col-md-8 col-lg-6 mt-4">
                                <div className="flex-c flex-lg-r align-items-lg-center mt-4 mb-5">
                                    <div className="flex-c flex-1">
                                        <button
                                            className="touch touch-pop"
                                            onClick={goBack}
                                            type="button"
                                            title="Cancel">
                                            <i className="fa-solid fa-arrow-left"></i>
                                        </button>
                                    </div>
                                    <h1 className="flex-3 text-lg-c mt-3 mt-lg-0">
                                        John D.
                                    </h1>
                                    <div className="flex-1" />
                                </div>

                                <form onSubmit={e => e.preventDefault()}>
                                    <TextInput
                                        name={'teamPromo'}
                                        label={'Team Promo'}
                                        value={inputValue.teamPromo}
                                        maxLength={'8'}
                                        onChange={handleOnChange}
                                        disabled={true}
                                    />
                                    <div className="flex-c mb-4">
                                        <label
                                            htmlFor="teamPromoState"
                                            className="mb-2">
                                            Team Promo Status
                                        </label>
                                        <div className="flex-r align-items-center">
                                            <input
                                                className="m-0"
                                                type="radio"
                                                id="activePromoState"
                                                name="activePromoState"
                                                value={
                                                    inputValue.teamPromoState
                                                }
                                                checked={
                                                    inputValue.teamPromoState
                                                }
                                                onChange={() => {
                                                    setPromoState(
                                                        !inputValue.teamPromoState
                                                    )
                                                }}
                                            />
                                            <label
                                                className="fw-400 mr-2 ml-2"
                                                htmlFor="activePromoState">
                                                Active
                                            </label>
                                            <input
                                                className="m-0 ml-2"
                                                type="radio"
                                                id="inactivePromoState"
                                                name="inactivePromoState"
                                                value={
                                                    !inputValue.teamPromoState
                                                }
                                                checked={
                                                    !inputValue.teamPromoState
                                                }
                                                onChange={() => {
                                                    setPromoState(
                                                        !inputValue.teamPromoState
                                                    )
                                                }}
                                            />
                                            <label
                                                className="fw-400 ml-2"
                                                htmlFor="inactivePromoState">
                                                Inactive
                                            </label>
                                        </div>
                                    </div>
                                    <TextInput
                                        type={'date'}
                                        name={'teamPromoExpiration'}
                                        label={'Team Promo Expiration'}
                                        value={
                                            inputValue.teamPromoteamPromoExpiration
                                        }
                                        onChange={handleOnChange}
                                    />
                                    <TextInput
                                        name={'referPromo'}
                                        label={'Referral Promo'}
                                        value={inputValue.referPromo}
                                        maxLength={'8'}
                                        onChange={handleOnChange}
                                        disabled={true}
                                    />
                                    <div className="flex-c mb-4">
                                        <label
                                            htmlFor="referPromoState"
                                            className="mb-2">
                                            Referral Promo Status
                                        </label>
                                        <div className="flex-r align-items-center">
                                            <input
                                                className="m-0"
                                                type="radio"
                                                id="activeReferState"
                                                name="activeReferState"
                                                value={
                                                    inputValue.referPromoState
                                                }
                                                checked={
                                                    inputValue.referPromoState
                                                }
                                                onChange={() => {
                                                    setPromoState(
                                                        !inputValue.referPromoState
                                                    )
                                                }}
                                            />
                                            <label
                                                className="fw-400 mr-2 ml-2"
                                                htmlFor="activeReferState">
                                                Active
                                            </label>
                                            <input
                                                className="m-0 ml-2"
                                                type="radio"
                                                id="inactiveReferState"
                                                name="inactiveReferState"
                                                value={
                                                    !inputValue.referPromoState
                                                }
                                                checked={
                                                    !inputValue.referPromoState
                                                }
                                                onChange={() => {
                                                    setPromoState(
                                                        !inputValue.referPromoState
                                                    )
                                                }}
                                            />
                                            <label
                                                className="fw-400 ml-2"
                                                htmlFor="inactiveReferState">
                                                Inactive
                                            </label>
                                        </div>
                                    </div>
                                    <TextInput
                                        name={'pSeats'}
                                        label={'Providers'}
                                        value={inputValue.pSeats}
                                        maxLength={'3'}
                                        onChange={handleOnChange}
                                    />
                                    <TextInput
                                        name={'cSeats'}
                                        label={'Clients'}
                                        value={inputValue.cSeats}
                                        maxLength={'3'}
                                        onChange={handleOnChange}
                                    />
                                    <TextInput
                                        name={'appliedTeamPromo'}
                                        label={'Applied Team Promo'}
                                        value={inputValue.appliedTeamPromo}
                                        placeholder={'N/A'}
                                        maxLength={'8'}
                                        onChange={handleOnChange}
                                    />
                                    <div className="flex-c mb-4">
                                        <label
                                            htmlFor="isTrialing"
                                            className="mb-2">
                                            Trial Status
                                        </label>
                                        <div className="flex-r align-items-center">
                                            <input
                                                className="m-0"
                                                type="radio"
                                                id="activeIsTrialing"
                                                name="activeIsTrialing"
                                                value={inputValue.isTrialing}
                                                checked={inputValue.isTrialing}
                                                onChange={() => {
                                                    setTrialingState(
                                                        !inputValue.isTrialing
                                                    )
                                                }}
                                                disabled={
                                                    inputValue.subStatus ===
                                                    'Active'
                                                }
                                            />
                                            <label
                                                className="fw-400 mr-2 ml-2"
                                                htmlFor="activeIsTrialing">
                                                Active
                                            </label>
                                            <input
                                                className="m-0 ml-2"
                                                type="radio"
                                                id="inactiveIsTrialing"
                                                name="inactiveIsTrialing"
                                                value={!inputValue.isTrialing}
                                                checked={!inputValue.isTrialing}
                                                onChange={() => {
                                                    setTrialingState(
                                                        !inputValue.isTrialing
                                                    )
                                                }}
                                                disabled={
                                                    inputValue.subStatus ===
                                                    'Active'
                                                }
                                            />
                                            <label
                                                className="fw-400 ml-2"
                                                htmlFor="inactiveIsTrialing">
                                                Inactive
                                            </label>
                                        </div>
                                    </div>
                                    <TextInput
                                        type={'date'}
                                        name={'trialExpiration'}
                                        label={'Trial Expiration'}
                                        value={inputValue.trialExpiration}
                                        onChange={handleOnChange}
                                        disabled={
                                            inputValue.subStatus === 'Active'
                                        }
                                    />
                                    <TextInput
                                        name={'subStatus'}
                                        label={'Subscription Status'}
                                        value={inputValue.subStatus}
                                        onChange={handleOnChange}
                                        disabled={true}
                                        lastItem={true}
                                    />
                                    <div
                                        onClick={handleUpdate}
                                        className="btn btn-secondary mb-4"
                                        title="Update User Settings">
                                        Update User Settings
                                    </div>
                                    <div className="text-c">
                                        <Link
                                            className="gray-700 hover fw-500"
                                            to={'/admin'}
                                            title="Cancel">
                                            Cancel
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default AdminEdit
