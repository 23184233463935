import { createAsyncThunk } from '@reduxjs/toolkit'
import packService from '../../services/pack.service'

export const getSinglePack = createAsyncThunk(
    'pack/getSinglePack',
    async (options, { rejectWithValue }) => {
        try {
            const data = await packService.getSinglePack(options.packId)
            options?.callBacks?.success?.(data)
            return data
        } catch (error) {
            options?.callBacks?.err?.()
            return rejectWithValue('An error occured')
        }
    }
)

export const updatePack = createAsyncThunk(
    'pack/updatePack',
    async (options, { rejectWithValue }) => {
        try {
            const res = await packService.update(
                options.packId,
                options.payload
            )
            const data = await packService.getSinglePack(options.packId)
            options?.callBacks?.success?.(data)
            return data
        } catch (error) {
            options?.callBacks?.err?.(error)
            return rejectWithValue(error)
        }
    }
)

export const createPack = createAsyncThunk(
    'pack/createPack',
    async (options, { rejectWithValue }) => {
        try {
            const data = await packService.create(options.payload)
            options?.callBacks?.success?.(data)
            return data
        } catch (error) {
            options?.callBacks?.err?.()
            return rejectWithValue(error)
        }
    }
)

export const createPackFromTemplate = createAsyncThunk(
    'pack/createPackFromTemplate',
    async (options, { rejectWithValue }) => {
        try {
            const data = await packService.createFromTemplate(options.payload)
            options?.callBacks?.success?.(data)
            return data
        } catch (error) {
            options?.callBacks?.err?.()
            return rejectWithValue(error)
        }
    }
)

export const copyPack = createAsyncThunk(
    'pack/copyPack',
    async (options, { rejectWithValue }) => {
        try {
            const data = await packService.copyPack(options.payload)
            options?.callBacks?.success?.(data)
            return data
        } catch (error) {
            options?.callBacks?.err?.()
            return rejectWithValue(error)
        }
    }
)

export const deletePack = createAsyncThunk(
    'pack/deletePack',
    async (options, { rejectWithValue }) => {
        try {
            const data = await packService.delete(options.packId)
            options?.callBacks?.success?.(data)
            return data
        } catch (error) {
            options?.callBacks?.err?.()
            return rejectWithValue(error)
        }
    }
)
