import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import { getTeam, getSubProviders, removeProvider } from '../actions/team'
import { getInvites } from '../actions/invites'
import { getRequests } from '../actions/requests'
import { modalState } from '../config/slice/component.slice'
import { default as ClientModal } from '../components/Modals/generalModal'
import teamService from '../services/team.service'
import moment from 'moment'
import { toast } from 'react-toastify'
import { resolveUrl } from '../config/helpers'
import 'react-toastify/dist/ReactToastify.css'
import TeamCode from '../components/elements/teamCode'

function MyTeam() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [searchVal, setSearchVal] = useState('')
    const [pSearchVal, setpSearchVal] = useState('')
    const [clientValue, setClient] = useState('')
    const [notifyState, setNotifyState] = useState(false)
    const [teamCodeState, setTeamCodeState] = useState(false)
    const [filteredMembers, setFilteredMembers] = useState([])
    const [filteredProviders, setFilteredProviders] = useState([])
    const { loading: userLoading, user: user } = useSelector(
        state => state.user
    )
    const subs = useSelector(state => state.subs.value)
    const [clientModalBody, setClientModalBody] = useState()
    const [showClientModal, setShowClientModal] = useState(false)
    const [showSeatsModal, setShowSeatsModal] = useState(false)
    const {
        loading: teamLoading,
        team: myTeam,
        subProviders
    } = useSelector(state => state.team)
    const { loading: invitesLoading, invites: myInvites } = useSelector(
        state => state.invites
    )
    const { loading: requestsLoading, requests: myRequests } = useSelector(
        state => state.requests
    )

    const notificationTotal =
        myInvites[0]?.data?.length + myRequests[0]?.data?.length

    const title = 'Team | Cardeon - Team'
    const url = 'https://cardeon.io/team'
    const desc = 'Review and manage your team.'

    const toggleNotify = () => {
        if (teamCodeState) {
            setTeamCodeState(false)
        }
        setNotifyState(!notifyState)
    }
    const toggleTeamCodeState = () => {
        if (notifyState) {
            setNotifyState(false)
        }
        setTeamCodeState(!teamCodeState)
    }

    const onCloseSeatModal = () => {
        setShowSeatsModal(false)
    }
    const notifyTimeStamp = item => {
        const timeCreated = moment(item)
        const currentTime = moment(new Date())
        const hours = currentTime.diff(timeCreated, 'hours')
        let timestamp
        if (hours <= 24) {
            timestamp = moment(timeCreated).format('h:mm A')
        } else if (hours > 24 && hours <= 48) {
            timestamp = 'Yesterday'
        } else if (hours > 48 && hours < 168) {
            timestamp = moment(timeCreated).format('dddd')
        } else if (hours >= 168) {
            timestamp = moment(timeCreated).format('MM/DD')
        }
        return timestamp
    }

    const activityTimeStamp = item => {
        const timeCreated = moment(item)
        const currentTime = moment(new Date())
        const hours = currentTime.diff(timeCreated, 'hours')
        let timestamp
        if (hours <= 24) {
            timestamp = 'today'
        } else if (hours > 24 && hours <= 48) {
            timestamp = '1 day ago'
        } else if (hours > 48 && hours <= 72) {
            timestamp = '2 days ago'
        } else if (hours > 72 && hours <= 96) {
            timestamp = '3 days ago'
        } else if (hours > 96 && hours <= 120) {
            timestamp = '5 days ago'
        } else if (hours > 120 && hours <= 144) {
            timestamp = '6 days ago'
        } else {
            timestamp = 'more than a week ago'
        }
        return timestamp
    }

    useEffect(() => {
        async function init() {
            setLoading(true)
            if (Object.keys(user).length > 0) {
                if (user?.agreement != undefined && user?.agreement === false) {
                    document.body.classList.add('o-flow-y-hide')
                    dispatch(modalState(true))
                }
                dispatch(getTeam())
                getIntReq()
                dispatch(getSubProviders())
            }
            setLoading(false)
        }
        init()
    }, [user])

    const getIntReq = async () => {
        dispatch(getInvites())
        dispatch(getRequests())
    }

    useEffect(() => {
        if (searchVal == '') {
            setFilteredMembers(myTeam.members)
        }
        if (myTeam && myTeam?.members?.length) {
            const splittedSearch = searchVal.toLowerCase().split(',')
            const filtered = myTeam?.members?.filter(b => {
                const teamWords = b.firstname.toLowerCase().split(' ')
                return teamWords.some(v => {
                    return (
                        splittedSearch.some(s => v.startsWith(s)) ||
                        v.startsWith(searchVal.toLowerCase())
                    )
                })
            })
            setFilteredMembers(filtered)
        }
    }, [searchVal])

    useEffect(() => {
        setFilteredMembers(myTeam.members)
    }, [myTeam])

    useEffect(() => {
        if (pSearchVal == '') {
            setFilteredProviders(subProviders)
        }
        if (subProviders?.length) {
            const splittedSearch = pSearchVal.toLowerCase().split(',')
            const filtered = subProviders?.filter(b => {
                const teamWords = b.firstname.toLowerCase().split(' ')
                return teamWords.some(v => {
                    return (
                        splittedSearch.some(s => v.startsWith(s)) ||
                        v.startsWith(pSearchVal.toLowerCase())
                    )
                })
            })
            setFilteredProviders(filtered)
        }
    }, [pSearchVal])

    useEffect(() => {
        setFilteredProviders(subProviders)
    }, [subProviders])

    const searchHandler = e => {
        setSearchVal(e.target.value)
    }

    const pSearchHandler = e => {
        setpSearchVal(e.target.value)
    }

    const removeClient = async () => {
        setLoading(true)
        setShowClientModal(false)
        try {
            let prettyJson = {
                client: clientValue.id
            }
            let res = await teamService.removeClient(user.team.id, prettyJson)
            if (res) {
                toast.success('Successfully removed the client.', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
                dispatch(getTeam())
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
            toast.error('Error removing client.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    }

    const clearInvite = async () => {
        setLoading(true)
        setShowClientModal(false)
        try {
            // Provider clears an invite
            let prettyJson = {
                ids: [clientValue.inviteId]
            }
            let res = await teamService.clearAll(prettyJson)
            if (res) {
                getIntReq()
                toast.success('Successfully cleared the invite.', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
            toast.error('Error clearing invite.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    }

    const manageInvite = async () => {
        setLoading(true)
        setShowClientModal(false)
        try {
            // Provider withdraws their own invite
            let prettyJson = {
                client: clientValue.id
            }
            let res = await teamService.deleteInvite(user.team.id, prettyJson)
            if (res) {
                getIntReq()
                toast.success('Successfully deleted the invite.', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
            toast.error('Error deleting invite.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    }

    const manageRequest = async () => {
        setLoading(true)
        setShowClientModal(false)
        try {
            // Provider declines a client request
            let prettyJson = {
                client: clientValue.id
            }
            let res = await teamService.declineRequest(user.team.id, prettyJson)
            if (res) {
                getIntReq()
                toast.success('Successfully declined the request.', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
            toast.error('Error declining the request.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    }

    const acceptRequest = async () => {
        setLoading(true)
        setShowClientModal(false)
        try {
            // Provider accepts a client request
            let prettyJson = {
                client: clientValue.id
            }
            let res = await teamService.acceptRequest(user.team.id, prettyJson)
            if (res) {
                dispatch(getTeam())
                getIntReq()
                toast.success('New team member added.', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
            toast.error('Error adding new team member.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    }

    const removeProviderFunc = async () => {
        setLoading(true)
        setShowClientModal(false)
        let prettyJson = {
            provider: clientValue.id
        }
        dispatch(
            removeProvider({
                teamId: user.team.id,
                payload: prettyJson,
                callBacks: {
                    success: data => {
                        dispatch(getTeam())
                        dispatch(getSubProviders())
                        getIntReq()
                        setLoading(false)
                        toast.success('Provider Removed', {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        })
                    },
                    err: () => {
                        setLoading(false)
                        toast.error('Error adding new team member.', {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        })
                    }
                }
            })
        )
    }

    const stageManageInvite = async item => {
        setClient(item)
        setClientModalBody({
            title: `Withdraw Invitation`,
            copy: `Are you sure you want to withdraw this invitation? You can re-invite this client at any time. Do you want to proceed?`,
            btnText: `Yes, Remove Invitation`,
            function: `manageInvite`
        })
        setShowClientModal(true)
    }

    const stageClearInvite = async item => {
        setClient(item)
        setClientModalBody({
            title: `Clear Invitation`,
            copy: `Are you sure you want to clear this invitation? This action can not be undone. Do you want to proceed?`,
            btnText: `Yes, Clear Invitation`,
            function: `clearInvite`
        })
        setShowClientModal(true)
    }

    const belongTeamPromoState = member => {
        if (!member.appliedTeamPromo) {
            return false
        }
        return member.appliedTeamPromoState
    }

    const showTeamCode = (subs, user) => {
        if (subs && subs?.subscription?.status) {
            return false
        }
        if (belongTeamPromoState(user)) {
            return false
        }
        return true
    }

    if (
        loading ||
        userLoading ||
        teamLoading ||
        invitesLoading ||
        requestsLoading
    ) {
        return (
            <div className="page-content flex-c flex-grow-1 align-items-center justify-content-center bckg-white">
                <span className="gray-600 font-28">
                    <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                </span>
            </div>
        )
    }

    if (showTeamCode(subs, user)) {
        return (
            <div className="page-content flex-c flex-grow-1 align-items-center interior-view">
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={desc} />
                    <link rel="canonical" href={url} />
                    <meta property="og:url" content={url} />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={desc} />
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={desc} />
                </Helmet>
                <div className="flex-c align-items-center mt-4 mx-2">
                    <h1 className="flex-r font-24 lh-20 pr-1">
                        No Subscription
                    </h1>
                    <p>
                        You don't have an active trial or subscription.
                        Subscribe now or enter promo code below
                    </p>
                    <div className="align-self-center">
                        <TeamCode />
                    </div>
                    <Link
                        type="button"
                        className="fw-700 ml-sm-3"
                        title="View Plans"
                        to="/pricing">
                        View Plans
                    </Link>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="flex-r flex-grow-1">
                <div className="page-content flex-c flex-grow-1 interior-view">
                    <Helmet>
                        <title>{title}</title>
                        <meta name="description" content={desc} />
                        <link rel="canonical" href={url} />
                        <meta property="og:url" content={url} />
                        <meta property="og:title" content={title} />
                        <meta property="og:description" content={desc} />
                        <meta name="twitter:title" content={title} />
                        <meta name="twitter:description" content={desc} />
                    </Helmet>
                    <div className="flex-c px-4 mt-3 mb-5">
                        <div className="flex-r align-items-end mb-1">
                            <h1 className="flex-r font-24 lh-20 pr-1">
                                Clients
                            </h1>
                            <span className="fw-300 font-16 gray-700 pl-2 lh-16">
                                {myTeam?.members?.length}
                                <span className="px-1">/</span>
                                {parseInt(subs?.subscription?.seatCount ?? 0) +
                                    user?.cSeats}
                            </span>
                        </div>
                        <div className="flex-r my-4">
                            <div className="input-wrapper flex-1 mb-0">
                                <input
                                    onChange={searchHandler}
                                    type="text"
                                    className="form-control"
                                    id="search"
                                    value={searchVal}
                                    placeholder="Search existing clients"
                                    required
                                />
                            </div>
                            <Link
                                className="btn btn-primary-alt flex-0 ml-3"
                                to={'/search'}
                                title="Find New Members">
                                <span className="display-hide display-sm-flex">
                                    Find New Members
                                </span>
                                <i className="display-sm-hide fa-solid fa-plus"></i>
                            </Link>
                            <Link
                                className="btn btn-tile ml-3"
                                onClick={e => {
                                    toggleTeamCodeState()
                                }}
                                title="Team Code">
                                <i className="fa-solid fa-address-book"></i>
                            </Link>
                            <Link
                                className="btn btn-tile ml-3"
                                onClick={e => {
                                    toggleNotify()
                                }}
                                title="Team Notifications">
                                <i className="fa-solid fa-bell"></i>
                                {notificationTotal > 0 && (
                                    <div className="badge">
                                        {notificationTotal}
                                    </div>
                                )}
                            </Link>
                        </div>
                        <div className="flex-c flex-sm-r flex-wrap">
                            {filteredMembers?.length > 0 ? (
                                filteredMembers.map((item, index) => (
                                    <div
                                        type="button"
                                        key={index}
                                        className="flex-r align-items-center bckg-white widget hover-itm"
                                        onClick={e => {
                                            navigate(`/profile?id=${item.id}`)
                                        }}>
                                        <div
                                            className="flex-c img-user img-user-md mr-2"
                                            style={{
                                                backgroundColor: item.gravatar
                                            }}>
                                            {item.profilemedia ? (
                                                <img
                                                    src={resolveUrl(
                                                        item.profilemedia
                                                    )}
                                                    className="white fw-500"
                                                    alt={
                                                        item.firstname[0] +
                                                        item.lastname[0]
                                                    }
                                                    title="Profile Image"
                                                />
                                            ) : (
                                                <div className="flex-r align-items-center justify-content-center flex-grow-1 white fw-500">
                                                    <span>
                                                        {item.firstname[0]}
                                                    </span>
                                                    <span>
                                                        {item.lastname[0]}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex-c flex-1 ml-1">
                                            <span className="fw-500 pb-1">
                                                {item.firstname}{' '}
                                                {item.lastname[0]}.
                                            </span>
                                            <span className="gray-700 font-14">
                                                {' '}
                                                Active{' '}
                                                {activityTimeStamp(
                                                    item.lastlogin
                                                )}
                                            </span>
                                        </div>
                                        <div
                                            type="button"
                                            className="d-flex btn-menu"
                                            onClick={e => {
                                                e.stopPropagation()
                                            }}>
                                            <i
                                                className="fa-solid fa-user-minus font-14"
                                                title="Remove Team Member"
                                                onClick={e => {
                                                    setClient(item)
                                                    setClientModalBody({
                                                        title: `Remove: ${item.firstname} ${item.lastname[0]}.`,
                                                        copy: `Are you sure you want to remove ${item.firstname}? You can re-add this client at any time. Do you want to proceed?`,
                                                        btnText: `Yes, Remove ${item.firstname}`,
                                                        function: `removeClient`
                                                    })
                                                    setShowClientModal(true)
                                                }}></i>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <h2>No clients found.</h2>
                            )}
                        </div>
                    </div>

                    <div className="flex-c px-4">
                        <div className="flex-r align-items-end mb-1">
                            <h1 className="flex-r font-24 lh-20 pr-1">
                                Providers
                            </h1>
                            <span className="fw-300 font-16 gray-700 pl-2 lh-16">
                                {subProviders?.length}
                                <span className="px-1">/</span>
                                {user?.pSeats}
                            </span>
                        </div>
                        <div className="flex-r my-4">
                            <div className="input-wrapper flex-1 mb-0">
                                <input
                                    onChange={pSearchHandler}
                                    type="text"
                                    className="form-control"
                                    id="search"
                                    value={pSearchVal}
                                    placeholder="Search existing providers"
                                    required
                                />
                            </div>
                        </div>
                        <div className="flex-c flex-sm-r flex-wrap">
                            {filteredProviders?.length > 0 ? (
                                filteredProviders.map((item, index) => (
                                    <div
                                        type="button"
                                        key={index}
                                        className="flex-r align-items-center bckg-white widget hover-itm"
                                        onClick={e => {
                                            navigate(`/profile?id=${item.id}`)
                                        }}>
                                        <div
                                            className="flex-c img-user img-user-md mr-2"
                                            style={{
                                                backgroundColor: item.gravatar
                                            }}>
                                            {item.profilemedia ? (
                                                <img
                                                    src={resolveUrl(
                                                        item.profilemedia
                                                    )}
                                                    className="white fw-500"
                                                    alt={
                                                        item.firstname[0] +
                                                        item.lastname[0]
                                                    }
                                                    title="Profile Image"
                                                />
                                            ) : (
                                                <div className="flex-r align-items-center justify-content-center flex-grow-1 white fw-500">
                                                    <span>
                                                        {item.firstname[0]}
                                                    </span>
                                                    <span>
                                                        {item.lastname[0]}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex-c flex-1 ml-1">
                                            <span className="fw-500 pb-1">
                                                {item.firstname}{' '}
                                                {item.lastname[0]}.
                                            </span>
                                            <span className="gray-700 font-14">
                                                {' '}
                                                Active{' '}
                                                {activityTimeStamp(
                                                    item.lastlogin
                                                )}
                                            </span>
                                        </div>
                                        <div
                                            type="button"
                                            className="d-flex btn-menu"
                                            onClick={e => {
                                                e.stopPropagation()
                                            }}>
                                            <i
                                                className="fa-solid fa-user-minus font-14"
                                                title="Remove Team Member"
                                                onClick={e => {
                                                    setClient(item)
                                                    setClientModalBody({
                                                        title: `Remove: ${item.firstname} ${item.lastname[0]}.`,
                                                        copy: `Are you sure you want to remove ${item.firstname}? You can re-add this provider at any time. Do you want to proceed?`,
                                                        btnText: `Yes, Remove ${item.firstname}`,
                                                        function: `removeProvider`
                                                    })
                                                    setShowClientModal(true)
                                                }}></i>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <h2>No providers found.</h2>
                            )}
                        </div>
                    </div>
                </div>
                <div
                    className={`page-content flex-c notify-bar pt-0 ${
                        teamCodeState ? 'visible' : ''
                    }`}>
                    <div className="flex-c p-4">
                        <span className={`fw-700 font-18 'mb-3'`}>
                            Team Code
                        </span>
                        <TeamCode />
                    </div>
                </div>
                <div
                    className={`page-content flex-c notify-bar pt-0 ${
                        notifyState ? 'visible' : ''
                    }`}>
                    <div className="flex-c p-4">
                        <span
                            className={`fw-700 font-18 ${
                                myRequests[0]?.data?.length === 0
                                    ? 'mb-1'
                                    : 'mb-3'
                            }`}>
                            {myRequests[0]?.title}
                        </span>
                        {myRequests[0]?.data?.length === 0 && (
                            <span className="italic font-14 mb-4">
                                No pending requests.
                            </span>
                        )}
                        {myRequests[0]?.data?.map((item, index) => (
                            <div
                                key={index}
                                className="flex-r align-items-center bckg-white basic-wrapper mb-2">
                                <div className="flex-c bckg-primary img-user img-user-md mr-2">
                                    {item.profilemedia ? (
                                        <img
                                            src={resolveUrl(item.profilemedia)}
                                            alt={
                                                item.firstname[0] +
                                                item.lastname[0]
                                            }
                                            className="white fw-500"
                                            title="Profile Image"
                                        />
                                    ) : (
                                        <div className="flex-r align-items-center justify-content-center flex-grow-1 white fw-500">
                                            <span>{item.firstname[0]}</span>
                                            <span>{item.lastname[0]}</span>
                                        </div>
                                    )}
                                </div>
                                <div className="flex-c flex-1 ml-1">
                                    <span className="fw-500 pb-1">
                                        {item.firstname} {item.lastname[0]}
                                    </span>
                                    <span
                                        className={`font-14 ${
                                            item.declined
                                                ? 'red'
                                                : item.accepted
                                                ? 'confirm'
                                                : 'gray-700'
                                        }`}>
                                        {item.declined
                                            ? 'Declined'
                                            : item.accepted
                                            ? 'Accepted'
                                            : notifyTimeStamp(item.createdat)}
                                    </span>
                                </div>

                                {!item.declined && (
                                    <div
                                        type="button"
                                        className="d-flex btn-menu"
                                        onClick={e => {
                                            e.stopPropagation()
                                        }}>
                                        <i
                                            className="fas fa-user-plus font-14"
                                            onClick={e => {
                                                if (
                                                    myTeam?.members?.length <
                                                    subs?.subscription
                                                        ?.seatCount
                                                ) {
                                                    setClient(item)
                                                    setClientModalBody({
                                                        title: `Accept: ${item.firstname} ${item.lastname[0]}.`,
                                                        copy: `Are you sure you want to accept ${item.firstname}? This will utilize 1 available seat. Do you want to proceed?`,
                                                        btnText: `Yes, Accept ${item.firstname}`,
                                                        function: `acceptRequest`
                                                    })
                                                    setShowClientModal(true)
                                                } else {
                                                    setShowSeatsModal(true)
                                                }
                                            }}
                                            title="Accept this Request"></i>
                                    </div>
                                )}
                                {!item.accepted && (
                                    <div
                                        type="button"
                                        className="d-flex btn-menu"
                                        onClick={e => {
                                            e.stopPropagation()
                                        }}>
                                        <i
                                            className="fas fa-trash-can font-14"
                                            onClick={e => {
                                                setClient(item)
                                                setClientModalBody({
                                                    title: `Decline: ${item.firstname} ${item.lastname[0]}.`,
                                                    copy: `Are you sure you want to decline ${item.firstname}? You can invite this client at a later time. Do you want to proceed?`,
                                                    btnText: `Yes, Decline ${item.firstname}`,
                                                    function: `manageRequest`
                                                })
                                                setShowClientModal(true)
                                            }}
                                            title="Decline this Request"></i>
                                    </div>
                                )}
                            </div>
                        ))}

                        <span
                            className={`fw-700 font-18 ${
                                myRequests[0]?.data?.length === 0
                                    ? 'mt-0'
                                    : 'mt-4'
                            } ${
                                myInvites[0]?.data?.length === 0
                                    ? 'mb-1'
                                    : 'mb-3'
                            }`}>
                            {myInvites[0]?.title}
                        </span>
                        {myInvites[0]?.data?.length === 0 && (
                            <span className="italic font-14 mb-2">
                                No pending invites.
                            </span>
                        )}
                        {myInvites[0]?.data?.map((item, index) => (
                            <div
                                key={index}
                                className="flex-r align-items-center bckg-white basic-wrapper mb-2">
                                <div className="flex-c bckg-primary img-user img-user-md mr-2">
                                    {item.profilemedia ? (
                                        <img
                                            src={resolveUrl(item.profilemedia)}
                                            alt={
                                                item.firstname[0] +
                                                item.lastname[0]
                                            }
                                            className="white fw-500"
                                            title="Profile Image"
                                        />
                                    ) : (
                                        <div className="flex-r align-items-center justify-content-center flex-grow-1 white fw-500">
                                            <span>{item.firstname[0]}</span>
                                            <span>{item.lastname[0]}</span>
                                        </div>
                                    )}
                                </div>
                                <div className="flex-c flex-1 ml-1">
                                    <span className="fw-500 pb-1">
                                        {item.firstname} {item.lastname[0]}
                                    </span>
                                    <span
                                        className={`font-14 ${
                                            item.declined
                                                ? 'red'
                                                : item.accepted
                                                ? 'confirm'
                                                : 'gray-700'
                                        }`}>
                                        {item.declined
                                            ? 'Declined'
                                            : item.accepted
                                            ? 'Accepted'
                                            : notifyTimeStamp(item.createdat)}
                                    </span>
                                </div>
                                <div
                                    type="button"
                                    className="d-flex btn-menu"
                                    onClick={e => {
                                        e.stopPropagation()
                                    }}>
                                    <i
                                        className={`fas font-14
                                                    ${
                                                        !item.declined &&
                                                        !item.accepted
                                                            ? 'fa-trash-can'
                                                            : 'fa-circle-xmark'
                                                    }
                                                    `}
                                        onClick={e => {
                                            if (
                                                !item.declined &&
                                                !item.accepted
                                            ) {
                                                stageManageInvite(item)
                                            } else {
                                                stageClearInvite(item)
                                            }
                                        }}
                                        title={
                                            !item.declined && !item.accepted
                                                ? 'Withdraw this Invitation'
                                                : 'Clear this invitation'
                                        }></i>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {showClientModal && (
                <ClientModal
                    showModal={setShowClientModal}
                    title={clientModalBody.title}
                    children={
                        <>
                            <p className="lh-24 mt-3 mb-4">
                                {clientModalBody.copy}
                            </p>
                            <Link
                                className="btn btn-secondary mb-4"
                                onClick={() => {
                                    switch (clientModalBody.function) {
                                        case 'removeClient':
                                            removeClient()
                                            break
                                        case 'manageInvite':
                                            manageInvite()
                                            break
                                        case 'clearInvite':
                                            clearInvite()
                                            break
                                        case 'manageRequest':
                                            removeClient()
                                            break
                                        case 'acceptRequest':
                                            acceptRequest()
                                            break
                                        case 'removeProvider':
                                            removeProviderFunc()
                                            break
                                        default:
                                            console.log('Unrecognized function')
                                            break
                                    }
                                }}
                                to={'/my-team'}
                                title={clientModalBody.btnText}>
                                {clientModalBody.btnText}
                            </Link>
                            <div className="text-c mb-3">
                                <Link
                                    className="gray-700 hover fw-500"
                                    onClick={e => {
                                        setShowClientModal(false)
                                        e.stopPropagation()
                                    }}
                                    to={'/my-team'}
                                    title="Cancel">
                                    Cancel
                                </Link>
                            </div>
                        </>
                    }
                />
            )}
            {showSeatsModal && (
                <div
                    className="modal-background"
                    role="dialog"
                    onClick={onCloseSeatModal}>
                    <div
                        className="col-sm-8 col-md-6 col-lg-4"
                        onClick={e => e.stopPropagation()}
                        role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    Seat Limit Reached
                                </h5>
                                <i
                                    className="fa-solid fa-xmark"
                                    onClick={onCloseSeatModal}
                                    title="Close Modal"></i>
                            </div>
                            <div className="flex-c modal-body pb-4">
                                <p className="lh-24 mb-3">
                                    You have no available seats to add new
                                    clients.
                                </p>
                            </div>
                            <div className="flex-c text-c modal-footer">
                                <Link
                                    className="btn btn-secondary mb-3"
                                    onClick={onCloseSeatModal}
                                    title="Return">
                                    Return
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default MyTeam
