import { createSlice } from '@reduxjs/toolkit'

const subsSlice = createSlice({
    name: 'subs',
    initialState: {
        value: ''
    },
    reducers: {
        userSubs: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { userSubs } = subsSlice.actions

export default subsSlice.reducer
