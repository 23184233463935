import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { modalState } from '../../config/slice/component.slice'

export function SubsFoundModal() {
    const dispatch = useDispatch()
    const subs = useSelector(state => state.subs.value)
    const hideModal = async () => {
        document.body.classList.remove('o-flow-y-hide')
        dispatch(modalState(false))
    }

    return (
        <div className="modal-background" role="dialog">
            <div className="col-sm-8 col-md-6 col-lg-4" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">
                            {subs ? 'Active Subscription' : 'Trial Ended'}
                        </h5>
                        <i
                            className="fa-solid fa-xmark gray-800"
                            onClick={hideModal}
                            title="Close Modal"></i>
                    </div>
                    <div className="modal-body pb-3">
                        <p className="lh-24 mt-2 mb-4">
                            {subs
                                ? 'You already have an active subscription.'
                                : 'You have already exhausted your free trial. Please select a paid subscription.'}
                        </p>
                    </div>
                    <div className="modal-footer">
                        <Link
                            className="btn btn-secondary"
                            onClick={hideModal}
                            title="Close Modal">
                            Close
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
