import React from 'react'

export default function GeneralModal({ title ,showModal, children }) {
    return (
        <div
            className="modal-background"
            role="dialog"
            onClick={() => showModal(false)}>
            <div
                className="col-sm-8 col-md-6 col-lg-4"
                role="document"
                onClick={e => e.stopPropagation()}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        <i
                            className="fa-solid fa-xmark gray-700"
                            onClick={() => showModal(false)}
                            title="Close Modal"></i>
                    </div>
                    <div className="flex-c modal-footer">{children}</div>
                </div>
            </div>
        </div>
    )
}
