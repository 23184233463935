import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import emailjs from 'emailjs-com'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const title = 'Contact | Cardeon - Contact Us'
const url = 'https://cardeon.io/contact'
const desc =
    'Contact Cardeon to learn more about scaled wellness coaching and acquiring more clients.'

function Contact() {
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm()

    const handleKeyDown = async event => {
        if (event.key === 'Enter') {
            onSubmit()
        }
    }

    const onSubmit = async data => {
        try {
            var templateParams = {
                name: data.name,
                email: data.email,
                message: data.message
            }

            emailjs
                .send(
                    'service_e2ooaxi',
                    'template_3s2pixp',
                    templateParams,
                    'user_zT40OpyoQhkkamRcsD2xb'
                )
                .then(function (response) {
                    toast.success('Message Sent', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    })
                })
            reset()
        } catch (e) {
            toast.warn('Message Failed', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
            return
        }
    }

    return (
        <div className="contact flex-c flex-grow-1 interior-view">
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={desc} />
                <link rel="canonical" href={url} />
                <meta property="og:url" content={url} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={desc} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={desc} />
            </Helmet>
            <div className="flex-c container">
                <div className="flex-c row">
                    <div className="flex-c col-12 pt-lg-5">
                        <div className="flex-c align-items-center">
                            <div className="flex-c text-c">
                                <h1 className="mt-4 mb-2">Contact Cardeon</h1>
                                <p className="lh-20 mt-1 mb-1">
                                    Please use the form below for any and all
                                    questions, concerns or recommendations.
                                </p>
                            </div>
                            <div className="flex-c col-sm-8 col-md-6 col-lg-4 mt-5">
                                <form
                                    className="flex-c"
                                    onSubmit={handleSubmit(onSubmit)}
                                    onKeyDown={handleKeyDown}>
                                    <div className="input-wrapper">
                                        <label
                                            className="fw-500 pl-1"
                                            aria-describedby="name">
                                            Name
                                        </label>
                                        <input
                                            type="name"
                                            className="form-control mt-2"
                                            id="name"
                                            {...register('name', {
                                                required: true,
                                                maxLength: 20
                                            })}
                                        />
                                        <div className="p-relative">
                                            <div className="input-desc red">
                                                {errors.name &&
                                                    'Name is required'}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-wrapper">
                                        <label
                                            className="fw-500 pl-1"
                                            aria-describedby="email">
                                            Email address
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control mt-2"
                                            id="email"
                                            {...register('email', {
                                                required: true,
                                                pattern:
                                                    /^\w+([\+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                                            })}
                                        />
                                        <div className="p-relative">
                                            <div className="input-desc red">
                                                {errors.email &&
                                                    'Email is required'}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-wrapper">
                                        <label
                                            className="fw-500 pl-1"
                                            aria-describedby="message">
                                            Message
                                        </label>
                                        <textarea
                                            type="message"
                                            className="form-control mt-2"
                                            id="message"
                                            {...register('message', {
                                                required: true,
                                                minLength: 5
                                            })}
                                        />
                                        <div className="p-relative">
                                            <div className="input-desc red">
                                                {errors.message &&
                                                    'Message is required'}
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        onClick={handleSubmit(onSubmit)}
                                        className="btn btn-secondary fw-700 mt-2"
                                        title="Submit">
                                        Submit
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact
