import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import authService from '../services/auth.service'
import memberService from '../services/members.service'
import subsService from '../services/subs.service'
import {
    getMemberSelf,
    updateMemberSelf,
    sendResetPasswordCode
} from '../actions/members'
import { userSubs } from '../config/slice/subs.slice'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const title = 'Enter Password | Cardeon'
const url = 'https://cardeon.io/password-entry'
const desc = 'Provide your Cardeon account password.'

function SignIn() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { search } = useLocation()
    let params = new URLSearchParams(search)
    let email = params.get('email')
    const subs = useSelector(state => state.subs.value)
    const [inputValue, setInput] = useState('')
    const [loading, setLoading] = useState(false)
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm()

    const handleKeyDown = async event => {
        if (event.key === 'Enter') {
            onLogin()
        }
    }

    async function updateTimestamp() {
        const today = new Date()
        let prettyJson = {
            lastlogin: today
        }
        dispatch(
            updateMemberSelf({
                payload: prettyJson,
                callBacks: {
                    err: () => {
                        setLoading(false)
                        toast.error('Unknown Error', {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        })
                    }
                }
            })
        )
    }

    const belongTeamPromoState = member => {
        if (!member.appliedTeamPromo) {
            return false
        }
        return member.appliedTeamPromoState
    }

    async function fetchData() {
        setLoading(true)
        dispatch(
            getMemberSelf({
                callBacks: {
                    success: async memberRes => {
                        if (memberRes && Object.keys(memberRes).length > 0) {
                            if (memberRes.team?.agreement) {
                                await getSubs()
                            }
                            if (!memberRes.isVerified) {
                                navigate(
                                    `/verification?email=${memberRes.email}`
                                )
                            } else {
                                if (memberRes.role === 'Admin') {
                                    navigate('/admin')
                                } else if (
                                    memberRes.intent == 'Provider' &&
                                    (subs != null ||
                                        belongTeamPromoState(memberRes))
                                ) {
                                    navigate('/content')
                                } else {
                                    navigate('/account')
                                }
                            }
                            setLoading(false)
                        } else {
                            setLoading(false)
                            toast.error('Unknown Error', {
                                position: 'top-right',
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined
                            })
                            return
                        }
                    },
                    err: () => {
                        setLoading(false)
                        toast.error('Unknown Error', {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        })
                        return
                    }
                }
            })
        )

        async function getSubs() {
            try {
                const sub = await subsService.getSubs()
                if (sub.member) {
                    dispatch(userSubs(sub))
                } else {
                    dispatch(userSubs(null))
                }
            } catch (e) {
                toast.error('Unknown Error', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
                return
            }
        }
    }

    const onLogin = async () => {
        if (!email) {
            toast.warn('Invalid Email address.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark'
            })
            return
        }
        setLoading(true)
        try {
            const resp = await authService.loginEmail(email, inputValue)
            if (resp === 'auth/user-not-found') {
                setLoading(false)
                toast.error('Account not Found', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'dark'
                })
                return
            } else if (resp === 'auth/wrong-password') {
                setLoading(false)
                toast.warn('Incorrect Password', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'dark'
                })
                return
            } else {
                await updateTimestamp()
                await fetchData()
            }
            reset()
        } catch (e) {
            setLoading(false)
            toast.error('Unknown Error', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark'
            })
            return
        }
    }

    async function goBack() {
        navigate('/signin')
    }

    const sendForgetPasswordCode = async () => {
        setLoading(true)
        const prettyJson = {
            email: email
        }
        dispatch(
            sendResetPasswordCode({
                payload: prettyJson,
                callBacks: {
                    success: () => {
                        setLoading(false)
                        toast.success('Code sent', {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        })
                    },
                    err: err => {
                        setLoading(false)
                    }
                }
            })
        )
    }

    if (loading) {
        return (
            <div className="auth flex-c flex-grow-1 align-items-center justify-content-center bckg-white">
                <span className="gray-600 font-28">
                    <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                </span>
            </div>
        )
    } else {
        return (
            <div className="password-entry flex-c flex-grow-1 interior-view">
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={desc} />
                    <link rel="canonical" href={url} />
                    <meta property="og:url" content={url} />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={desc} />
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={desc} />
                </Helmet>
                <div className="flex-c container">
                    <div className="flex-c row align-items-center">
                        <div className="flex-c col-12 col-sm-8 col-md-6 col-lg-10 pt-5">
                            <div className="flex-c flex-lg-r align-items-lg-center mt-4">
                                <div className="flex-c flex-1">
                                    <button
                                        className="touch touch-pop"
                                        onClick={goBack}
                                        type="button"
                                        title="Cancel">
                                        <i className="fa-solid fa-arrow-left"></i>
                                    </button>
                                </div>
                                <h1 className="flex-3 text-lg-c mt-3 mt-lg-0">
                                    Enter Password
                                </h1>
                                <div className="flex-1" />
                            </div>
                            <p className="text-lg-c lh-20 mt-1 mb-3">
                                Provide your account password.
                            </p>
                        </div>
                    </div>
                    <div className="flex-c row align-items-center">
                        <div className="flex-c col-sm-8 col-md-6 col-lg-4 mt-4">
                            <form
                                onSubmit={handleSubmit(onLogin)}
                                className="flex-c"
                                onKeyDown={handleKeyDown}>
                                <div className="input-wrapper">
                                    <label
                                        className="pl-1"
                                        aria-describedby="Password">
                                        Password
                                    </label>
                                    <input
                                        type="password"
                                        {...register('password', {
                                            required: true
                                        })}
                                        className="form-control mt-2"
                                        id="password"
                                        value={inputValue}
                                        onChange={e => setInput(e.target.value)}
                                        placeholder="password"
                                        required
                                    />
                                    <div className="input-desc red">
                                        {errors.password &&
                                            'Please provide a valid password.'}
                                    </div>
                                </div>
                                <button
                                    onClick={handleSubmit(onLogin)}
                                    className="btn btn-secondary mt-2"
                                    title="Sign In">
                                    Sign In
                                </button>
                                <div className="flex-c text-c mt-4">
                                    <Link
                                        className="brand-secondary hover fw-500"
                                        onClick={() => {
                                            sendForgetPasswordCode()
                                        }}
                                        to={`/forget-password?email=${email}`}
                                        title="Forget Password">
                                        Forget Password?
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SignIn
