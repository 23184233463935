import React from 'react'
import { Helmet } from 'react-helmet'

const title = 'Cookie Policy | Cardeon'
const url = 'https://cardeon.io/cookies'
const desc = "Welcome to Cardeon's Cookie Policy."

function Cookies() {
    return (
        <div className="cookies flex-c flex-grow-1 interior-view">
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={desc} />
                <link rel="canonical" href={url} />
                <meta property="og:url" content={url} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={desc} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={desc} />
            </Helmet>
            <div className="flex-c container">
                <div className="flex-c row">
                    <div className="flex-c col-12 pt-5">
                        <h1 className="mt-4">
                            Cookie Policy
                        </h1>
                        <p>Last Updated: 10/15/2024</p>
                        <p>Please carefully review this Cookie Policy ("Agreement") before accessing or using the Cardeon mobile application (the "Service" or the "Cardeon App"), operated by Eva, LLC ("Company," "us," "we," or "our"). This Agreement outlines the terms and conditions under which you may use our Service and establishes important legal protections for both you and the Company.</p>
                        <p>By accessing, downloading, installing, or using the Service, you ("User") agree to be legally bound by the terms of this Agreement. If you do not agree with these terms, you must immediately cease using the Service. This Agreement is a legally binding contract between you and the Company, governing your use of the Service and its related features, content, and offerings. Your continued use of the Service constitutes your ongoing acceptance of any changes or updates to this Agreement.</p>
                        <p>
                            We use cookies to help improve your experience of our
                            website at http://cardeon.io/. This cookie policy is
                            part of Eva LLC's privacy policy. It covers the use of
                            cookies between your device and our site. We also
                            provide basic information on third-party services we may
                            use, who may also use cookies as part of their service.
                            This policy does not cover their cookies.
                        </p>
                        <p>
                            If you don't wish to accept cookies from us, you
                            should instruct your browser to refuse cookies from
                            http://cardeon.io/. In such a case, we may be unable
                            to provide you with some of your desired content and
                            services.
                        </p>
                        <strong className="pt-5">What is a cookie?</strong>
                        <p>
                            A cookie is a small piece of data that a website
                            stores on your device when you visit. It typically
                            contains information about the website itself, a
                            unique identifier that allows the site to recognize
                            your web browser when you return, additional data
                            that serves the cookie's purpose, and the lifespan
                            of the cookie itself.
                        </p>
                        <p>
                            Cookies are used to enable certain features (e.g.
                            logging in), track site usage (e.g. analytics),
                            store your user settings (e.g. time zone,
                            notification preferences), and to personalize your
                            content (e.g. advertising, language).
                        </p>
                        <p>
                            Cookies set by the website you are visiting are
                            usually referred to as first-party cookies. They
                            typically only track your activity on that
                            particular site.
                        </p>
                        <p>
                            Cookies set by other sites and companies (i.e. third
                            parties) are called third-party cookies They can be
                            used to track you on other websites that use the
                            same third-party service.
                        </p>
                        <strong className="pt-5">
                            Types of cookies and how we use them
                        </strong>
                        <span className="fw-500 gray-700 mt-3">
                            Essential cookies
                        </span>
                        <p>
                            Essential cookies are crucial to your experience of
                            a website, enabling core features like user logins,
                            account management, shopping carts, and payment
                            processing.
                        </p>
                        <p>We do not use this type of cookie on our site.</p>
                        <span className="fw-500 gray-700 mt-3">
                            Performance cookies
                        </span>
                        <p>
                            Performance cookies track how you use a website
                            during your visit. Typically, this information is
                            anonymous and aggregated, with information tracked
                            across all site users. They help companies
                            understand visitor usage patterns, identify and
                            diagnose problems or errors their users may
                            encounter, and make better strategic decisions in
                            improving their audience's overall website
                            experience. These cookies may be set by the website
                            you're visiting (first-party) or by third-party
                            services. They do not collect personal information
                            about you.
                        </p>
                        <p>We use performance cookies on our site.</p>
                        <span className="fw-500 gray-700 mt-3">
                            Functionality cookies
                        </span>
                        <p>
                            Functionality cookies are used to collect
                            information about your device and any settings you
                            may configure on the website you're visiting (like
                            language and time zone settings). With this
                            information, websites can provide you with
                            customized, enhanced, or optimized content and
                            services. These cookies may be set by the website
                            you're visiting (first-party) or by third-party
                            services.
                        </p>
                        <p>We do not use this type of cookie on our site.</p>
                        <span className="fw-500 gray-700 mt-3">
                            Targeting/Advertising cookies
                        </span>
                        <p>
                            Targeting/advertising cookies help determine what
                            promotional content is most relevant and appropriate
                            to you and your interests. Websites may use them to
                            deliver targeted advertising or limit the number of
                            times you see an advertisement. This helps companies
                            improve the effectiveness of their campaigns and the
                            quality of content presented to you. These cookies
                            may be set by the website you're visiting
                            (first-party) or by third-party services.
                            Targeting/advertising cookies set by third-parties
                            may be used to track you on other websites that use
                            the same third-party service.
                        </p>
                        <p>We do not use this type of cookie on our site.</p>
                        <strong className="pt-5">Contact Us</strong>
                        <p className="my-0 mb-2">
                            For any questions or concerns regarding this cookie
                            policy, you may contact us using the following
                            details:
                        </p>
                        <a
                            href="mailto: abc@example.com"
                            title="Email Support"
                            className="brand-secondary fw-500">
                            support@cardeon.io
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cookies
