import React, { useEffect, useState } from 'react'
import { useNavigate, Link, useParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import BreadCrumb from '../../components/elements/breadCrumb'
import { setBreadCrumb } from '../../config/slice/others.slice'
import { resolveUrl } from '../../config/helpers'
import 'react-toastify/dist/ReactToastify.css'
import searchService from '../../services/search.service'
import { searchResults, setSearchQuery } from '../../config/slice/search.slice'

function MemberResults() {
    const dispatch = useDispatch()
    const { search: urlSearch } = useLocation()
    let params = new URLSearchParams(urlSearch)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [clientValue, setClient] = useState('')
    const [showClientModal, setShowClientModal] = useState(false)
    const search = useSelector(state => state.search.value)
    const { loading: userLoading, user: user } = useSelector(
        state => state.user
    )
    const subs = useSelector(state => state.subs.value)
    const { team: myTeam } = useSelector(state => state.team)

    const title = 'Search Members | Cardeon - Results'
    const url = 'https://cardeon.io/search/memberresults'
    const desc = 'Review member search results.'

    const getURLParamsObj = params => {
        const filters = {
            intent: params.get('intent'),
            ageFrom: params.get('ageFrom'),
            ageTo: params.get('ageTo'),
            distanceFrom: params.get('distanceFrom'),
            gender: params.get('gender')
        }
        return filters
    }

    console.log(search)

    useEffect(() => {
        async function runSearch() {
            dispatch(
                setBreadCrumb([
                    { name: 'My Team', path: '/my-team' },
                    {
                        name: 'Member Search',
                        path: `/search`
                    },
                    {
                        name: 'Search Results',
                        path: `/search/memberresults`
                    }
                ])
            )
            setLoading(true)
            try {
                const searchRes = await searchService.postSearchPeoplePromise(
                    getURLParamsObj(params)
                )
                dispatch(searchResults(searchRes))
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        runSearch()
    }, [])

    // const sendInvite = async () => {
    //     setLoading(true)
    //     setShowClientModal(false)
    //     try {
    //         let prettyJson = {
    //             client: clientValue.id
    //         }
    //         let res = await teamService.inviteClient(user.team.id, prettyJson)
    //         if (res) {
    //             toast.success('Invite sent.', {
    //                 position: 'top-right',
    //                 autoClose: 5000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined
    //             })
    //         }
    //         setLoading(false)
    //     } catch (e) {
    //         setLoading(false)
    //         toast.error('Error Inviting Client.', {
    //             position: 'top-right',
    //             autoClose: 5000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined
    //         })
    //     }
    // }

    async function goBack() {
        navigate('/search')
    }

    if (loading || userLoading) {
        return (
            <div className="page-content flex-c flex-grow-1 align-items-center justify-content-center bckg-white">
                <span className="gray-600 font-28">
                    <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                </span>
            </div>
        )
    } else {
        return (
            <>
                <BreadCrumb />
                <div className="page-content flex-c flex-grow-1 interior-view">
                    <Helmet>
                        <title>{title}</title>
                        <meta name="description" content={desc} />
                        <link rel="canonical" href={url} />
                        <meta property="og:url" content={url} />
                        <meta property="og:title" content={title} />
                        <meta property="og:description" content={desc} />
                        <meta name="twitter:title" content={title} />
                        <meta name="twitter:description" content={desc} />
                    </Helmet>
                    <div className="flex-c px-4 mt-5">
                        <div className="flex-r align-items-center mb-4">
                            <button
                                className="touch touch-pop mr-3"
                                onClick={goBack}
                                type="button"
                                title="Go Back">
                                <i className="fa-solid fa-arrow-left"></i>
                            </button>
                            <div className="flex-r align-items-end">
                                <h1 className="flex-r font-24 lh-20 pr-1">
                                    Search Results
                                </h1>
                                <span className="fw-300 font-16 gray-700 pl-2 lh-16">
                                    {search?.length} Results
                                </span>
                            </div>
                        </div>
                        <div className="flex-c flex-sm-r flex-wrap">
                            {search?.map((item, index) => (
                                <div
                                    type="button"
                                    key={index}
                                    className="flex-r align-items-center bckg-white widget hover-itm"
                                    onClick={e => {
                                        navigate(
                                            `/profile?id=${item.id}&frm=search`
                                        )
                                        dispatch(
                                            setSearchQuery(
                                                getURLParamsObj(params)
                                            )
                                        )
                                    }}>
                                    <div
                                        className="flex-c img-user img-user-md mr-2"
                                        style={{
                                            backgroundColor: item.gravatar
                                        }}>
                                        {item.profilemedia ? (
                                            <img
                                                src={resolveUrl(
                                                    item.profilemedia
                                                )}
                                                alt={item.initial}
                                                title="Profile Image"
                                            />
                                        ) : (
                                            <div className="flex-r align-items-center justify-content-center flex-grow-1 white fw-500">
                                                <span>{item.initial}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex-c flex-1 ml-1 fw-500">
                                        {item.name}.
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default MemberResults
