import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { modalState } from '../../config/slice/component.slice'
import { toast } from 'react-toastify'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import moment from 'moment'
import 'react-toastify/dist/ReactToastify.css'
import { getSingleBox } from '../../actions/Content/box'
import { getSinglePack, updatePack } from '../../actions/Content/pack'
import TextInput from '../../components/inputs/TextInput'
import DropDownInput from '../../components/inputs/DropDownInput'
import { getTeam } from '../../actions/team'
import { AssigneeSelectionModal } from '../../components/Modals/assigneeSelection'
import { resolveUrl, formatData } from '../../config/helpers'
import BreadCrumb from '../../components/elements/breadCrumb'
import { setBreadCrumb } from '../../config/slice/others.slice'
import { PACK_TYPE, PACK_TYPE_ARR } from '../../config/helpers/constants'

function PackEdit() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { search } = useLocation()
    let params = new URLSearchParams(search)
    let id = params.get('id')
    let boxId = params.get('boxId')
    const [loading, setLoading] = useState(false)
    const { loading: userLoading, user: user } = useSelector(
        state => state.user
    )
    const subs = useSelector(state => state.subs.value)
    const { loading: boxLoading, singleBox } = useSelector(state => state.boxes)
    const { loading: packLoading, singlePack } = useSelector(
        state => state.pack
    )
    const { team } = useSelector(state => state.team)
    const { allBoxes: boxes } = useSelector(state => state.boxes)
    const others = useSelector(state => state.others)
    const typeList = PACK_TYPE_ARR
    const [inputValue, setInput] = useState({
        type: typeList[0],
        assignees: {},
        name: '',
        category: others?.packCategoryList[0] ?? '',
        difficulty: others?.difficultyList[0] ?? '',
        lockSelfTotal: '',
        removable: true,
        dimension: others?.dimensionList[0] ?? '',
        summary: '',
        order: 0,
        lockId: null,
        lockName: null,
        price: 0
    })
    const [assigneesValue, setAssigneesValue] = useState({})
    const [showAssigneeModal, setShowAssigneeModal] = useState(false)
    const [reassignCheck, setReassign] = useState({})
    const [removeAssigneeCheck, setRemoveAssigneeCheck] = useState(false)
    const mySub = subs ? subs.subscription : null
    const subStartSec = subs ? new Date(mySub.currentPeriodStart * 1000) : null
    const subStartDate = subs ? moment(subStartSec).format('MM/DD/YYYY') : null
    const subEndSec = subs ? new Date(mySub.currentPeriodEnd * 1000) : null
    const subEndDate = subs ? moment(subEndSec).format('MM/DD/YYYY') : null
    const trialEndDate = subs
        ? moment(subStartSec).add(1, 'M').format('MM/DD/YYYY')
        : null
    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm()

    const setReassignCheck = a => {
        let res = { ...reassignCheck }
        if (res[a.id]) {
            delete res[a.id]
        } else {
            res[a.id] = true
        }
        setReassign(res)
    }

    const setAssignee = assignee => {
        if (!assignee) {
            setAssigneesValue({})
        } else if (assignee == 'all') {
            setAssigneesValue(getAllAssignees())
        } else {
            setAssigneesValue({ ...assigneesValue, [assignee.id]: assignee })
        }
    }

    const getAllAssignees = () => {
        const all = {}
        team?.members?.forEach(m => {
            all[m.id] = m
        })
        return all
    }

    const removeAssignee = assignee => {
        const newAssignees = { ...assigneesValue }
        delete newAssignees[assignee.id]
        setAssigneesValue(newAssignees)
    }

    const getAssignees = () => {
        const assigneesArr = []
        for (const key in assigneesValue) {
            assigneesArr.push(assigneesValue[key])
        }
        return assigneesArr
    }

    const AssigneeComponent = () => {
        return (
            <div className={`input-wrapper mb-4`}>
                <div className="mb-2">
                    <label>Assignee</label>
                    <span className="red ml-1">*</span>
                </div>
                {getAssignees().length > 0 ? (
                    getAssignees().length != 1 ? (
                        <div className="file-upload-done-wrapper bckg-white border-bottom-g300 border-r-6 flex-r justify-content-between align-items-center">
                            <div className="flex-r align-items-center">
                                <span className="font-16 fw-500">Multiple</span>
                                <span className="font-16 fw-300 gray-700 ml-1">
                                    ({getAssignees().length})
                                </span>
                            </div>
                            <a
                                className="brand-secondary fw-500 hover"
                                onClick={() => {
                                    setShowAssigneeModal(true)
                                }}
                                title="Change Assignee(s)">
                                Change Assignee(s)
                            </a>
                        </div>
                    ) : (
                        getAssignees().map((a, i) => (
                            <div
                                key={i}
                                className="file-upload-done-wrapper bckg-white border-bottom-g300 border-r-6 flex-r justify-content-between align-items-center">
                                <div className="flex-r align-items-center">
                                    {a?.profilemedia ? (
                                        <div className="img-file">
                                            <img
                                                src={resolveUrl(
                                                    a?.profilemedia
                                                )}
                                                alt="User profile image"
                                                title="User profile Image"
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            className="flex-c img-file"
                                            style={{
                                                backgroundColor: a?.gravatar
                                            }}>
                                            <div className="flex-r align-items-center justify-content-center flex-grow-1 white fw-500 font-20">
                                                <span>{`${a?.firstname[0]}${a?.lastname[0]}`}</span>
                                            </div>
                                        </div>
                                    )}
                                    <span className="font-20 fw-500 ml-3">{`${a?.firstname} ${a?.lastname[0]}.`}</span>
                                </div>
                                <a
                                    className="brand-secondary fw-500 hover mr-3"
                                    onClick={() => {
                                        setShowAssigneeModal(true)
                                    }}
                                    title="Change Assignee(s)">
                                    Change Assignee(s)
                                </a>
                            </div>
                        ))
                    )
                ) : (
                    <>
                        <div
                            className="form-control flex-or-r align-items-center justify-content-between hover-itm"
                            onClick={() => {
                                setShowAssigneeModal(true)
                            }}>
                            <p className="m-0">Please Select</p>
                            <i className="fa-solid fa-users-viewfinder font-20 gray-700"></i>
                        </div>
                    </>
                )}
                {showAssigneeModal && (
                    <AssigneeSelectionModal
                        showModal={setShowAssigneeModal}
                        members={team?.members}
                        setAssignee={setAssignee}
                        removeAssignee={removeAssignee}
                        assigneesValue={assigneesValue}
                    />
                )}
            </div>
        )
    }

    const handleOnChange = e => {
        setInput({
            ...inputValue,
            [e.target.name]: e.target.value
        })
    }

    const getLockedByPacks = () => {
        const filteredPack = singleBox?.packs.filter(
            p =>
                (p?.order || 0) < (inputValue.order || 0) &&
                singlePack?.id != p?.id
        )
        return [
            { value: '', label: 'None' },
            ...formatData(filteredPack, 'name', 'id')
        ]
    }

    const formatPriceList = data => {
        const list = data.map(d => {
            return {
                label: d,
                value: d
            }
        })
        return [{ value: '0', label: 'None' }, ...list]
    }

    const defineDifficulty = item => {
        let difficulty

        if (item == 1) {
            difficulty = 'Easy'
        } else if (item == 2) {
            difficulty = 'Moderate'
        } else if (item == 3) {
            difficulty = 'Difficult'
        } else if (item == 4) {
            difficulty = 'Hard'
        } else {
            difficulty = 'Extreme'
        }

        return difficulty
    }

    const getDifficultyOptions = options => {
        return options.map(i => {
            return {
                value: i,
                label: defineDifficulty(i)
            }
        })
    }

    const title = 'Content | Cardeon - My Content'
    const url = 'https://cardeon.io/content'
    const desc = 'Review and manage your client content.'

    const getExistingAssignees = (data, team) => {
        const existing = {}
        for (const key in data?.assignees) {
            const [v] = team?.members?.filter(t => t.id == key)
            existing[key] = { ...data?.assignees[key], ...v }
        }
        setAssigneesValue(existing)
    }

    const getTeamFunc = data => {
        dispatch(
            getTeam({
                callBacks: {
                    success: team => {
                        getExistingAssignees(data, team)
                    }
                }
            })
        )
    }

    useEffect(() => {
        async function init() {
            setLoading(true)
            if (Object.keys(user).length > 0) {
                if (!user.agreement) {
                    document.body.classList.add('o-flow-y-hide')
                    dispatch(modalState(true))
                }
                dispatch(getSingleBox({ boxId: boxId, callBacks: {} }))
                dispatch(
                    getSinglePack({
                        packId: id,
                        callBacks: {
                            success: data => {
                                getTeamFunc(data)
                                setInput({
                                    name: data?.name,
                                    type: data?.type,
                                    order: data?.order,
                                    lockId: data?.lockId,
                                    lockName: data?.lockName,
                                    assignees: data?.assignees,
                                    category: data?.category,
                                    difficulty: data?.difficulty,
                                    lockSelfTotal: data?.lockSelfTotal,
                                    removable: data?.removable,
                                    dimension: data?.dimension,
                                    price: data?.price,
                                    summary: data?.summary
                                })
                                setLoading(false)
                            },
                            err: () => {
                                setLoading(false)
                            }
                        }
                    })
                )
            }
        }
        init()
    }, [id, user])

    const getAssigneesToReassign = () => {
        if (inputValue.type == PACK_TYPE.ASSIGNED) {
            return getAssignees().filter(a => a.packRemoved)
        }
        return []
    }

    const prepareAssignees = () => {
        let finalAssignees = {}
        for (const key in assigneesValue) {
            finalAssignees[key] = {
                packRemoved: reassignCheck[key]
                    ? false
                    : assigneesValue[key].packRemoved
            }
        }
        return finalAssignees
    }

    const handleUpdate = () => {
        if (
            inputValue.type != PACK_TYPE.MY_TEAM &&
            Object.keys(assigneesValue).length < 1
        ) {
            toast.error('Assignee Required', { autoClose: 1000 })
            return
        }
        if (inputValue.name == '') {
            toast.error('Invalid Name', { autoClose: 1000 })
            return
        }
        if (!inputValue.lockSelfTotal) {
            toast.error('Invalid Required Cards', { autoClose: 1000 })
            return
        }
        if (!inputValue.summary) {
            toast.error('Invalid Summary', { autoClose: 1000 })
            return
        }
        let prettyJson = {
            name: inputValue.name,
            order: inputValue.order,
            category: inputValue.category,
            difficulty: parseInt(inputValue.difficulty),
            lockSelfTotal: parseInt(inputValue.lockSelfTotal),
            dimension: inputValue?.dimension.toLowerCase(),
            removable: inputValue.removable,
            summary: inputValue.summary,
            type: inputValue.type,
            ...(inputValue.lockId != null &&
            inputValue.lockId != '' &&
            inputValue.order != 0
                ? {
                      lockId: inputValue.lockId,
                      lockName:
                          singleBox?.packs?.find(
                              el => el?.id === inputValue.lockId
                          )?.name || ''
                  }
                : {}),
            price: parseInt(inputValue.price == null ? 0 : inputValue.price)
        }
        if (inputValue.type != PACK_TYPE.MY_TEAM) {
            prettyJson.assignees = prepareAssignees()
        }
        if (inputValue.type == PACK_TYPE.MY_TEAM) {
            prettyJson.removeAssignees = removeAssigneeCheck
        }
        setLoading(true)
        dispatch(
            updatePack({
                packId: id,
                payload: prettyJson,
                callBacks: {
                    success: () => {
                        setLoading(false)
                        navigate(`/content/box/${boxId}`)
                    },
                    err: () => {
                        setLoading(false)
                    }
                }
            })
        )
    }

    useEffect(() => {
        if (id && singlePack?.name) {
            const crumbsToAdd = [
                {
                    name: 'Boxes',
                    path: '/content'
                },
                {
                    name: singleBox?.name,
                    path: `/content/box/${singleBox?.id}`
                },
                {
                    name: `${singlePack?.name}:edit`,
                    path: `/content/pack/edit?id=${id}&boxId=${boxId}`
                }
            ]
            dispatch(setBreadCrumb(crumbsToAdd))
        }
    }, [id, singleBox?.name, singlePack?.name])

    async function goBack() {
        navigate(`/content/box/${boxId}`)
    }

    if (loading || packLoading || userLoading) {
        return (
            <div className="page-content flex-c flex-grow-1 align-items-center justify-content-center bckg-white">
                <span className="gray-600 font-28">
                    <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                </span>
            </div>
        )
    } else {
        return (
            <>
                <BreadCrumb />
                <div className="page-content flex-c flex-grow-1 interior-view">
                    <Helmet>
                        <title>{title}</title>
                        <meta name="description" content={desc} />
                        <link rel="canonical" href={url} />
                        <meta property="og:url" content={url} />
                        <meta property="og:title" content={title} />
                        <meta property="og:description" content={desc} />
                        <meta name="twitter:title" content={title} />
                        <meta name="twitter:description" content={desc} />
                    </Helmet>
                    <div className="flex-c container">
                        <div className="flex-c row align-items-center mt-3">
                            <div className="flex-c col-md-8 col-lg-6 mt-4">
                                <div className="flex-c flex-lg-r align-items-lg-center mt-4">
                                    <div className="flex-c flex-1">
                                        <button
                                            className="touch touch-pop"
                                            onClick={goBack}
                                            type="button"
                                            title="Cancel">
                                            <i className="fa-solid fa-arrow-left"></i>
                                        </button>
                                    </div>
                                    <h1 className="flex-3 text-lg-c mt-3 mt-lg-0">
                                        {inputValue.name || singlePack?.name}
                                    </h1>
                                    <div className="flex-1" />
                                </div>
                                <p className="text-lg-c lh-20 mt-1 mb-3">
                                    Edit the details of the selected pack.
                                </p>
                                <h3 className="mb-2 mt-4">Pack Details</h3>
                                <span className="divider mb-3" />
                                <form onSubmit={e => e.preventDefault()}>
                                    <TextInput
                                        name={'name'}
                                        label={'Name'}
                                        value={inputValue.name}
                                        placeholder={
                                            'e.g Beginners - max 25 characters'
                                        }
                                        maxLength={'25'}
                                        required={true}
                                        onChange={handleOnChange}
                                    />
                                    <DropDownInput
                                        name="type"
                                        label={'Type'}
                                        required={false}
                                        disabled={team?.members?.length < 1}
                                        value={inputValue.type}
                                        onChange={handleOnChange}
                                        options={formatData(typeList)}
                                        title={
                                            !team?.members?.length < 1
                                                ? 'Type'
                                                : 'Disabled'
                                        }
                                        marginBottom={
                                            (!!singlePack &&
                                                Object.keys(assigneesValue)
                                                    .length > 0) ||
                                            team?.members?.length < 1
                                        }
                                    />
                                    {team?.members?.length < 1 && (
                                        <span className="flex-r lh-xl font-xs gray-700 mb-4">
                                            Add members to your team in order to
                                            assign packs.
                                        </span>
                                    )}
                                    {inputValue.type != PACK_TYPE.ASSIGNED &&
                                        singlePack?.assignees &&
                                        Object.keys(singlePack?.assignees)
                                            .length > 0 && (
                                            <>
                                                <span className="flex-r align-items-center mt-3 mb-2">
                                                    <input
                                                        type="checkbox"
                                                        name="removeAssignee"
                                                        checked={
                                                            removeAssigneeCheck
                                                        }
                                                        onChange={() => {
                                                            setRemoveAssigneeCheck(
                                                                !removeAssigneeCheck
                                                            )
                                                        }}
                                                    />
                                                    <div className="flex-r align-items-center ml-2">
                                                        <span className="fw-500">
                                                            Remove Assignee(s)
                                                        </span>
                                                    </div>
                                                </span>
                                                <span className="flex-r lh-24 font-14 mb-3">
                                                    Check to remove this pack
                                                    from existing assignee's
                                                    hand. Updating this pack to
                                                    My Team alone will not
                                                    remove the pack from current
                                                    assignee's hand.
                                                </span>
                                            </>
                                        )}
                                    {inputValue.type == PACK_TYPE.ASSIGNED &&
                                        !!singlePack &&
                                        Object.keys(singlePack?.assignees)
                                            .length > 0 && (
                                            <span className="flex-r lh-24 font-14 gray-700 mb-4">
                                                If you need to update this pack
                                                to My Team and you want the
                                                current assignee to keep the
                                                pack, exclusively update the
                                                pack type to My Team.
                                            </span>
                                        )}
                                    {inputValue.type == PACK_TYPE.ASSIGNED && (
                                        <AssigneeComponent />
                                    )}
                                    {getAssigneesToReassign().map(a => (
                                        <>
                                            <span className="flex-r lh-24 font-14 red">
                                                The following Clients have
                                                removed the pack. Check all
                                                corresponding boxes below if you
                                                want to re-assign the pack to a
                                                given client. Otherwise, select
                                                a new assignee or update the
                                                type to My Team. Click Update
                                                Pack below to confirm.
                                            </span>
                                            <span className="flex-r align-items-center mt-3 mb-5">
                                                <input
                                                    type="checkbox"
                                                    name="reassign"
                                                    checked={
                                                        reassignCheck[a.id] ??
                                                        false
                                                    }
                                                    onChange={() => {
                                                        setReassignCheck(a)
                                                    }}
                                                />
                                                <div className="flex-r align-items-center ml-2">
                                                    <span className="fw-500">
                                                        Re-assign to:
                                                    </span>
                                                    <span className="pl-1">{`${a?.firstname} ${a?.lastname[0]}.`}</span>
                                                </div>
                                            </span>
                                        </>
                                    ))}

                                    <DropDownInput
                                        name={'category'}
                                        label={'Category'}
                                        value={inputValue.category}
                                        onChange={handleOnChange}
                                        options={formatData(
                                            others?.packCategoryList
                                        )}
                                    />
                                    <DropDownInput
                                        name={'difficulty'}
                                        label={'Difficulty'}
                                        value={inputValue.difficulty}
                                        onChange={handleOnChange}
                                        options={getDifficultyOptions(
                                            others?.difficultyList
                                        )}
                                    />
                                    <DropDownInput
                                        name={'order'}
                                        label={'Order'}
                                        value={inputValue.order}
                                        onChange={handleOnChange}
                                        options={formatData(
                                            others?.itemOrderList
                                        )}
                                    />
                                    {inputValue.order != 0 && (
                                        <DropDownInput
                                            name={'lockId'}
                                            label={'Locked By'}
                                            value={inputValue.lockId}
                                            onChange={handleOnChange}
                                            options={getLockedByPacks()}
                                        />
                                    )}
                                    <TextInput
                                        type={'number'}
                                        name={'lockSelfTotal'}
                                        label={'Minimum Required'}
                                        value={inputValue.lockSelfTotal}
                                        placeholder={'e.g Number: 1 - 28'}
                                        required={true}
                                        onChange={handleOnChange}
                                    />
                                    <DropDownInput
                                        name={'removable'}
                                        label={'Removable'}
                                        value={inputValue.removable}
                                        onChange={handleOnChange}
                                        options={[
                                            { value: true, label: 'Yes' },
                                            { value: false, label: 'No' }
                                        ]}
                                    />
                                    <DropDownInput
                                        name={'dimension'}
                                        label={'Dimension'}
                                        value={inputValue.dimension}
                                        onChange={handleOnChange}
                                        options={formatData(
                                            others?.dimensionList
                                        )}
                                    />
                                    {inputValue.dimension !== 'balanced' && (
                                        <DropDownInput
                                            name={'price'}
                                            label={'Price'}
                                            value={inputValue.price}
                                            onChange={handleOnChange}
                                            options={formatPriceList(
                                                others?.priceList
                                            )}
                                        />
                                    )}
                                    <TextInput
                                        name={'summary'}
                                        label={'Summary'}
                                        value={inputValue.summary}
                                        placeholder={
                                            'Describe the contents of this pack in less than 1,000 characters'
                                        }
                                        maxLength={'1000'}
                                        required={true}
                                        onChange={handleOnChange}
                                        textarea={true}
                                        lastItem={true}
                                    />
                                    <div
                                        onClick={handleUpdate}
                                        className="btn btn-secondary mb-4"
                                        title="Update Pack">
                                        Update Pack
                                    </div>
                                    <div className="text-c">
                                        <Link
                                            className="gray-700 hover fw-500"
                                            to={`/content/box/${boxId}`}
                                            title="Cancel">
                                            Cancel
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default PackEdit
