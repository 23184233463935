import { axiosInstance } from '../config/helpers/axios'

const boxService = {
    getAllBoxes: () => {
        return axiosInstance.get('/box/get-boxes')
    },
    getSingleBox: boxId => {
        return axiosInstance.get(`/box/get-boxes?boxId=${boxId}`)
    },
    update: (id, payload) => {
        return axiosInstance.put(`/box/update/${id}`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    create: payload => {
        return axiosInstance.post(`/box/create`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    getTemplates: () => {
        return axiosInstance.get('/templates')
    },
    copyBox: async payload => {
        return axiosInstance.post('/box/copy', payload)
    },
    delete: boxId => {
        return axiosInstance.put(`/box/delete/${boxId}`)
    }
}

export default boxService
