import { createSlice } from '@reduxjs/toolkit'
import { getMembersByIdPromise } from '../../actions/members'

const initialState = {
    loading: false,
    member: {}
}

const pendingCase = state => {
    state.loading = true
}

const rejectedCase = (state, action) => {
    state.loading = false
}

const memberSlice = createSlice({
    name: 'member',
    initialState,
    reducers: {
        resetMember: (state, action) => {
            state.member = {}
        }
    },
    extraReducers: builder => {
        // Get member
        builder
            .addCase(getMembersByIdPromise.pending, pendingCase)
            .addCase(getMembersByIdPromise.fulfilled, (state, action) => {
                state.loading = false
                state.member = action.payload
            })
            .addCase(getMembersByIdPromise.rejected, rejectedCase)
    }
})

export const { resetMember } = memberSlice.actions

export default memberSlice.reducer
