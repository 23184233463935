import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { modalState } from '../../config/slice/component.slice'
import { toast } from 'react-toastify'
import { useNavigate, Link, useParams } from 'react-router-dom'
import moment from 'moment'
import 'react-toastify/dist/ReactToastify.css'
import { getSinglePack } from '../../actions/Content/pack'
import CardContentCard from '../../components/cards/cardContentCard'
import { copyCard, deleteCard } from '../../actions/Content/card'
import BreadCrumb from '../../components/elements/breadCrumb'
import { setBreadCrumb } from '../../config/slice/others.slice'
import { default as DeleteConfirmModal } from '../../components/Modals/generalModal'

function PackContent() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const [loading, setLoading] = useState(false)
    const [currDel, setCurrDel] = useState(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const { loading: userLoading, user: user } = useSelector(
        state => state.user
    )
    const { loading: packLoading, singlePack } = useSelector(
        state => state.pack
    )

    const [currActive, setCurrActive] = useState(0)

    const title = 'Content | Cardeon - My Content'
    const url = 'https://cardeon.io/content'
    const desc = 'Review and manage your client content.'

    useEffect(() => {
        async function init() {
            setLoading(true)
            if (Object.keys(user).length > 0) {
                if (!user.agreement) {
                    document.body.classList.add('o-flow-y-hide')
                    dispatch(modalState(true))
                }
                dispatch(getSinglePack({ packId: params?.id }))
            }
            setLoading(false)
        }
        init()
    }, [params.id, user])

    const deleteConfirmModal = cardId => {
        setCurrDel(cardId)
        setShowDeleteModal(true)
    }

    const copyCardFunc = cardId => {
        setLoading(true)
        const payload = { cardId: cardId }
        dispatch(
            copyCard({
                payload: payload,
                callBacks: {
                    success: () => {
                        dispatch(getSinglePack({ packId: params?.id }))
                        setLoading(false)
                    },
                    err: () => {
                        setLoading(false)
                    }
                }
            })
        )
    }

    const deleteFunction = cardId => {
        setLoading(true)
        dispatch(
            deleteCard({
                cardId: cardId,
                callBacks: {
                    success: () => {
                        dispatch(getSinglePack({ packId: params?.id }))
                        setLoading(false)
                    },
                    err: () => {
                        setLoading(false)
                    }
                }
            })
        )
    }

    useEffect(() => {
        if (params?.id && singlePack?.name) {
            const crumbsToAdd = [
                {
                    name: 'Boxes',
                    path: '/content'
                },
                {
                    name: singlePack?.box,
                    path: `/content/box/${singlePack?.boxId}`
                },
                {
                    name: singlePack?.name,
                    path: `/content/pack/${params.id}`
                }
            ]
            dispatch(setBreadCrumb(crumbsToAdd))
        }
    }, [params?.id, singlePack?.name])

    async function goBack() {
        navigate(`/content/box/${singlePack?.boxId}`)
    }

    if (loading || packLoading || userLoading) {
        return (
            <div className="page-content flex-c flex-grow-1 align-items-center justify-content-center bckg-white">
                <span className="gray-600 font-28">
                    <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                </span>
            </div>
        )
    } else {
        return (
            <>
                <BreadCrumb />
                <div className="page-content flex-c flex-grow-1 interior-view">
                    <Helmet>
                        <title>{title}</title>
                        <meta name="description" content={desc} />
                        <link rel="canonical" href={url} />
                        <meta property="og:url" content={url} />
                        <meta property="og:title" content={title} />
                        <meta property="og:description" content={desc} />
                        <meta name="twitter:title" content={title} />
                        <meta name="twitter:description" content={desc} />
                    </Helmet>
                    <div className="flex-c px-4 mt-5">
                        <div className="flex-r mt-2">
                            <h1 className="flex-r flex-1 align-items-center font-24">
                                <button
                                    className="touch touch-pop mr-3"
                                    onClick={goBack}
                                    type="button"
                                    title="Go Back">
                                    <i className="fa-solid fa-arrow-left"></i>
                                </button>
                                {singlePack?.name}{' '}
                            </h1>
                        </div>
                        <div className="flex-c mt-3">
                            {singlePack?.items?.map((item, index) => (
                                <CardContentCard
                                    key={index}
                                    pack={singlePack}
                                    index={index}
                                    title={item.dimension}
                                    required={item.required}
                                    cards={item.cards}
                                    currActive={currActive}
                                    setCurrActive={setCurrActive}
                                    deleteFunction={deleteConfirmModal}
                                    copyFunction={copyCardFunc}
                                />
                            ))}
                        </div>
                        {showDeleteModal && (
                            <DeleteConfirmModal
                                showModal={setShowDeleteModal}
                                title={"Confirm delete"}
                                children={
                                    <>
                                        <p className="lh-24 mt-3 mb-4">
                                            Are you sure you want to delete this
                                            card? This action can not be undone.
                                            Would you like to proceed?
                                        </p>
                                        <Link
                                            className="btn btn-cancel mb-4"
                                            onClick={() => {
                                                deleteFunction(currDel)
                                                setShowDeleteModal(false)
                                            }}
                                            to={`/content/pack/${singlePack?.id}`}
                                            title="Delete">
                                            Delete
                                        </Link>
                                        <div className="text-c mb-3">
                                            <Link
                                                className="gray-700 hover fw-500"
                                                onClick={e => {
                                                    setShowDeleteModal(false)
                                                    e.stopPropagation()
                                                }}
                                                to={`/content/pack/${singlePack?.id}`}
                                                title="Return">
                                                Return
                                            </Link>
                                        </div>
                                    </>
                                }
                            />
                        )}
                    </div>
                </div>
            </>
        )
    }
}

export default PackContent
