import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import authService from '../services/auth.service'
import { resetUser, setCode } from '../config/slice/user.slice'
import { userSubs } from '../config/slice/subs.slice'
import { selectedPlan } from '../config/slice/selectedPlan.slice'
import { toast } from 'react-toastify'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import {
    confirmResetPasswordCode,
    sendResetPasswordCode
} from '../actions/members'
import { useEffect } from 'react'

const title = 'Verification | Cardeon'
const url = 'https://cardeon.io/forget-password'
const desc = 'Verify your Cardeon account.'

function ForgetPassword() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { search } = useLocation()
    let params = new URLSearchParams(search)
    let email = params.get('email')
    let frm = params.get('frm')
    const [inputValue, setInput] = useState('')
    const [loading, setLoading] = useState(false)
    const { loading: userLoading, user: user } = useSelector(
        state => state.user
    )
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm()

    const handleKeyDown = async event => {
        if (event.key === 'Enter') {
            verifyCode()
        }
    }

    const verifyCode = async () => {
        if (inputValue.length != 6) {
            toast.error('Invalid Code', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
            return
        }
        setLoading(true)
        const prettyJson = {
            email: email,
            code: inputValue
        }

        dispatch(
            confirmResetPasswordCode({
                payload: prettyJson,
                callBacks: {
                    success: data => {
                        dispatch(setCode(inputValue))
                        setLoading(false)
                        navigate(
                            `/reset-password?${
                                frm ? `frm=${frm}&` : ''
                            }email=${email}`
                        )
                    },
                    err: err => {
                        setLoading(false)
                        toast.error(err?.message ?? 'Invalid Code', {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        })
                    }
                }
            })
        )
    }

    const resendCode = async e => {
        e.preventDefault()
        setLoading(true)
        const prettyJson = {
            email: email
        }
        dispatch(
            sendResetPasswordCode({
                payload: prettyJson,
                callBacks: {
                    success: () => {
                        setLoading(false)
                        toast.success('Code resent', {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        })
                    },
                    err: err => {
                        setLoading(false)
                    }
                }
            })
        )
    }

    return (
        <div className="signin flex-c flex-grow-1 interior-view">
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={desc} />
                <link rel="canonical" href={url} />
                <meta property="og:url" content={url} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={desc} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={desc} />
            </Helmet>
            <div className="flex-c container">
                <div className="flex-c row align-items-center">
                    <div className="flex-c col-12 col-sm-8 col-md-6 col-lg-10 pt-5">
                        <div className="flex-c flex-lg-r align-items-lg-center mt-4">
                            <div className="flex-c flex-1">
                                <Link
                                    className="flex-r align-items-lg-center"
                                    to={frm ? `/${frm}` : '/signin'}>
                                    <button
                                        className="touch touch-pop"
                                        type="button"
                                        title="Cancel">
                                        <i className="fa-solid fa-arrow-left"></i>
                                    </button>
                                </Link>
                            </div>
                            <h1 className="flex-3 text-lg-c mt-3 mt-lg-0">
                                Enter Code
                            </h1>
                            <div className="flex-1" />
                        </div>
                        <p className="text-lg-c lh-20 mt-1 mb-3">
                            A one time passcode was sent to the email address
                            associated with your account. <br />
                            Please enter the code to verify your account.
                        </p>
                        <p className="lh-20 mt-1 align-self-center">
                            Didn't receive a code?{' '}
                            <a
                                className="brand-secondary hover fw-500"
                                style={{ cursor: 'pointer' }}
                                onClick={e => {
                                    resendCode(e)
                                }}>
                                Resend Code
                            </a>
                        </p>
                    </div>
                </div>
                <div className="flex-c row align-items-center">
                    <div className="flex-c col-sm-8 col-md-6 col-lg-4 mt-4">
                        <form
                            onSubmit={handleSubmit(verifyCode)}
                            className="flex-c"
                            onKeyDown={handleKeyDown}>
                            <div className="input-wrapper">
                                <label className="pl-1" aria-describedby="Code">
                                    One Time Passcode
                                </label>
                                <input
                                    type="text"
                                    {...register('code', {
                                        required: true
                                    })}
                                    className="form-control mt-2"
                                    id="code"
                                    value={inputValue}
                                    onChange={e => setInput(e.target.value)}
                                    placeholder="Code"
                                    required
                                />
                                <div className="input-desc red">
                                    {errors.code && 'Invalid code'}
                                </div>
                            </div>
                            <button
                                onClick={handleSubmit(verifyCode)}
                                className="btn btn-secondary mt-2"
                                value="Continue">
                                Continue
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgetPassword
