import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { TermsModal } from '../components/Modals/terms'
import { modalState } from '../config/slice/component.slice'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import {
    getAllBoxes,
    copyBox,
    deleteBox,
    updateBox
} from '../actions/Content/box'
import { default as DeleteConfirmModal } from '../components/Modals/generalModal'
import BoxPackCard from '../components/cards/boxPackCard'
import TeamCode from '../components/elements/teamCode'

function Content() {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [currDel, setCurrDel] = useState(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [searchVal, setSearchVal] = useState('')
    const [filteredBoxes, setFilteredBoxes] = useState([])
    const modal = useSelector(state => state.modal.value)
    const [activeMenuIndex, setActiveMenuIndex] = useState(-1)
    const { loading: userLoading, user: user } = useSelector(
        state => state.user
    )
    const { loading: boxLoading, allBoxes: boxes } = useSelector(
        state => state.boxes
    )
    const subs = useSelector(state => state.subs.value)

    const title = 'Content | Cardeon - Content'
    const url = 'https://cardeon.io/content'
    const desc = 'Review and manage your client content.'
    useEffect(() => {
        async function init() {
            setLoading(true)
            if (Object.keys(user).length > 0) {
                if (user?.agreement != undefined && user?.agreement === false) {
                    document.body.classList.add('o-flow-y-hide')
                    dispatch(modalState(true))
                }
                dispatch(getAllBoxes())
            }
            setLoading(false)
        }
        init()
    }, [user])

    useEffect(() => {
        if (searchVal == '') {
            setFilteredBoxes(boxes)
        }
        if (boxes && boxes?.length) {
            const splittedSearch = searchVal.toLowerCase().split(',')
            const filtered = boxes?.filter(b => {
                const boxWords = b.name.toLowerCase().split(' ')
                return boxWords.some(v => {
                    return (
                        splittedSearch.some(s => v.startsWith(s)) ||
                        v.startsWith(searchVal.toLowerCase())
                    )
                })
            })
            setFilteredBoxes(filtered)
        }
    }, [searchVal])

    useEffect(() => {
        setFilteredBoxes(boxes)
    }, [boxes])

    const searchHandler = e => {
        setSearchVal(e.target.value)
    }

    const copyBoxFunc = boxId => {
        setLoading(true)
        const payload = { boxId: boxId }
        dispatch(
            copyBox({
                payload: payload,
                callBacks: {
                    success: () => {
                        setLoading(false)
                    },
                    err: () => {
                        setLoading(false)
                    }
                }
            })
        )
    }

    const deleteBoxFunc = boxId => {
        setLoading(true)
        setShowDeleteModal(false)
        dispatch(
            deleteBox({
                boxId: boxId,
                callBacks: {
                    success: () => {
                        setLoading(false)
                    },
                    err: () => {
                        setLoading(false)
                    }
                }
            })
        )
    }

    const deleteConfirmModal = boxId => {
        setCurrDel(boxId)
        setShowDeleteModal(true)
    }

    const activateBoxFunc = boxId => {
        setLoading(true)
        dispatch(
            updateBox({
                boxId: boxId,
                payload: { active: true },
                callBacks: {
                    success: () => {
                        toast.success('Box activated', {
                            autoClose: 2000,
                            closeOnClick: true
                        })
                        setLoading(false)
                    },
                    err: err => {
                        toast.error(err.message, {
                            autoClose: 2000,
                            closeOnClick: true
                        })
                        setLoading(false)
                    }
                }
            })
        )
    }

    const deactivateBoxFunc = boxId => {
        setLoading(true)
        dispatch(
            updateBox({
                boxId: boxId,
                payload: { active: false },
                callBacks: {
                    success: () => {
                        toast.success('Box deactivated', {
                            autoClose: 2000,
                            closeOnClick: true
                        })
                        setLoading(false)
                    },
                    err: err => {
                        toast.error(err.message, {
                            autoClose: 2000,
                            closeOnClick: true
                        })
                        setLoading(false)
                    }
                }
            })
        )
    }

    const menuStateFunc = index => {
        setActiveMenuIndex(index)
    }

    const belongTeamPromoState = member => {
        if (!member.appliedTeamPromo) {
            return false
        }
        return member.appliedTeamPromoState
    }

    const showTeamCode = (subs, user) => {
        if (subs && subs?.subscription?.status) {
            return false
        }
        if (belongTeamPromoState(user)) {
            return false
        }
        return true
    }

    if (loading || userLoading || boxLoading) {
        return (
            <div className="page-content flex-c flex-grow-1 align-items-center justify-content-center bckg-white">
                <span className="gray-600 font-28">
                    <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                </span>
            </div>
        )
    }

    if (showTeamCode(subs, user)) {
        return (
            <div className="page-content flex-c flex-grow-1 align-items-center interior-view">
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={desc} />
                    <link rel="canonical" href={url} />
                    <meta property="og:url" content={url} />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={desc} />
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={desc} />
                </Helmet>
                <div className="flex-c align-items-center mt-4 mx-2">
                    <h1 className="flex-r font-24 lh-20 pr-1">
                        No Subscription
                    </h1>
                    <p>
                        You don't have an active trial or subscription.
                        Subscribe now or enter promo code below
                    </p>
                    <div className="align-self-center">
                        <TeamCode />
                    </div>
                    <Link
                        type="button"
                        className="fw-700 ml-sm-3"
                        title="View Plans"
                        to="/pricing">
                        View Plans
                    </Link>
                </div>
            </div>
        )
    }
    return (
        <>
            <div className="page-content flex-c flex-grow-1 interior-view">
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={desc} />
                    <link rel="canonical" href={url} />
                    <meta property="og:url" content={url} />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={desc} />
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={desc} />
                </Helmet>
                <div className="flex-c px-4 mt-3">
                    <div className="flex-r align-items-end mb-1">
                        <h1 className="flex-r font-24 lh-20 pr-1">Boxes</h1>
                        <span className="fw-300 font-16 gray-700 pl-2 lh-20">
                            ({boxes?.length > 0 ? boxes?.length : '0'})
                        </span>
                    </div>
                    <div className="flex-r my-4">
                        <div className="input-wrapper flex-1 mb-0">
                            <input
                                onChange={searchHandler}
                                type="text"
                                className="form-control"
                                id="search"
                                name="Search"
                                value={searchVal}
                                placeholder="Search"
                                required
                            />
                        </div>
                        <Link
                            className="btn btn-primary-alt flex-0 ml-3"
                            to={'/content/box/create'}
                            title="New Box">
                            <span className="display-hide display-sm-flex">
                                New Box
                            </span>
                            <i className="display-sm-hide fa-solid fa-plus"></i>
                        </Link>
                    </div>
                    <div className="flex-c">
                        {filteredBoxes?.length > 0 ? (
                            filteredBoxes.map((box, index) => (
                                <BoxPackCard
                                    key={index}
                                    data={box}
                                    type={'box'}
                                    index={index}
                                    menuStateFunc={menuStateFunc}
                                    menuState={index == activeMenuIndex}
                                    actionPaths={{
                                        view: `/content/box/${box.id}`,
                                        edit: `/content/box/edit?id=${box.id}`
                                    }}
                                    status={box.active}
                                    statusFunc={
                                        box.active
                                            ? deactivateBoxFunc
                                            : activateBoxFunc
                                    }
                                    copyFunction={copyBoxFunc}
                                    deleteFunction={deleteConfirmModal}
                                    style={`border-g300 ${
                                        filteredBoxes.length - 1 === index
                                            ? 'border-bottom-g300'
                                            : 'border-bottom-reset'
                                    } ${index === 0 ? 'border-r-6-top' : ''} ${
                                        filteredBoxes.length - 1 === index
                                            ? 'border-r-6-bottom'
                                            : ''
                                    }`}
                                />
                            ))
                        ) : (
                            <h2>No boxes found.</h2>
                        )}
                        {modal === true && <TermsModal />}
                        {showDeleteModal && (
                            <DeleteConfirmModal
                                showModal={setShowDeleteModal}
                                title={"Confirm delete"}
                                children={
                                    <>
                                        <p className="lh-24 mt-3 mb-4">
                                            Are you sure you want to delete this
                                            box? Deleting this box will also
                                            delete all of its packs and cards.
                                            This action can not be undone. Would
                                            you like to proceed?
                                        </p>
                                        <button
                                            className="btn btn-cancel mb-4"
                                            onClick={() => {
                                                deleteBoxFunc(currDel)
                                                setShowDeleteModal(false)
                                            }}>
                                            Delete
                                        </button>
                                        <div className="text-c mb-3">
                                            <a
                                                className="gray-700 hover fw-500"
                                                onClick={e => {
                                                    setShowDeleteModal(false)
                                                    e.stopPropagation()
                                                }}>
                                                Return
                                            </a>
                                        </div>
                                    </>
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Content
