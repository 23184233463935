import React from 'react'
import { Helmet } from 'react-helmet'
import cw from '../img/Team/CW-min.jpeg'
import df from '../img/Team/DF-min.jpeg'
import jo from '../img/Team/JO-min.png'
import ab from '../img/Team/AB-min.jpeg'
import lw from '../img/Team/LW-min.png'
import zl from '../img/Team/ZL-min.jpeg'
import kt from '../img/Team/KT-min.jpeg'
import sp from '../img/Team/SP-min.png'
import lo from '../img/Team/LO-min.jpeg'
import ta from '../img/Team/TA-min.jpg'

const title = 'Team | Cardeon - Our Team'
const url = 'https://cardeon.io/team'
const desc =
    'Learn about the Cardeon Team.'

function Team() {
    return (
        <div className="about flex-c flex-grow-1 interior-view">
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={desc} />
                <link rel="canonical" href={url} />
                <meta property="og:url" content={url} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={desc} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={desc} />
            </Helmet>
            <div className="flex-c container">
                <div className="flex-c row">
                    <div className="flex-c col-12 pt-lg-5">
                        <h1 className="mt-4">Our Team</h1>
                    </div>
                </div>
                <div className="flex-r row">
                    <div className="flex-c align-items-center col-md-6 col-lg-4 col-xl-3 my-5">
                        <div className="flex-c img-team">
                            <img
                                className="img border-medium-wh shadow border-r-100 p-0 mb-4"
                                src={cw}
                                title="Cody Wingert"
                                alt="Cody Wingert"
                            />
                        </div>
                        <p className="fw-500 font-20 mt-3 mb-2">Cody Wingert</p>
                        <p className="font-16 lh-20 m-0">Chief Executive Officer</p>
                        <p className="font-14 italic fw-500 lh-20 m-0 mt-1">Founder</p>
                    </div>
                    <div className="flex-c align-items-center col-md-6 col-lg-4 col-xl-3 my-5">
                        <div className="flex-c img-team">
                            <img
                                className="img border-medium-wh shadow border-r-100 p-0 mb-4"
                                src={df}
                                title="Dr. Daniel Franz"
                                alt="Dr. Daniel Franz"
                            />
                        </div>
                        <p className="fw-500 font-20 mt-3 mb-2">Dr. Daniel Franz</p>
                        <p className="font-16 lh-20 m-0">Chief Clinical Officer</p>
                        <p className="font-14 italic fw-500 lh-20 m-0 mt-1">Logotherapy</p>
                    </div>
                    <div className="flex-c align-items-center col-md-6 col-lg-4 col-xl-3 my-5">
                        <div className="flex-c img-team">
                            <img
                                className="img border-medium-wh shadow border-r-100 p-0 mb-4"
                                src={jo}
                                title="Jonathan Olaifa"
                                alt="Jonathan Olaifa"
                            />
                        </div>
                        <p className="fw-500 font-20 mt-3 mb-2">Jonathan Olaifa</p>
                        <p className="font-16 lh-20 m-0">Technology Manager</p>
                        <p className="font-14 italic fw-500 lh-20 m-0 mt-1">Computer Science</p>
                    </div>
                    <div className="flex-c align-items-center col-md-6 col-lg-4 col-xl-3 my-5">
                        <div className="flex-c img-team">
                            <img
                                className="img border-medium-wh shadow border-r-100 p-0 mb-4"
                                src={ab}
                                title="Dr. Anders Beier"
                                alt="Dr. Anders Beier"
                            />
                        </div>
                        <p className="fw-500 font-20 mt-3 mb-2">Dr. Anders Beier</p>
                        <p className="font-16 lh-20 m-0">Chief Advisor</p>
                        <p className="font-14 italic fw-500 lh-20 m-0 mt-1">Psychotherapy</p>
                    </div>
                    <div className="flex-c align-items-center col-md-6 col-lg-4 col-xl-3 my-5">
                        <div className="flex-c img-team">
                            <img
                                className="img border-medium-wh shadow border-r-100 p-0 mb-4"
                                src={kt}
                                title="Dr. Kendra Thornton"
                                alt="Dr. Kendra Thornton"
                            />
                        </div>
                        <p className="fw-500 font-20 mt-3 mb-2">Dr. Kendra Thornton</p>
                        <p className="font-16 lh-20 m-0">Advisor</p>
                        <p className="font-14 italic fw-500 lh-20 m-0 mt-1">Neuroeducation</p>
                    </div>
                    <div className="flex-c align-items-center col-md-6 col-lg-4 col-xl-3 my-5">
                        <div className="flex-c img-team">
                            <img
                                className="img border-medium-wh shadow border-r-100 p-0 mb-4"
                                src={lo}
                                title="Lindsey O'connell"
                                alt="Lindsey O'connell"
                            />
                        </div>
                        <p className="fw-500 font-20 mt-3 mb-2">Lindsey O'connell</p>
                        <p className="font-16 lh-20 m-0">Advisor</p>
                        <p className="font-14 italic fw-500 lh-20 m-0 mt-1">Psychotherapy</p>
                    </div>
                    <div className="flex-c align-items-center col-md-6 col-lg-4 col-xl-3 my-5">
                        <div className="flex-c img-team">
                            <img
                                className="img border-medium-wh shadow border-r-100 p-0 mb-4"
                                src={lw}
                                title="Lindsey Wheeler"
                                alt="Lindsey Wheeler"
                            />
                        </div>
                        <p className="fw-500 font-20 mt-3 mb-2">Lindsey Wheeler</p>
                        <p className="font-16 lh-20 m-0">Chief Advisor</p>
                        <p className="font-14 italic fw-500 lh-20 m-0 mt-1">Psychotherapy</p>
                    </div>
                    <div className="flex-c align-items-center col-md-6 col-lg-4 col-xl-3 my-5">
                        <div className="flex-c img-team">
                            <img
                                className="img border-medium-wh shadow border-r-100 p-0 mb-4"
                                src={sp}
                                title="Shana Pack"
                                alt="Shana Pack"
                            />
                        </div>
                        <p className="fw-500 font-20 mt-3 mb-2">Shana Pack</p>
                        <p className="font-16 lh-20 m-0">Advisor</p>
                        <p className="font-14 italic fw-500 lh-20 m-0 mt-1">Health & Benefits</p>
                    </div>
                    <div className="flex-c align-items-center col-md-6 col-lg-4 col-xl-3 my-5">
                    <div className="flex-c img-team">
                            <img
                                className="img border-medium-wh shadow border-r-100 p-0 mb-4"
                                src={ta}
                                title="Dr. Troy Akins"
                                alt="Dr. Troy Akins"
                            />
                        </div>
                        <p className="fw-500 font-20 mt-3 mb-2">Dr. Troy Akins</p>
                        <p className="font-16 lh-20 m-0">Advisor</p>
                        <p className="font-14 italic fw-500 lh-20 m-0 mt-1">Clinical Psychology</p>
                    </div>
                    <div className="flex-c align-items-center col-md-6 col-lg-4 col-xl-3 my-5">
                        <div className="flex-c img-team">
                            <img
                                className="img border-medium-wh shadow border-r-100 p-0 mb-4"
                                src={zl}
                                title="Dr. Zach Levin"
                                alt="Dr. Zach Levin"
                            />
                        </div>
                        <p className="fw-500 font-20 mt-3 mb-2">Dr. Zach Levin</p>
                        <p className="font-16 lh-20 m-0">Chief Advisor</p>
                        <p className="font-14 italic fw-500 lh-20 m-0 mt-1">Neuropsychology</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Team
