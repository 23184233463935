import { createSlice } from '@reduxjs/toolkit'

const searchSlice = createSlice({
    name: 'search',
    initialState: {
        value: [],
        query: {}
    },
    reducers: {
        searchResults: (state, action) => {
            state.value = action.payload
        },
        setSearchQuery: (state, action) => {
            state.query = action.payload
        }
    }
})

export const { searchResults, setSearchQuery } = searchSlice.actions

export default searchSlice.reducer
