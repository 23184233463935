import React from 'react'

function DropDownInput({
    label,
    name,
    value,
    placeholder,
    required,
    onChange,
    options,
    lastItem,
    marginBottom,
    disabled,
    title
}) {
    return (
        <div
            className={`input-wrapper ${!marginBottom ? 'mb-4' : 'mb-2'} ${
                lastItem ? 'mb-5' : ''
            }`}>
            <div className="mb-2">
                <label htmlFor={name}>
                    {label}
                    {required ? <span className="red ml-1">*</span> : ''}
                </label>
            </div>
            <select
                className="form-control"
                name={name}
                title={title || label}
                onChange={onChange}
                placeholder={placeholder ?? null}
                value={value}
                disabled={disabled}
                required={required ?? false}>
                {options?.length > 0 &&
                    options.map(option => (
                        <option key={option?.value} value={option?.value}>
                            {option?.label}
                        </option>
                    ))}
            </select>
        </div>
    )
}

export default DropDownInput
