import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const title = 'Native App | Cardeon'
const url = 'https://cardeon.io/maintenance'
const desc = "Our cutting-edge features are available exclusively in the native app."

function Maintenance() {
    return (
        <div className="404 flex-c flex-grow-1 interior-view">
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={desc} />
                <link rel="canonical" href={url} />
                <meta property="og:url" content={url} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={desc} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={desc} />
            </Helmet>
            <div className="flex-c container">
                <div className="flex-c row">
                    <div className="flex-c col-12 align-items-center text-c">
                        <h1>Experience the Full Potential</h1>
                        <p className="lh-20 mt-4 mb-2">Our cutting-edge features are available exclusively in the native app.</p>
                        <p className="lh-20 mt-0 mb-1">Download now for the best experience in mental health and wellness.</p>
                    </div>
                </div>
                <div className="flex-c row align-items-center mt-3">
                    <div className="flex-c col-12 col-md-7 col-lg-5">
                        <div className="flex-c flex-sm-r flex-auto mb-md-5 mt-4">
                            <Link
                                type="link"
                                className="btn bckg-g900 white mb-3 mb-sm-0 mr-sm-2"
                                to="https://apps.apple.com/us/app/cardeon/id1353714513"
                                title="Download on the App Store"
                                target="_blank"
                                rel="noopener noreferrer">
                                <i className="fa-brands fa-apple pr-2"></i>
                                App Store
                            </Link>
                            <Link
                                type="link"
                                className="btn bckg-g900 white ml-sm-2"
                                to="https://play.google.com/store/apps/details?id=com.eva.support"
                                title="Download on Google Play"
                                target="_blank"
                                rel="noopener noreferrer">
                                <i className="fa-brands fa-android pr-2"></i>
                                Google Play
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Maintenance
