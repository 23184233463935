import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
    getSinglePack,
    updatePack,
    createPack,
    createPackFromTemplate,
    copyPack,
    deletePack
} from '../../actions/Content/pack'

const initialState = {
    loading: false,
    singlePack: {}
}

const pendingCase = state => {
    state.loading = true
}

const rejectedCase = (state, action) => {
    state.loading = false
}

const packSlice = createSlice({
    name: 'pack',
    initialState,
    reducers: {},
    extraReducers: builder => {
        // Get single pack
        builder
            .addCase(getSinglePack.pending, pendingCase)
            .addCase(getSinglePack.fulfilled, (state, action) => {
                state.loading = false
                state.singlePack = action.payload
            })
            .addCase(getSinglePack.rejected, rejectedCase)

        // Update Pack
        builder
            .addCase(updatePack.pending, pendingCase)
            .addCase(updatePack.fulfilled, (state, action) => {
                state.loading = false
                state.singlePack = action.payload
            })
            .addCase(updatePack.rejected, rejectedCase)

        // Create Pack
        builder
            .addCase(createPack.pending, pendingCase)
            .addCase(createPack.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(createPack.rejected, rejectedCase)

        // Create Pack From Template
        builder
            .addCase(createPackFromTemplate.pending, pendingCase)
            .addCase(createPackFromTemplate.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(createPackFromTemplate.rejected, rejectedCase)

        // Copy pack
        builder
            .addCase(copyPack.pending, pendingCase)
            .addCase(copyPack.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(copyPack.rejected, rejectedCase)

        // Delete pack
        builder
            .addCase(deletePack.pending, pendingCase)
            .addCase(deletePack.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(deletePack.rejected, rejectedCase)
    }
})

export default packSlice.reducer
