import { axiosInstance } from '../config/helpers/axios'

const mediaService = {
    uploadProfileMedia: params => {
        const form = new FormData()
        form.append('name', 'image')
        form.append('file', params)
        return axiosInstance.post(`/usr/src/app/media/profile`, form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    deleteProfileMedia: params => {
        return axiosInstance.put(`/usr/src/app/media/delete`, params)
    }
}

export default mediaService
