import { createAsyncThunk } from '@reduxjs/toolkit'
import cardService from '../../services/card.service'

export const getSingleCard = createAsyncThunk(
    'card/getSingleCard',
    async (options, { rejectWithValue }) => {
        try {
            const data = await cardService.getSingleCard(options.cardId)
            options?.callBacks?.success?.(data)
            return data
        } catch (error) {
            options?.callBacks?.err?.()
            return rejectWithValue('An error occured')
        }
    }
)

export const updateCard = createAsyncThunk(
    'card/updateCard',
    async (options, { rejectWithValue }) => {
        try {
            const data = await cardService.update(
                options.cardId,
                options.payload
            )
            options?.callBacks?.success?.(data)
            return data
        } catch (error) {
            options?.callBacks?.err?.(error)
            return rejectWithValue(error)
        }
    }
)

export const createCard = createAsyncThunk(
    'card/createCard',
    async (options, { rejectWithValue }) => {
        try {
            const data = await cardService.create(options.payload)
            options?.callBacks?.success?.(data)
            return data
        } catch (error) {
            options?.callBacks?.err?.(error)
            return rejectWithValue(error)
        }
    }
)

export const copyCard = createAsyncThunk(
    'card/copyCard',
    async (options, { rejectWithValue }) => {
        try {
            const data = await cardService.copyCard(options.payload)
            options?.callBacks?.success?.(data)
            return data
        } catch (error) {
            options?.callBacks?.err?.()
            return rejectWithValue(error)
        }
    }
)

export const deleteCard = createAsyncThunk(
    'card/deleteCard',
    async (options, { rejectWithValue }) => {
        try {
            const data = await cardService.delete(options.cardId)
            options?.callBacks?.success?.(data)
            return data
        } catch (error) {
            options?.callBacks?.err?.()
            return rejectWithValue(error)
        }
    }
)
