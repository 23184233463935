import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { userContact } from '../config/slice/auth.slice'
import { selectedPlan } from '../config/slice/selectedPlan.slice'
import { toast } from 'react-toastify'
import { useNavigate, Link } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'

const title = 'Sign In | Cardeon'
const url = 'https://cardeon.io/signin'
const desc = 'Sign In to your Cardeon account.'

function SignIn() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [inputValue, setInput] = useState('')
    const regEmail = /^\w+([\+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm()

    const handleKeyDown = async event => {
        if (event.key === 'Enter') {
            onLogin()
        }
    }

    const onLogin = async () => {
        dispatch(selectedPlan(null))
        if (inputValue.length > 0) {
            if (regEmail.test(inputValue)) {
                reset()
                navigate(`/password-entry?email=${inputValue}`)
            } else {
                toast.warn('Invalid Email address.', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'dark'
                })
                return
            }
        }
    }

    return (
        <div className="signin flex-c flex-grow-1 interior-view">
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={desc} />
                <link rel="canonical" href={url} />
                <meta property="og:url" content={url} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={desc} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={desc} />
            </Helmet>
            <div className="flex-c container">
                <div className="flex-c row">
                    <div className="flex-c col-12">
                        <div className="flex-c align-items-center">
                            <div className="flex-c text-c">
                                <h1>Sign In to Cardeon</h1>
                                <p className="lh-20 mt-1">
                                    Don't have an account?{' '}
                                    <Link
                                        className="brand-secondary hover fw-500"
                                        // to="/disclaimer"
                                        to="/maintenance"
                                        title="Sign Up">
                                        Sign Up
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-c align-items-center row">
                    <div className="flex-c col-sm-8 col-md-6 col-lg-4 mt-4">
                        <form
                            onSubmit={handleSubmit(onLogin)}
                            className="flex-c"
                            onKeyDown={handleKeyDown}>
                            <div className="input-wrapper">
                                <label
                                    className="pl-1"
                                    aria-describedby="Email">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    {...register('email', {
                                        required: true
                                    })}
                                    className="form-control mt-2"
                                    id="email"
                                    value={inputValue}
                                    onChange={e =>
                                        setInput(e.target.value)
                                    }
                                    placeholder="example@email.com"
                                    required
                                />
                                <div className="input-desc red">
                                    {errors.email &&
                                        'Please provide a valid email.'}
                                </div>
                            </div>
                            <Link
                                onClick={handleSubmit(onLogin)}
                                className="btn btn-secondary mt-2"
                                title="Continue">
                                Continue
                            </Link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignIn
