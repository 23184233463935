import React from 'react'
import { Helmet } from 'react-helmet'

const title = 'About | Cardeon - Our Story'
const url = 'https://cardeon.io/about'
const desc =
    'Learn about Cardeon and our dedication to providing wellness coaching technology solutions.'

function About() {
    return (
        <div className="about flex-c flex-grow-1 interior-view">
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={desc} />
                <link rel="canonical" href={url} />
                <meta property="og:url" content={url} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={desc} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={desc} />
            </Helmet>
            <div className="flex-c container">
                <div className="flex-c row">
                    <div className="flex-c col-12 pt-lg-5">
                        <h1 className="mt-4">About Cardeon</h1>
                        <strong className="pt-5">Mission Statement</strong>
                        <p>To empower individuals in their journey towards mental well-being by providing innovative digital products designed to proactively address and manage the challenges of everyday life. Through accessible and user-friendly tools, we aim to equip people with the resources they need to foster resilience, develop coping strategies, and promote mental health awareness. Our commitment lies in promoting early intervention and prevention, fostering a culture where mental well-being is prioritized and supported. Together, we strive to create a world where everyone can navigate life's struggles with strength, resilience, and faith.</p>
                        <strong className="pt-4 pt-lg-5">
                            What is Cardeon?
                        </strong>
                        <p>
                            Cardeon is a technology solution supporting mental
                            wellness. It is also entirely driven by feedback
                            from the very people that use it; which is why we
                            always encourage accountability; let us know how
                            we're doing. We'll do our best to fix and improve
                            any and all parts of the app as quick as possible
                            while ensuring the integrity and security of the app
                            and the people that use it.
                        </p>
                        <strong className="pt-5">
                            Where did it all start?
                        </strong>
                        <p>
                            The idea for Cardeon was initially conceived in the
                            summer of 2016. It took months of iteration on
                            design, layout and features, busines strategy; as
                            well as endless hours of communication from our
                            potential user base until we finally settled on a
                            forming Eva, LLC. A year later, in the summer of
                            2017, the build process for Eva, LLC was officially
                            underway. In November of 2017, Eva, LLC became an
                            officially registered LLC in the state of North
                            Carolina! In the fall of 2019 Eva, LLC was
                            officially registered doing business as,
                            CancerFlight. After some much needed re-evaluation,
                            CancerFlight became Cardeon in the spring of 2022.
                            Today, Cardeon is ready for download in both the
                            Apple and Google Play app stores.
                        </p>
                        <strong className="pt-5">The Difference</strong>
                        <p>
                            Cardeon was created in the spare time of a dedicated
                            individual who believes that even in today's world,
                            technology not only has the ability to make ones
                            life easier, but technology can truly make your life
                            better. Our goal is to create and grow technology
                            solutions to improve and optimize mental health and wellness.
                            We currently have no investors, board of
                            directors or fancy budget. Cardeon was bootstrapped & crafted
                            from the minds of a select few dedicated and passionate
                            individuals who want to make a difference in the world.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
