import { axiosInstance } from '../config/helpers/axios'

const cardService = {
    getSingleCard: cardId => {
        return axiosInstance.get(`/cards/${cardId}`)
    },
    update: (cardId, payload) => {
        return axiosInstance.put(`/cards/update/${cardId}`, payload)
    },
    create: payload => {
        return axiosInstance.post(`/cards/create`, payload)
    },
    copyCard: async payload => {
        return axiosInstance.post('/cards/copy', payload)
    },
    delete: cardId => {
        return axiosInstance.put(`/cards/delete/${cardId}`)
    }
}

export default cardService
