import React from 'react'

function TextInput({
    textarea,
    label,
    name,
    value,
    placeholder,
    required,
    maxLength,
    type,
    onChange,
    lastItem,
    title,
    style,
    min,
    max,
    disabled
}) {
    return (
        <div
            className={`input-wrapper ${lastItem ? 'mb-5' : ''} ${
                style ? style : ''
            }`}>
            <div className="mb-2">
                <label htmlFor={name}>
                    {label}
                    {required ? <span className="red ml-1">*</span> : ''}
                </label>
            </div>
            {textarea ? (
                <textarea
                    type="text"
                    className={`form-control ${disabled ? 'disabled' : ''}`}
                    rows={6}
                    style={{ resize: 'none' }}
                    name={name}
                    title={title || label}
                    value={value}
                    maxLength={maxLength}
                    onChange={onChange}
                    placeholder={placeholder}
                    required={required ?? false}
                    disabled={disabled ?? false}
                />
            ) : (
                <input
                    type={type ? type : 'text'}
                    className={`form-control ${disabled ? 'disabled' : ''}`}
                    name={name}
                    title={title || label}
                    value={value}
                    maxLength={maxLength}
                    onChange={onChange}
                    placeholder={placeholder}
                    required={required ?? false}
                    min={min}
                    max={max}
                    disabled={disabled ?? false}
                />
            )}
        </div>
    )
}

export default TextInput
