import React from 'react'
import { resolveUrl } from '../../config/helpers'

export function AssigneeSelectionModal({
    showModal,
    members,
    setAssignee,
    removeAssignee,
    assigneesValue
}) {
    const getLengthText = () => {
        if (Object.keys(assigneesValue).length == 0) {
            return '(None)'
        }
        if (Object.keys(assigneesValue).length == members?.length) {
            return '(All)'
        }
        return ''
    }
    return (
        <div
            className="modal-background"
            role="dialog"
            onClick={() => {
                showModal(false)
            }}>
            <div
                className="col-sm-8 col-md-6 col-lg-4"
                role="document"
                onClick={e => e.stopPropagation()}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Select Assignee(s)</h5>
                        <i
                            className="fa-solid fa-xmark gray-800"
                            onClick={() => showModal(false)}
                            title="Close Modal"></i>
                    </div>
                    <div className="modal-body mb-4 mt-2">
                        <div className="flex-r justify-content-between file-upload-done-wrapper bckg-white file-upload-first">
                            <div className="flex-r align-items-center">
                                <span className="fw-500 mr-1">Selected:</span>
                                <span className="fw-400 gray-700">
                                    ({Object.keys(assigneesValue).length})
                                </span>
                                <span className="fw-400 gray-700 ml-1">
                                    {getLengthText()}
                                </span>
                            </div>
                            {getLengthText() == '(All)' ? (
                                <span
                                    className="brand-secondary fw-500"
                                    onClick={() => {
                                        setAssignee(null)
                                    }}
                                    style={{ cursor: 'pointer' }}>
                                    De-select All
                                </span>
                            ) : (
                                <span
                                    className="brand-secondary fw-500"
                                    onClick={() => {
                                        setAssignee('all')
                                    }}
                                    style={{ cursor: 'pointer' }}>
                                    Select All
                                </span>
                            )}
                        </div>
                        {members?.map((m, i) => (
                            <div
                                onClick={() => {
                                    if (assigneesValue[m.id]) {
                                        removeAssignee(m)
                                    } else {
                                        setAssignee(m)
                                    }
                                }}
                                key={i}
                                className={`flex-r file-upload-done-wrapper bckg-white justify-content-between align-items-center ${
                                    members.length - 1 === i
                                        ? 'file-upload-last'
                                        : ''
                                } hover-itm`}>
                                <div
                                    className="flex-r align-items-center justify-content-between"
                                    style={{ width: '100%' }}>
                                    <div className="flex-r align-items-center">
                                        {m?.profilemedia ? (
                                            <div className="img-file">
                                                <img
                                                    src={resolveUrl(
                                                        m?.profilemedia
                                                    )}
                                                    alt="User profile image"
                                                    title="User profile Image"
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                className="flex-c img-file"
                                                style={{
                                                    backgroundColor: m?.gravatar
                                                }}>
                                                <div className="flex-r align-items-center justify-content-center flex-grow-1 white fw-500 font-20">
                                                    <span>{`${m?.firstname[0]}${m?.lastname[0]}`}</span>
                                                </div>
                                            </div>
                                        )}
                                        <span className="font-20 fw-500 ml-2">{`${m?.firstname} ${m?.lastname[0]}.`}</span>
                                    </div>
                                    <div>
                                        <input
                                            type="checkbox"
                                            name="removeAssignee"
                                            checked={
                                                assigneesValue[m.id]
                                                    ? true
                                                    : false
                                            }
                                            onChange={() => {
                                                console.log('clicked')
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="flex-c text-c modal-footer">
                        <div
                            onClick={() => showModal(false)}
                            className="btn btn-secondary"
                            title="Finished Selecting">
                            Finished Selecting
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
