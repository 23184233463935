import { API } from '../../services/main.service'

export const resolveUrl = url => {
    let _p = ''
    let _u = url
    if (!_u?.length) return null
    else if (String(_u).startsWith('.')) {
        _u = _u.substr(1)
        _p = API
    } else if (String(url).startsWith('http')) {
        // none
    } else {
        _p = API
    }
    return `${_p}${_u}`
}

export const capitalize = val => {
    if (typeof val != 'string') return val
    return `${val[0]?.toUpperCase()}${val?.substring(1)}`
}

export const formatData = (data, label, value) => {
    if (!label) {
        return data?.map(d => {
            return {
                label: capitalize(d),
                value: d
            }
        })
    } else {
        return data.map(d => {
            return {
                label: d[label],
                value: d[value]
            }
        })
    }
}

export const validateAlphabet = str => {
    const regex = /^[A-Za-z]+$/
    return regex.test(str)
}

export const getImgSrc = imgData => {
    if (typeof imgData == 'string') return resolveUrl(imgData)
    return URL.createObjectURL(imgData)
}
