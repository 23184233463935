import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import BreadCrumb from '../components/elements/breadCrumb'
import { setBreadCrumb } from '../config/slice/others.slice'
import searchService from '../services/search.service'
import { searchResults } from '../config/slice/search.slice'
import { toast } from 'react-toastify'

function Search() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { search } = useLocation()
    const [loading, setLoading] = useState(false)
    const { loading: userLoading, user: user } = useSelector(
        state => state.user
    )
    const [ageFromValue, setAgeFrom] = useState('18')
    const [ageToValue, setAgeTo] = useState('65')
    const [distanceFromValue, setDistanceFrom] = useState('0')

    const {
        register,
        setValue,
        formState: { errors },
        handleSubmit
    } = useForm()

    const title = 'Search | Cardeon - Member Search'
    const url = 'https://cardeon.io/search'
    const desc = 'Search for new team members.'

    async function goBack() {
        navigate(`/my-team`)
    }

    useEffect(() => {
        async function init() {
            setLoading(true)
            dispatch(searchResults())
            dispatch(
                setBreadCrumb([
                    { name: 'My Team', path: '/my-team' },
                    {
                        name: 'Member Search',
                        path: `/search`
                    }
                ])
            )
            setLoading(false)
        }

        setValue('distanceFrom', distanceFromValue, { shouldDirty: true })
        setValue('ageFrom', ageFromValue, { shouldDirty: true })
        setValue('ageTo', ageToValue, { shouldDirty: true })

        init()
    }, [search])

    useEffect(() => {
        dispatch(
            setBreadCrumb([
                {
                    name: 'My Team',
                    path: `/my-team`
                },
                {
                    name: 'Member Search',
                    path: `/search`
                }
            ])
        )
    }, [user])

    // Intent
    const [searchIntents, setSearchIntent] = useState(['Client', 'Provider'])

    async function addRemoveIntentFilter(text) {
        if (searchIntents.includes(text)) {
            // remove
            const removedIntents = [...searchIntents]
            let pos = removedIntents.indexOf(text)
            removedIntents.splice(pos, 1)
            setSearchIntent(removedIntents)
        } else {
            // add
            const addedIntents = [...searchIntents]
            addedIntents.push(text)
            setSearchIntent(addedIntents)
        }
    }

    // Gender

    const [searchGenders, setSearchGender] = useState({
        male: 'male',
        female: 'female'
    })

    async function addRemoveGenderFilter(text) {
        const searchGendersTemp = { ...searchGenders }
        if (searchGendersTemp[text]) {
            delete searchGendersTemp[text]
            setSearchGender(searchGendersTemp)
        } else {
            searchGendersTemp[text] = text
            setSearchGender(searchGendersTemp)
        }
    }

    const handleKeyDown = async event => {
        if (event.key === 'Enter') {
            handleSearch()
        }
    }

    const handleSearch = async () => {
        const intent = searchIntents
        const ageFrom = ageFromValue
        const ageTo = ageToValue
        const distanceFrom =
            user.zip !== null
                ? distanceFromValue === '0'
                    ? ''
                    : distanceFromValue
                : ''
        const gender = Object.keys(searchGenders)

        const toSend = {
            intent,
            ageFrom,
            ageTo,
            distanceFrom,
            gender
        }
        const urlSearchParams = new URLSearchParams(toSend)
        navigate(`/search/memberresults?${urlSearchParams}`)
    }

    return (
        <>
            <BreadCrumb />
            <div className="page-content flex-c flex-grow-1 interior-view">
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={desc} />
                    <link rel="canonical" href={url} />
                    <meta property="og:url" content={url} />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={desc} />
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={desc} />
                </Helmet>
                <div className="flex-c container">
                    <div className="flex-c row align-items-center mt-3">
                        <div className="flex-c col-md-8 col-lg-6 mt-4">
                            <div className="flex-c flex-lg-r align-items-lg-center mt-4">
                                <div className="flex-c flex-1">
                                    <button
                                        className="touch touch-pop"
                                        onClick={goBack}
                                        type="button"
                                        title="Return">
                                        <i className="fa-solid fa-arrow-left"></i>
                                    </button>
                                </div>
                                <h1 className="flex-3 text-lg-c mt-3 mt-lg-0">
                                    Member Search
                                </h1>
                                <div className="flex-1" />
                            </div>

                            <p className="text-lg-c lh-20 mt-1 mb-5">
                                Search for new members to add to your team.
                            </p>

                            {user?.visible && (
                                <form
                                    onSubmit={handleSubmit(handleSearch)}
                                    className="flex-c"
                                    onKeyDown={handleKeyDown}>
                                    <div className="flex-c mb-4">
                                        <label className="mb-2">
                                            User Types
                                        </label>
                                        <div className="flex-r align-items-center">
                                            <input
                                                type="checkbox"
                                                aria-required="true"
                                                className="m-0"
                                                id="clients"
                                                value="Client"
                                                checked={searchIntents.includes(
                                                    'Client'
                                                )}
                                                onChange={() =>
                                                    addRemoveIntentFilter(
                                                        'Client'
                                                    )
                                                }
                                            />
                                            <label
                                                htmlFor="clients"
                                                className="fw-400 mr-2 ml-2">
                                                Clients
                                            </label>
                                            <input
                                                type="checkbox"
                                                aria-required="true"
                                                className="m-0 ml-2"
                                                id="providers"
                                                value="Provider"
                                                checked={searchIntents.includes(
                                                    'Provider'
                                                )}
                                                onChange={() =>
                                                    addRemoveIntentFilter(
                                                        'Provider'
                                                    )
                                                }
                                            />
                                            <label
                                                htmlFor="providers"
                                                className="fw-400 ml-2">
                                                Providers
                                            </label>
                                        </div>
                                    </div>

                                    {user && user.zip !== null && (
                                        <div className="input-wrapper">
                                            <label
                                                className="pl-1"
                                                aria-describedby="Radius in miles">
                                                Radius in miles
                                            </label>
                                            <input
                                                type="number"
                                                aria-required="true"
                                                {...register('distanceFrom', {
                                                    required: false,
                                                    min: 0,
                                                    max: 50
                                                })}
                                                className="form-control mt-2"
                                                id="distanceFrom"
                                                value={distanceFromValue}
                                                onChange={e =>
                                                    setDistanceFrom(
                                                        e.target.value
                                                    )
                                                }
                                                placeholder="Range: 1 - 50"
                                            />
                                            <span className="font-12 italic fw-500 pt-2">
                                                A value of 0 will omit filtering
                                                by location
                                            </span>
                                            <div className="input-desc red">
                                                {errors.distanceFrom &&
                                                    'Please provide a value from 0 to 50.'}
                                            </div>
                                        </div>
                                    )}

                                    <div className="flex-c mb-4">
                                        <label className="mb-2">Gender</label>
                                        <div className="flex-r align-items-center">
                                            <input
                                                type="checkbox"
                                                aria-required="true"
                                                className="m-0"
                                                id="female"
                                                checked={
                                                    searchGenders['female']
                                                        ? true
                                                        : false
                                                }
                                                onChange={() =>
                                                    addRemoveGenderFilter(
                                                        'female'
                                                    )
                                                }
                                            />
                                            <label
                                                htmlFor="female"
                                                className="fw-400 mr-2 ml-2">
                                                Female
                                            </label>
                                            <input
                                                type="checkbox"
                                                aria-required="true"
                                                className="m-0 ml-2"
                                                id="male"
                                                checked={
                                                    searchGenders['male']
                                                        ? true
                                                        : false
                                                }
                                                onChange={() =>
                                                    addRemoveGenderFilter(
                                                        'male'
                                                    )
                                                }
                                            />
                                            <label
                                                htmlFor="male"
                                                className="fw-400 ml-2">
                                                Male
                                            </label>
                                        </div>
                                    </div>

                                    <div className="flex-c mb-5">
                                        <div className="flex-c flex-sm-r">
                                            <div className="flex-c flex-1 mr-sm-2">
                                                <div className="input-wrapper">
                                                    <label
                                                        className="pl-1"
                                                        aria-describedby="Age From">
                                                        Age From
                                                    </label>
                                                    <input
                                                        type="number"
                                                        aria-required="true"
                                                        {...register(
                                                            'ageFrom',
                                                            {
                                                                required: true,
                                                                min: 18,
                                                                max:
                                                                    ageToValue -
                                                                    1
                                                            }
                                                        )}
                                                        className="form-control mt-2"
                                                        id="ageFrom"
                                                        value={ageFromValue}
                                                        onChange={e =>
                                                            setAgeFrom(
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder="Minimum: 18"
                                                    />
                                                    <span className="font-12 italic fw-500 pt-2">
                                                        Minimum: 18
                                                    </span>
                                                    <div className="input-desc red">
                                                        {errors.ageFrom &&
                                                            'Please provide a value of 18 or greater and less than Age To.'}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-c flex-1 ml-sm-2">
                                                <div className="input-wrapper">
                                                    <label
                                                        className="pl-1"
                                                        aria-describedby="Age To">
                                                        Age To
                                                    </label>
                                                    <input
                                                        type="number"
                                                        aria-required="true"
                                                        {...register('ageTo', {
                                                            required: true,
                                                            min:
                                                                +ageFromValue +
                                                                1,
                                                            max: 65
                                                        })}
                                                        className="form-control mt-2"
                                                        id="ageTo"
                                                        value={ageToValue}
                                                        onChange={e =>
                                                            setAgeTo(
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder="Maximum: 65"
                                                    />
                                                    <span className="font-12 italic fw-500 pt-2">
                                                        Maximum: 65
                                                    </span>
                                                    <div className="input-desc red">
                                                        {errors.ageTo &&
                                                            'Please provide a value of 65 or lesser and greater than Age From.'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        onClick={handleSubmit(handleSearch)}
                                        className="btn btn-secondary mb-4"
                                        title="Search">
                                        Search
                                    </div>
                                    <div className="text-c">
                                        <Link
                                            className="gray-700 hover fw-500"
                                            to={`/my-team`}
                                            title="Cancel">
                                            Cancel
                                        </Link>
                                    </div>
                                </form>
                            )}
                            {!user?.visible && (
                                <div className="flex-r align-items-center justify-content-center blip text-c">
                                    While hidden, you may not search for
                                    members, but you may message existing
                                    connections.
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Search
