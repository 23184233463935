import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { resolveUrl } from '../../config/helpers'

function BoxPackCard({
    data,
    type,
    status,
    statusFunc,
    reassignFunction,
    copyFunction,
    deleteFunction,
    actionPaths,
    style,
    index,
    menuState,
    menuStateFunc
}) {
    const navigate = useNavigate()

    const closeMenu = () => {
        menuStateFunc(-1)
    }

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    closeMenu()
                }
            }
            document.addEventListener('mousedown', handleClickOutside)
            return () => {
                document.removeEventListener('mousedown', handleClickOutside)
            }
        }, [ref])
    }

    const wrapperRef = useRef(null)
    useOutsideAlerter(wrapperRef)

    const toggleMenu = () => {
        if (menuState) {
            menuStateFunc(-1)
        } else {
            menuStateFunc(index)
        }
    }

    return (
        <div
            className={`flex-r data-list ${style}`}
            onClick={() => {
                navigate(actionPaths?.view ?? '')
            }}>
            <div className="flex-r flex-1 align-items-center">
                <div
                    className={`flex-c img-data-item ${
                        data?.badge ? 'bckg-white' : 'bckg-gravatar'
                    }`}>
                    {data?.badge ? (
                        <img
                            src={resolveUrl(data?.badge)}
                            alt="Badge Image"
                            title="Badge Image"
                        />
                    ) : (
                        <div className="flex-r align-items-center justify-content-center flex-grow-1 white fw-500">
                            <span>{data?.gravatar}</span>
                        </div>
                    )}
                </div>
                <div className="flex-r flex-1 dl-item-title fw-500 ml-3">
                    {data?.name}
                </div>
                {type == 'box' ? (
                    <div className="display-hide display-sm-flex flex-r flex-sm-1 fw-500">
                        Packs:
                        <span className="fw-300 pl-2">
                            {data?.packs?.length}
                        </span>
                    </div>
                ) : (
                    <div className="display-hide display-sm-flex flex-r flex-sm-1 fw-500">
                        Cards:
                        <span className="fw-300 pl-2">{data?.totalCards}</span>
                    </div>
                )}
            </div>
            <div className="flex-r flex-1 align-items-center justify-content-end">
                <div className="flex-r flex-sm-1 mr-2">
                    {data?.active ? (
                        <div className="pill pill-sm pill-sm-sq bckg-g-l green m-0">
                            <span className="display-hide display-sm-flex">
                                Active
                            </span>
                            <i className="display-sm-hide fa-solid fa-check"></i>
                        </div>
                    ) : (
                        <div className="pill pill-sm pill-sm-sq bckg-r-l red m-0">
                            <span className="display-hide display-sm-flex">
                                Inactive
                            </span>
                            <i className="display-sm-hide fa-solid fa-xmark"></i>
                        </div>
                    )}
                </div>
                <div className="dl-item-title">
                    {type == 'box'
                        ? data?.packs.some(el => el.packRemoved == true) && (
                              <i className="fa-solid fa-circle-exclamation red mr-2 mr-sm-3"></i>
                          )
                        : data.packRemoved && (
                              <i className="fa-solid fa-circle-exclamation red mr-2 mr-sm-3"></i>
                          )}
                </div>
                <div
                    ref={menuState ? wrapperRef : null}
                    className="flex-c justify-content-center align-items-end p-relative">
                    <div
                        type="button"
                        className="d-flex btn-menu"
                        onClick={e => {
                            e.stopPropagation()
                        }}>
                        <i
                            className={`fas
                            ${menuState ? 'fa-times' : 'fa-ellipsis-vertical'}
                            `}
                            onBlur={() => {
                                console.log('not there again')
                            }}
                            onClick={e => {
                                toggleMenu()
                            }}
                            title="Toggle Menu Visibility"></i>
                    </div>

                    <div
                        className={`flex-c bar-control shadow ${
                            menuState ? 'open' : null
                        }`}>
                        {type === 'pack' && data.packRemoved && (
                            <a
                                className="nav-item"
                                style={{ cursor: 'pointer' }}
                                onClick={e => {
                                    e.stopPropagation()
                                    reassignFunction(data)
                                    closeMenu()
                                }}
                                title={'Re-assign Pack'}>
                                Re-assign
                            </a>
                        )}
                        <Link
                            onClick={e => e.stopPropagation()}
                            className="nav-item"
                            to={actionPaths?.view ?? ''}
                            title={type === 'box' ? 'View Box' : 'View Pack'}>
                            View
                        </Link>
                        <Link
                            onClick={e => e.stopPropagation()}
                            className="nav-item"
                            to={actionPaths?.edit ?? ''}
                            title={type === 'box' ? 'Edit Box' : 'Edit Pack'}>
                            Edit
                        </Link>
                        <a
                            className="nav-item"
                            style={{ cursor: 'pointer' }}
                            onClick={e => {
                                e.stopPropagation()
                                copyFunction(data.id)
                                closeMenu()
                            }}
                            title={type === 'box' ? 'Copy Box' : 'Copy Pack'}>
                            Copy
                        </a>
                        <a
                            className="nav-item"
                            style={{ cursor: 'pointer' }}
                            onClick={e => {
                                e.stopPropagation()
                                statusFunc(data.id)
                                closeMenu()
                            }}
                            title={status ? 'De-activate' : 'Activate'}>
                            {status ? 'De-activate' : 'Activate'}
                        </a>
                        <a
                            className="nav-item red"
                            style={{ cursor: 'pointer' }}
                            onClick={e => {
                                e.stopPropagation()
                                deleteFunction(data.id)
                                closeMenu()
                            }}
                            title={
                                type === 'box' ? 'Delete Box' : 'Delete Pack'
                            }>
                            Delete
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BoxPackCard
