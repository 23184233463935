import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import authService from '../services/auth.service'
import { deleteProfileMedia, getMemberSelf, updateMemberSelf, updateProfileMedia } from '../actions/members'
import { resetUser } from '../config/slice/user.slice'
import { userSubs } from '../config/slice/subs.slice'
import { selectedPlan } from '../config/slice/selectedPlan.slice'
import { modalState } from '../config/slice/component.slice'
import { toast } from 'react-toastify'
import { useNavigate, Link } from 'react-router-dom'
import moment from 'moment'
import 'react-toastify/dist/ReactToastify.css'
import { TermsModal } from '../components/Modals/terms'
import subsService from '../services/subs.service'
import memberService from '../services/members.service'
import GeneralModal, { default as DeleteAccountModal } from '../components/Modals/generalModal'
import {
    togglePromo,
    updatememberLocation,
    removeBlockedMembers,
    getBlockedMembers
} from '../actions/members'
import { updateProviderTeam } from '../actions/team'
import DropDownInput from '../components/inputs/DropDownInput'
import TextInput from '../components/inputs/TextInput'
import { formatData, getImgSrc, resolveUrl } from '../config/helpers'
import SubmitButton from '../components/elements/submitButton'
import DimensionContent from '../components/score/dimensions'
import SummaryContent from '../components/score/summary'
import { sendResetPasswordCode } from '../actions/members'
import Crop from '../components/elements/Crop'


function Account() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [initValue, initSetup] = useState(false)
    const [tabValue, setTab] = useState('settings')
    const [birthdayValue, setBirthday] = useState('')
    const [teamValue, setTeam] = useState('')
    const [displayValue, setDisplay] = useState('')
    const [agreeValue, setAgree] = useState('')
    const [modalValue, setModal] = useState('')
    const [modalType, setModalType] = useState('')
    const [showProfileModal, setShowProfileModal] = useState(false)
    const [selectedProfileImage, setSelectedProfileImage] = useState(null)
    const [showCropModal, setShowCropModal] = useState(false)

    const { loading: userLoading, user: user } = useSelector(
        state => state.user
    )

    const [userInfo, setUserInfo] = useState({
        emailPromo: false,
        scoreTeams: true,
        scoreBadges: true,
        scoreEngagements: true,
        scoreVisible: true,
        profileVisible: true,
        zip: ''
    })  
    const [zipValidation, setZipValidation] = useState(true)
    const [toUnblock, setToUnblock] = useState({})
    const modal = useSelector(state => state.modal.value)
    const subs = useSelector(state => state.subs.value)
    const planSelection = useSelector(state => state.planSelection.value)
    const mySub = subs ? subs.subscription : null
    const subStartSec = subs ? new Date(mySub.currentPeriodStart * 1000) : null
    const subStartDate = subs ? moment(subStartSec).format('MM/DD/YYYY') : null
    const subEndSec = subs ? new Date(mySub.currentPeriodEnd * 1000) : null
    const subEndDate = subs ? moment(subEndSec).format('MM/DD/YYYY') : null
    const trialEndDate = subs
        ? moment(subStartSec).add(1, 'M').format('MM/DD/YYYY')
        : null
    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm()
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const genderValue = ['male', 'female']

    const gravatars = [
        { label: 'Primary', value: 'rgb(78, 52, 229)' },
        { label: 'Red', value: 'rgb(255, 80, 0)' },
        { label: 'Yellow', value: 'rgb(255, 172, 0)' },
        { label: 'Pink', value: 'rgb(172, 79, 155)' },
        { label: 'Orange', value: 'rgb(255, 117, 25)' },
        { label: 'Gray', value: 'rgb(100, 100, 100)' },
        { label: 'Green', value: 'rgb(51, 182, 121)' },
        { label: 'Blue', value: 'rgb(1, 84, 150)' }
    ]

    const [inputValue, setInput] = useState({
        gravatar: '',
        gender: '',
        summary: '',
        visible: '',
        team: '',
        organization: ''
    })

    const title = 'Account | Cardeon - My Account'
    const url = 'https://cardeon.io/account'
    const desc =
        'Review and manage your Cardeon membership account. Wellness coaching subscription and team management.'

    useEffect(() => {
        async function init() {
            if (Object.keys(user).length > 0) {
                if (user?.agreement != undefined && user?.agreement === false) {
                    document.body.classList.add('o-flow-y-hide')
                    dispatch(modalState(true))
                } else if (
                    user?.team?.id &&
                    planSelection &&
                    planSelection === 'trial' &&
                    user?.trialEverStarted === false
                ) {
                    await kickTrial()
                } else if (
                    user?.team?.id &&
                    planSelection &&
                    planSelection.id
                ) {
                    await kickSubscription()
                } else if (planSelection) {
                    initSetup(true)
                }
                const birthday = user?.birthday
                const prettyBirthday = `${
                    new Date(birthday).getMonth() + 1
                }-${new Date(birthday).getDate()}-${new Date(
                    birthday
                ).getFullYear()}`
                setBirthday(prettyBirthday)
                setInput({
                    gravatar: user?.gravatar,
                    gender: user?.gender,
                    summary: user?.summary !== null ? user?.summary : '',
                    visible: user?.visible,
                    team: user?.team?.name,
                    organization: user?.team?.organization
                })
                setDisplayName(user?.team?.name)
                setSelectedProfileImage(user?.profilemedia??null)
                setLoading(false)
            }
            setLoading(false)
        }
        init()
    }, [user])

    useEffect(() => {
        async function self() {
            setLoading(true)
            await getSelf()
            setLoading(false)
        }
        self()
    }, [])

    useEffect(() => {
        if (user?.id) {
            setUserInfo({
                emailPromo: user?.emailPromo,
                scoreTeams: user?.scoreTeams,
                scoreBadges: user?.scoreBadges,
                scoreVisible: user?.scoreVisible,
                scoreEngagements: user?.scoreEngagements,
                profileVisible: user?.visible,
                zip: user?.zip ?? ''
            })
        }
    }, [user])

    const sendForgetPasswordCode = async () => {
        setLoading(true)
        const prettyJson = {
            email: user?.email
        }
        dispatch(
            sendResetPasswordCode({
                payload: prettyJson,
                callBacks: {
                    success: () => {
                        setLoading(false)
                        toast.success('Code sent', {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        })
                    },
                    err: err => {
                        setLoading(false)
                    }
                }
            })
        )
    }

    const updateToUnblock = id => {
        let toUnblockTemp = { ...toUnblock }
        if (toUnblockTemp[id]) {
            delete toUnblockTemp[id]
            setToUnblock(toUnblockTemp)
        } else {
            setToUnblock({ ...toUnblockTemp, [id]: id })
        }
    }

    const unblockUsers = () => {
        if (Object.keys(toUnblock).length > 0) {
            setLoading(true)
            const payload = {
                members: Object.keys(toUnblock)
            }
            dispatch(
                removeBlockedMembers({
                    payload: payload,
                    callBacks: {
                        success: () => {
                            dispatch(getBlockedMembers())
                            setToUnblock({})
                            setLoading(false)
                        },
                        err: () => {
                            dispatch(getBlockedMembers())
                            setLoading(false)
                        }
                    }
                })
            )
        } else {
            toast.error('No members selected.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    }

    const togglePromoMail = state => {
        setUserInfo({ ...userInfo, emailPromo: state })
        setLoading(true)
        dispatch(
            togglePromo({
                status: state,
                callBacks: {
                    success: () => {
                        setLoading(false)
                    },
                    err: () => {
                        setLoading(false)
                    }
                }
            })
        )
    }

    async function kickTrial() {
        setLoading(true)
        try {
            await subsService.startTrial()
            await getSelf()
            await getSubs()
            dispatch(selectedPlan(null))
            setLoading(false)
        } catch (e) {
            setLoading(false)
            toast.error('Unknown Error.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    }

    async function kickSubscription() {
        setLoading(true)
        try {
            let teamJson = {
                team: user?.team.id,
                priceId: planSelection.id,
                agreement: user?.team.agreement
            }
            const resp = await subsService.postCreateSub(teamJson)
            if (resp) {
                dispatch(userSubs(resp))
                window.location.href = resp.sessionUrl
                // window.open(resp.sessionUrl, '_blank').focus()
                dispatch(selectedPlan(null))
                await getSelf()
                setLoading(false)
            }
        } catch (e) {
            setLoading(false)
            toast.error('Unknown Error.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    }

    async function getSubs() {
        try {
            const sub = await subsService.getSubs()
            if (sub.member) {
                dispatch(userSubs(sub))
            } else {
                dispatch(userSubs(null))
            }
        } catch (e) {
            toast.error('Unknown Error.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
            return
        }
    }

    const handleKeyDown = async event => {
        if (event.key === 'Enter') {
            providerSignup()
        }
    }

    async function fireTrial(e) {
        e.preventDefault()
        if (user?.team !== null && user?.team.agreement) {
            kickTrial()
        } else {
            dispatch(selectedPlan('trial'))
            initSetup(true)
        }
    }

    async function providerSignup() {
        setLoading(true)

        let prettierJson = {
            email: user?.email,
            name: displayValue,
            organization: teamValue,
            agreement: agreeValue
        }

        const res = await memberService.postBecomeProvider(prettierJson)
        await getSelf()

        if (planSelection === 'trial') {
            try {
                await subsService.startTrial()
                await getSubs()
                dispatch(selectedPlan(null))
                initSetup(false)
            } catch (e) {
                toast.error('Unknown Error.', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
            }
        } else if (planSelection.id) {
            try {
                let teamJson = {
                    team: res.id,
                    priceId: planSelection.id,
                    agreement: agreeValue
                }
                const resp = await subsService.postCreateSub(teamJson)
                if (resp) {
                    await dispatch(userSubs(resp))
                    window.location.href = resp.sessionUrl
                    // window.open(resp.sessionUrl, '_blank').focus()
                    dispatch(selectedPlan(null))
                    initSetup(false)
                }
            } catch (e) {
                toast.error('Unknown Error.', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
            }
        }
        setLoading(false)
    }

    const getSelf = async () => {
        setLoading(true)
        dispatch(
            getMemberSelf({
                callBacks: {
                    success: () => {
                        setLoading(false)
                    },
                    err: () => {
                        setLoading(false)
                        toast.error('Error fetching profile.', {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        })
                    }
                }
            })
        )
    }

    const onEmailReset = async () => {
        try {
            const res = authService.passwordResetEmail(user?.email)
            if (res) {
                toast.success('Reset Email Sent', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
            }
        } catch (e) {
            toast.error('Error sending reset email.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    }

    const onRefresh = async () => {
        setModal(false)
        try {
            setLoading(true)
            await getSubs()
            setLoading(false)
        } catch (e) {
            setLoading(false)
            toast.error('Error refreshing data.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    }

    const deleteSelf = async () => {
        setShowDeleteModal(false)
        try {
            setLoading(true)
            await memberService.deleteMemberSelf()

            authService.logout().then(() => {
                navigate('/')
                dispatch(resetUser())
                dispatch(selectedPlan(''))
                dispatch(userSubs(''))
            })
            setLoading(false)
        } catch (e) {
            setLoading(false)
            toast.error('Error deleting account.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    }

    async function goBack() {
        dispatch(selectedPlan(null))
        initSetup(false)
    }

    const triggerModal = modalType => {
        setModalType(modalType)
        setModal(true)
    }

    const onCloseModal = () => {
        setModalType('')
        setModal(false)
    }

    const onCancelSub = async () => {
        setLoading(true)
        try {
            await subsService.cancelSub(mySub.id)
            await getSelf()
            await getSubs()
            onCloseModal()
            setLoading(false)
        } catch (e) {
            setLoading(false)
            toast.error('Error cancelling subscription.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    }

    const removeSubscription = async () => {
        setLoading(true)
        try {
            await subsService.removeSub()
            dispatch(userSubs(null))
            await getSelf()
            onCloseModal()
            setLoading(false)
        } catch (e) {
            setLoading(false)
            toast.error('Error removing subscription.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    }

    const handleOnChange = e => {
        setInput({
            ...inputValue,
            [e.target.name]: e.target.value
        })
    }

    const handleUserInfoChange = (name, val) => {
        setUserInfo({ ...userInfo, [name]: val })
    }

    async function zipEval(value) {
        handleUserInfoChange('zip', value)
        const zipCheck = /[^0-9]/

        if (value.length == 5) {
            if (!zipCheck.test(value)) {
                setZipValidation(true)
            }
        } else if (value.length == 0) {
            setZipValidation(true)
        } else {
            setZipValidation(false)
        }
    }

    function updateProfileImage(data){
        if(!data) return
        setLoading(true)
        dispatch(
            updateProfileMedia({
                params:data,
                callBacks:{
                    success:()=>{
                        setLoading(false)
                    },
                    err:(err)=>{
                        setLoading(false)
                    }
                }
            })
        )
    }

    function removeProfileImage(){
        setLoading(true)
        let prettyJson = {
            id: selectedProfileImage.split(new RegExp('[_/]'))[5],
            url: selectedProfileImage
        }
        dispatch(
            deleteProfileMedia({
                params:prettyJson,
                callBacks:{
                    success:()=>{
                        toast.success("Profile image removed successfully", {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        })
                        setSelectedProfileImage(null)
                        setShowProfileModal(false)
                        setLoading(false)
                    },
                    err:()=>{
                        toast.error("Error removing profile image", {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        })
                        setLoading(false)
                    }
                }
            })
        )
    }

    function updateSelf(payload, successMsg, errMsg) {
        setLoading(true)
        updateProfileImage(selectedProfileImage)
        dispatch(
            updateMemberSelf({
                payload: payload,
                callBacks: {
                    success: () => {
                        setLoading(false)
                        toast.success(successMsg, {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        })
                    },
                    err: () => {
                        setLoading(false)
                        toast.error(errMsg, {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        })
                    }
                }
            })
        )
    }

    async function updateSettings() {
        setLoading(true)
        let prettyJson = {
            gravatar: inputValue.gravatar,
            gender: inputValue.gender,
            summary: inputValue.summary,
            scoreTeams: userInfo.scoreTeams,
            scoreBadges: userInfo.scoreBadges,
            scoreEngagements: userInfo.scoreEngagements,
            scoreVisible: userInfo.scoreVisible
        }
        updateSelf(prettyJson, 'Settings updated.', 'Error updating settings.')
    }

    async function updateZip() {
        setLoading(true)
        let prettyJson = {
            zip: userInfo.zip
        }
        dispatch(
            updatememberLocation({
                payload: prettyJson,
                callBacks: {
                    success: () => {
                        setLoading(false)
                        toast.success('Zip code successfully updated.', {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        })
                    },
                    err: err => {
                        setLoading(false)
                        toast.error(err, {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        })
                    }
                }
            })
        )
    }

    async function updatePrivacy() {
        setLoading(true)
        let prettyJson = {
            visible: userInfo.profileVisible
        }
        updateSelf(prettyJson, 'Privacy updated.', 'Error updating privacy.')
    }

    const [displayName, setDisplayName] = useState('')

    async function updateTeam() {
        setLoading(true)
        let payload = {
            name: displayName,
            organization: inputValue.organization,
            agreement: true
        }
        dispatch(
            updateProviderTeam({
                payload: payload,
                callBacks: {
                    success: data => {
                        setInput({
                            ...inputValue,
                            team: data.name,
                            organization: data.organization
                        })
                        setDisplayName(data.name)
                        toast.success('Team updated.', {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        })
                        setLoading(false)
                    },
                    err: () => {
                        toast.error('Error updating team.', {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        })
                        setLoading(false)
                    }
                }
            })
        )
    }

    const handleFileChange = e => {
        setSelectedProfileImage(e.target.files[0])
        setShowCropModal(true)
    }

    const BlockedComponent = blocked => {
        if (blocked?.length < 1) {
            return (
                <>
                    <div className="flex-c row align-items-center mt-2">
                        <div className="flex-c col-12 flex-auto text-c pt-5">
                            <div className="flex-r align-items-center mt-4">
                                <div className="flex-c flex-1 align-items-lg-end">
                                    {initValue && (
                                        <button
                                            className="touch touch-pop"
                                            onClick={goBack}
                                            type="button"
                                            title="Cancel">
                                            <i className="fa-solid fa-arrow-left"></i>
                                        </button>
                                    )}
                                </div>
                                <h1 className="flex-3 text-c">Blocked List</h1>
                                <div className="flex-1" />
                            </div>
                            <p className="lh-20 mt-1 mb-3">
                                Your blocked list is empty
                            </p>
                        </div>
                    </div>
                    <div className="flex-c row align-items-center">
                        <div className="flex-c col-md-8 col-lg-6 mt-4">
                            <div className="flex-r align-items-center justify-content-center blip">
                                <i className="fa-solid fa-circle-info"></i>
                                <span className="pl-2">
                                    When you have blocked members, they will
                                    appear here.
                                </span>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        return (
            <>
                <div className="flex-c row align-items-center mt-2">
                    <div className="flex-c col-12 flex-auto text-c pt-5">
                        <div className="flex-r align-items-center mt-4">
                            <div className="flex-c flex-1 align-items-lg-end">
                                {initValue && (
                                    <button
                                        className="touch touch-pop"
                                        onClick={goBack}
                                        type="button"
                                        title="Cancel">
                                        <i className="fa-solid fa-arrow-left"></i>
                                    </button>
                                )}
                            </div>
                            <h1 className="flex-3 text-c">Blocked List</h1>
                            <div className="flex-1" />
                        </div>
                        <p className="lh-20 mt-1 mb-3">
                            Edit your blocked list.
                        </p>
                    </div>
                </div>
                <div className="flex-c row align-items-center">
                    <div className="flex-c col-md-8 col-lg-6 mt-4">
                        <div className="flex-c mt-4">
                            {blocked?.map((b, i) => (
                                <div
                                    className="flex-r align-items-center bckg-white basic-wrapper mb-2"
                                    key={i}>
                                    <div
                                        className="flex-c img-user img-user-md mr-2"
                                        style={{
                                            backgroundColor: b.gravatar
                                        }}>
                                        {b.profilemedia ? (
                                            <img
                                                src={resolveUrl(b.profilemedia)}
                                                alt={b.name}
                                                title="Profile Image"
                                            />
                                        ) : (
                                            <div className="flex-r align-items-center justify-content-center flex-grow-1 white fw-500">
                                                <span>
                                                    {b.initial.toUpperCase()}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex-c flex-1 ml-1">
                                        <span className="fw-500">{b.name}</span>
                                    </div>
                                    <input
                                        type="checkbox"
                                        name="blockedCheck"
                                        onChange={() => {
                                            updateToUnblock(b.id)
                                        }}
                                        checked={toUnblock[b.id] ? true : false}
                                    />
                                </div>
                            ))}
                            <SubmitButton
                                title="Unblock Selected"
                                type="submit"
                                style="submit"
                                submitFunction={unblockUsers}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }


    const ProfileImageComponent=()=>{
        return (
            <GeneralModal
                title={"Profile Image Selection"}
                showModal={setShowProfileModal}
                children={
                    <>
                    <p className="lh-24 mt-2 mb-4">
                        {selectedProfileImage?
                        "Replace or remove the existing profile image.":
                        "Upload profile image."}
                        
                    </p>
                    <label className="btn btn-secondary mt-3 mb-4">
                        <input
                            type="file"
                            style={{ display: 'none' }}
                            onChange={e => {
                                handleFileChange(e)
                                setShowProfileModal(false)
                            }}
                        />

                        <span>{selectedProfileImage?"Replace":"Select"}</span>
                    </label>
                    {selectedProfileImage&&
                    <div className="text-c mb-3">
                        <p
                            className="gray-700 hover fw-500 m-0"
                            onClick={() => {
                                removeProfileImage()
                            }}
                            style={{ cursor: 'pointer' }}>
                            Remove
                        </p>
                    </div>
                    }
                </>
                }
            />
        )
    }

    if (loading || userLoading) {
        return (
            <div className="page-account flex-c flex-grow-1 align-items-center justify-content-center bckg-white">
                <span className="gray-600 font-28">
                    <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                </span>
            </div>
        )
    } else {
        return (
            <>
                <div className="crumbs border-bottom-g300 px-4 py-2">
                    <div className="flex-r align-items-center justify-content-center">
                        <a
                            className={`set-link gray-900 hover ${
                                tabValue === 'settings' ? 'selected' : ''
                            }`}
                            onClick={() => {
                                initSetup(false)
                                setTab('settings')
                            }}
                            title="Settings">
                            Settings
                        </a>
                        <a
                            className={`set-link gray-900 hover ${
                                tabValue === 'location' ? 'selected' : ''
                            }`}
                            onClick={() => {
                                initSetup(false)
                                setTab('location')
                            }}
                            title="Location">
                            Location
                        </a>
                        {user?.hasTeam && (
                            <a
                                className={`set-link gray-900 hover ${
                                    tabValue === 'team' ? 'selected' : ''
                                }`}
                                onClick={() => {
                                    initSetup(false)
                                    setTab('team')
                                }}
                                title="Team">
                                Team
                            </a>
                        )}
                        <a
                            className={`set-link gray-900 hover ${
                                tabValue === 'blocked' ? 'selected' : ''
                            }`}
                            onClick={() => {
                                initSetup(false)
                                setTab('blocked')
                            }}
                            title="Blocked List">
                            Blocked List
                        </a>
                        <a
                            className={`set-link gray-900 hover ${
                                tabValue === 'subscription' ? 'selected' : ''
                            }`}
                            onClick={() => {
                                initSetup(false)
                                setTab('subscription')
                            }}
                            title="Subscription">
                            Subscription
                        </a>
                        <a
                            className={`set-link gray-900 hover ${
                                tabValue === 'account' ? 'selected' : ''
                            }`}
                            onClick={() => {
                                initSetup(false)
                                setTab('account')
                            }}
                            title="Account">
                            Account
                        </a>
                        <a
                            className={`set-link gray-900 hover ${
                                tabValue === 'profile' ? 'selected' : ''
                            }`}
                            onClick={() => {
                                initSetup(false)
                                setTab('profile')
                            }}
                            title="Profile">
                            Profile
                        </a>
                    </div>
                </div>
                <div className="page-account flex-c flex-grow-1 interior-view">
                    <Helmet>
                        <title>{title}</title>
                        <meta name="description" content={desc} />
                        <link rel="canonical" href={url} />
                        <meta property="og:url" content={url} />
                        <meta property="og:title" content={title} />
                        <meta property="og:description" content={desc} />
                        <meta name="twitter:title" content={title} />
                        <meta name="twitter:description" content={desc} />
                    </Helmet>

                    <div className="flex-c container">
                        {tabValue === 'settings' && (
                            <>
                                <div className="flex-c row align-items-center mt-2">
                                    <div className="flex-c col-12 flex-auto text-c pt-5">
                                        <div className="flex-r align-items-center mt-4">
                                            <div className="flex-c flex-1 align-items-lg-end">
                                                {initValue && (
                                                    <button
                                                        className="touch touch-pop"
                                                        onClick={goBack}
                                                        type="button"
                                                        title="Cancel">
                                                        <i className="fa-solid fa-arrow-left"></i>
                                                    </button>
                                                )}
                                            </div>
                                            <h1 className="flex-3 text-c">
                                                Settings
                                            </h1>
                                            <div className="flex-1" />
                                        </div>
                                        <p className="lh-20 mt-1 mb-3">
                                            Review your profile settings below.
                                        </p>
                                    </div>
                                </div>
                                <div className="flex-c row align-items-center">
                                    <div className="flex-c col-md-8 col-lg-6 mt-4">
                                        {!initValue && user?.firstname && (
                                            <>
                                                <h3 className="mb-2 mt-4">
                                                    Profile
                                                </h3>

                                                <span className="divider mb-3" />

                                                <div className="flex-c mb-4">
                                                    <label
                                                        htmlFor="Profile Image"
                                                        className="font-16 mb-2">
                                                        Profile Image
                                                    </label>
                                                    <div className="flex-r align-items-center justify-content-between bckg-white basic-wrapper">
                                                        <div
                                                            className="flex-c img-user img-user-md mr-2"
                                                            style={{
                                                                backgroundColor:
                                                                    user?.gravatar
                                                            }}>
                                                            {selectedProfileImage ? (
                                                                <img
                                                                    src={getImgSrc(
                                                                        selectedProfileImage
                                                                    )}
                                                                    className="white fw-500"
                                                                    alt={
                                                                        user
                                                                            ?.firstname[0] +
                                                                        user
                                                                            ?.lastname[0]
                                                                    }
                                                                    title="Profile Image"
                                                                />
                                                            ) : (
                                                                <div className="flex-r align-items-center justify-content-center flex-grow-1 white fw-500">
                                                                    <span>
                                                                        {`${
                                                                            user
                                                                                ?.firstname[0] +
                                                                            user
                                                                                ?.lastname[0]
                                                                        }`.toUpperCase()}
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div
                                                            onClick={() => setShowProfileModal(true)}>
                                                            <span
                                                                className="brand-secondary hover fw-500"
                                                                style={{
                                                                    cursor: 'pointer'
                                                                }}>
                                                                    {selectedProfileImage ?"Update":"Upload"}
                                                            </span>
                                                        </div>
                                                        {showProfileModal && <ProfileImageComponent/>}
                                                        {showCropModal && (
                                                            <Crop
                                                                setSelectedBadge={setSelectedProfileImage}
                                                                image={selectedProfileImage}
                                                                showModal={setShowCropModal}
                                                            />
                                                        )}
                                                    </div>
                                                </div>

                                                <form
                                                    className="mb-5"
                                                    onSubmit={e =>
                                                        e.preventDefault()
                                                    }>
                                                    <DropDownInput
                                                        name={'gravatar'}
                                                        label={'Avatar Color'}
                                                        value={
                                                            inputValue.gravatar
                                                        }
                                                        onChange={
                                                            handleOnChange
                                                        }
                                                        options={formatData(
                                                            gravatars,
                                                            'label',
                                                            'value'
                                                        )}
                                                    />
                                                    <DropDownInput
                                                        name={'gender'}
                                                        label={'Gender'}
                                                        value={
                                                            inputValue.gender
                                                        }
                                                        onChange={
                                                            handleOnChange
                                                        }
                                                        options={formatData(
                                                            genderValue
                                                        )}
                                                    />
                                                    <TextInput
                                                        textarea={true}
                                                        name={'summary'}
                                                        label={'Summary'}
                                                        value={
                                                            inputValue.summary
                                                        }
                                                        placeholder={
                                                            'max 1,000 characters'
                                                        }
                                                        maxLength={'1000'}
                                                        onChange={
                                                            handleOnChange
                                                        }
                                                    />
                                                    <div className="flex-c mb-4">
                                                        <label
                                                            htmlFor="teams"
                                                            className="font-18 mb-2">
                                                            Teams:
                                                        </label>
                                                        <div className="flex-r align-items-center">
                                                            <input
                                                                className="m-0"
                                                                type="radio"
                                                                id="showTeams"
                                                                name="scoreTeams"
                                                                checked={
                                                                    userInfo.scoreTeams
                                                                }
                                                                onChange={e => {
                                                                    handleUserInfoChange(
                                                                        e.target
                                                                            .name,
                                                                        !userInfo.scoreTeams
                                                                    )
                                                                }}
                                                            />
                                                            <label
                                                                className="mr-2 ml-2"
                                                                htmlFor="showTeams">
                                                                Show
                                                            </label>
                                                            <input
                                                                className="m-0 ml-2"
                                                                type="radio"
                                                                id="hideTeams"
                                                                name="scoreTeams"
                                                                checked={
                                                                    !userInfo.scoreTeams
                                                                }
                                                                onChange={e => {
                                                                    handleUserInfoChange(
                                                                        e.target
                                                                            .name,
                                                                        !userInfo.scoreTeams
                                                                    )
                                                                }}
                                                            />
                                                            <label
                                                                className="ml-2"
                                                                htmlFor="hideTeams">
                                                                Hide
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="flex-c mb-4">
                                                        <label
                                                            htmlFor="badges"
                                                            className="font-18 mb-2">
                                                            Badges:
                                                        </label>
                                                        <div className="flex-r align-items-center">
                                                            <input
                                                                className="m-0"
                                                                type="radio"
                                                                id="showBadges"
                                                                name="scoreBadges"
                                                                checked={
                                                                    userInfo.scoreBadges
                                                                }
                                                                onChange={e => {
                                                                    handleUserInfoChange(
                                                                        e.target
                                                                            .name,
                                                                        !userInfo.scoreBadges
                                                                    )
                                                                }}
                                                            />
                                                            <label
                                                                className="mr-2 ml-2"
                                                                htmlFor="showBadges">
                                                                Show
                                                            </label>
                                                            <input
                                                                className="m-0 ml-2"
                                                                type="radio"
                                                                id="hideBadges"
                                                                name="scoreBadges"
                                                                checked={
                                                                    !userInfo.scoreBadges
                                                                }
                                                                onChange={e => {
                                                                    handleUserInfoChange(
                                                                        e.target
                                                                            .name,
                                                                        !userInfo.scoreBadges
                                                                    )
                                                                }}
                                                            />
                                                            <label
                                                                className="ml-2"
                                                                htmlFor="hideBadges">
                                                                Hide
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="flex-c mb-4">
                                                        <label
                                                            htmlFor="engagements"
                                                            className="font-18 mb-2">
                                                            Engagements:
                                                        </label>
                                                        <div className="flex-r align-items-center">
                                                            <input
                                                                className="m-0"
                                                                type="radio"
                                                                id="showEngagements"
                                                                name="scoreEngagements"
                                                                checked={
                                                                    userInfo.scoreEngagements
                                                                }
                                                                onChange={e => {
                                                                    handleUserInfoChange(
                                                                        e.target
                                                                            .name,
                                                                        !userInfo.scoreEngagements
                                                                    )
                                                                }}
                                                            />
                                                            <label
                                                                className="mr-2 ml-2"
                                                                htmlFor="showEngagements">
                                                                Show
                                                            </label>
                                                            <input
                                                                className="m-0 ml-2"
                                                                type="radio"
                                                                id="hideEngagements"
                                                                name="scoreEngagements"
                                                                checked={
                                                                    !userInfo.scoreEngagements
                                                                }
                                                                onChange={e => {
                                                                    handleUserInfoChange(
                                                                        e.target
                                                                            .name,
                                                                        !userInfo.scoreEngagements
                                                                    )
                                                                }}
                                                            />
                                                            <label
                                                                className="ml-2"
                                                                htmlFor="hideEngagements">
                                                                Hide
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="flex-c">
                                                        <label
                                                            htmlFor="scoreData"
                                                            className="font-18 mb-2">
                                                            Score Data:
                                                        </label>
                                                        <div className="flex-r align-items-center">
                                                            <input
                                                                className="m-0"
                                                                type="radio"
                                                                id="showScoreData"
                                                                name="scoreVisible"
                                                                checked={
                                                                    userInfo.scoreVisible
                                                                }
                                                                onChange={e => {
                                                                    handleUserInfoChange(
                                                                        e.target
                                                                            .name,
                                                                        !userInfo.scoreVisible
                                                                    )
                                                                }}
                                                            />
                                                            <label
                                                                className="mr-2 ml-2"
                                                                htmlFor="showScoreData">
                                                                Show
                                                            </label>
                                                            <input
                                                                className="m-0 ml-2"
                                                                type="radio"
                                                                id="hideScoreData"
                                                                name="scoreVisible"
                                                                checked={
                                                                    !userInfo.scoreVisible
                                                                }
                                                                onChange={e => {
                                                                    handleUserInfoChange(
                                                                        e.target
                                                                            .name,
                                                                        !userInfo.scoreVisible
                                                                    )
                                                                }}
                                                            />
                                                            <label
                                                                className="ml-2"
                                                                htmlFor="hideScoreData">
                                                                Hide
                                                            </label>
                                                        </div>
                                                    </div>
                                                </form>
                                                <button
                                                    className="btn btn-secondary"
                                                    onClick={() => {
                                                        updateSettings()
                                                    }}
                                                    title="Update Settings">
                                                    Update Settings
                                                </button>
                                            </>
                                        )}

                                        {initValue && (
                                            <form
                                                onSubmit={handleSubmit(
                                                    providerSignup
                                                )}
                                                className="flex-c"
                                                onKeyDown={handleKeyDown}>
                                                <h3 className="my-4">
                                                    Team Details
                                                </h3>

                                                <div className="input-wrapper">
                                                    <label
                                                        className="pl-1"
                                                        aria-describedby="Team Name">
                                                        Team Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        aria-required="true"
                                                        {...register('team', {
                                                            required: true
                                                        })}
                                                        className="form-control mt-2"
                                                        id="team"
                                                        value={teamValue}
                                                        onChange={e =>
                                                            setTeam(
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder="team name"
                                                    />
                                                    <div className="input-desc red">
                                                        {errors.team &&
                                                            'Please provide your team name.'}
                                                    </div>
                                                </div>

                                                <div className="input-wrapper">
                                                    <label
                                                        htmlFor="display"
                                                        className="pl-1"
                                                        aria-describedby="Display Name">
                                                        Display Name
                                                    </label>
                                                    <select
                                                        name="display"
                                                        aria-required="true"
                                                        {...register(
                                                            'display',
                                                            {
                                                                required: true
                                                            }
                                                        )}
                                                        className="form-control mt-2"
                                                        id="display"
                                                        value={displayValue}
                                                        onChange={e =>
                                                            setDisplay(
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder="please select">
                                                        <option
                                                            defaultValue
                                                            disabled
                                                            value="">
                                                            please select
                                                        </option>
                                                        <option
                                                            value={
                                                                user?.firstname +
                                                                user?.lastname
                                                            }>
                                                            {user?.firstname}{' '}
                                                            {user?.lastname}
                                                        </option>
                                                        <option
                                                            value={teamValue}>
                                                            {teamValue}
                                                        </option>
                                                    </select>
                                                    <span className="flex-c font-14 mt-2">
                                                        You may choose either
                                                        your personal name or
                                                        your team name as your
                                                        display name. Your
                                                        display name is what the
                                                        community will see on
                                                        your profile.
                                                    </span>
                                                    <div className="input-desc red">
                                                        {errors.display &&
                                                            'Please select your display name.'}
                                                    </div>
                                                </div>

                                                <h3 className="mt-4 mb-3">
                                                    Agreements
                                                </h3>

                                                <p className="m-0">
                                                    By creating an account you
                                                    are agreeing to the Cardeon{' '}
                                                    <Link
                                                        className="brand-secondary fw-500"
                                                        to="/privacy"
                                                        title="Privacy Policy">
                                                        Privacy Policy
                                                    </Link>{' '}
                                                    and{' '}
                                                    <Link
                                                        className="brand-secondary fw-500"
                                                        to="/terms"
                                                        title="Terms of Service">
                                                        Terms of Service
                                                    </Link>{' '}
                                                    set forth by Eva, LLC
                                                </p>
                                                <p className="m-0 mt-3">
                                                    I understand Cardeon does
                                                    not process any payments or
                                                    store financial information.
                                                </p>
                                                <p className="m-0 mt-3">
                                                    I understand canceling a
                                                    subscription will only stop
                                                    automatic renewal. Cardeon
                                                    does not issue refunds for
                                                    subscription cancellations.
                                                </p>
                                                <p className="m-0 mt-3">
                                                    I agree to waive Cardeon of
                                                    any wrongdoing that may
                                                    occur as a result of payment
                                                    processing.
                                                </p>

                                                <div className="divider mt-4" />

                                                <div className="flex-c">
                                                    <div
                                                        className={`flex-r align-items-center ml-3 mt-3 ${
                                                            errors.agree
                                                                ? 'mb-0'
                                                                : 'mb-3'
                                                        }`}>
                                                        <input
                                                            type="checkbox"
                                                            aria-required="true"
                                                            {...register(
                                                                'agree',
                                                                {
                                                                    required: true
                                                                }
                                                            )}
                                                            className="m-0"
                                                            id="agree"
                                                            value={agreeValue}
                                                            onChange={e =>
                                                                setAgree(
                                                                    !agreeValue
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            className="pl-2"
                                                            aria-describedby="I agree to all of the above">
                                                            I agree to all of
                                                            the above
                                                        </label>
                                                    </div>
                                                    <div
                                                        className={`input-desc red ml-3 ${
                                                            errors.agree
                                                                ? 'mb-3'
                                                                : 'mb-0'
                                                        }`}>
                                                        {errors.agree &&
                                                            'You must agree to the conditions above.'}
                                                    </div>
                                                </div>

                                                <div className="divider" />
                                                <button
                                                    onClick={handleSubmit(
                                                        providerSignup
                                                    )}
                                                    className="btn btn-secondary mt-4"
                                                    title={
                                                        planSelection ===
                                                        'trial'
                                                            ? 'Start Free Trial'
                                                            : 'Start Subscription'
                                                    }>
                                                    {planSelection === 'trial'
                                                        ? 'Start Free Trial'
                                                        : 'Start Subscription'}
                                                </button>
                                            </form>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                        {tabValue === 'location' && (
                            <>
                                <div className="flex-c row align-items-center mt-2">
                                    <div className="flex-c col-12 flex-auto text-c pt-5">
                                        <div className="flex-r align-items-center mt-4">
                                            <div className="flex-c flex-1 align-items-lg-end">
                                                {initValue && (
                                                    <button
                                                        className="touch touch-pop"
                                                        onClick={goBack}
                                                        type="button"
                                                        title="Cancel">
                                                        <i className="fa-solid fa-arrow-left"></i>
                                                    </button>
                                                )}
                                            </div>
                                            <h1 className="flex-3 text-c">
                                                Location
                                            </h1>
                                            <div className="flex-1" />
                                        </div>
                                        <p className="lh-20 mt-1 mb-3">
                                            Edit your location by providing your
                                            zip code.
                                        </p>
                                    </div>
                                </div>
                                <div className="flex-c row align-items-center">
                                    <div className="flex-c col-md-8 col-lg-6 mt-4">
                                        <form
                                            onSubmit={e => e.preventDefault()}>
                                            <TextInput
                                                type={'number'}
                                                name={'zip'}
                                                label={'Zip Code'}
                                                value={userInfo?.zip}
                                                placeholder={'zip code: 99999'}
                                                onChange={e => {
                                                    zipEval(e.target.value)
                                                }}
                                                maxLength={'5'}
                                                style={'mb-2'}
                                            />
                                            <div className="input-desc gray-800">
                                                Zip codes are exclusively used
                                                for filtering results when
                                                searching for community members.
                                            </div>
                                        </form>
                                        <button
                                            className="btn btn-secondary mt-5"
                                            disabled={!zipValidation}
                                            onClick={() => {
                                                updateZip()
                                            }}
                                            title="Update Location">
                                            Update Location
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                        {tabValue === 'team' && (
                            <>
                                <div className="flex-c row align-items-center mt-2">
                                    <div className="flex-c col-12 flex-auto text-c pt-5">
                                        <div className="flex-r align-items-center mt-4">
                                            <div className="flex-c flex-1 align-items-lg-end">
                                                {initValue && (
                                                    <button
                                                        className="touch touch-pop"
                                                        onClick={goBack}
                                                        type="button"
                                                        title="Cancel">
                                                        <i className="fa-solid fa-arrow-left"></i>
                                                    </button>
                                                )}
                                            </div>
                                            <h1 className="flex-3 text-c">
                                                Team
                                            </h1>
                                            <div className="flex-1" />
                                        </div>
                                        <p className="lh-20 mt-1 mb-3">
                                            Edit your team details.
                                        </p>
                                    </div>
                                </div>
                                <div className="flex-c row align-items-center">
                                    <div className="flex-c col-md-8 col-lg-6 mt-4">
                                        <form
                                            onSubmit={e => e.preventDefault()}>
                                            <TextInput
                                                type={'text'}
                                                name={'organization'}
                                                label={'Organization Name'}
                                                value={inputValue.organization}
                                                placeholder={'my team'}
                                                onChange={e => {
                                                    handleOnChange(e)
                                                    setDisplayName(
                                                        e.target.value
                                                    )
                                                }}
                                            />
                                            <DropDownInput
                                                name={'team'}
                                                label={'Display Name'}
                                                value={displayName}
                                                onChange={e => {
                                                    setDisplayName(
                                                        e.target.value
                                                    )
                                                }}
                                                options={formatData([
                                                    inputValue.organization,
                                                    `${user?.firstname} ${
                                                        user?.lastname &&
                                                        user?.lastname[0]
                                                    }`
                                                ])}
                                                marginBottom={true}
                                            />
                                            <div className="input-desc gray-800">
                                                You may choose either your
                                                personal name or your team name
                                                as your display name. Your
                                                display name is what the
                                                community will see on your
                                                profile.
                                            </div>
                                        </form>
                                        <SubmitButton
                                            title="Update Team"
                                            type="submit"
                                            style="submit"
                                            submitFunction={updateTeam}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        {tabValue === 'blocked' &&
                            BlockedComponent(user?.blocked)}
                        {tabValue === 'subscription' && (
                            <>
                                <div className="flex-c row align-items-center mt-2">
                                    <div className="flex-c col-12 flex-auto text-c pt-5">
                                        <div className="flex-r align-items-center mt-4">
                                            <div className="flex-c flex-1 align-items-lg-end">
                                                {initValue && (
                                                    <button
                                                        className="touch touch-pop"
                                                        onClick={goBack}
                                                        type="button"
                                                        title="Cancel">
                                                        <i className="fa-solid fa-arrow-left"></i>
                                                    </button>
                                                )}
                                            </div>
                                            <h1 className="flex-3 text-c">
                                                {!initValue
                                                    ? 'Subscription'
                                                    : planSelection === 'trial'
                                                    ? 'Start Free Trial'
                                                    : 'Start Subscription'}
                                            </h1>
                                            <div className="flex-1" />
                                        </div>
                                        <p className="lh-20 mt-1 mb-3">
                                            {!initValue
                                                ? 'Review your plan details below.'
                                                : planSelection === 'trial'
                                                ? 'Setup your team to start your free trial.'
                                                : 'Setup your team to start your subscription.'}
                                        </p>
                                    </div>
                                </div>
                                {user?.intent === 'Provider' && (
                                    <div className="flex-c row align-items-center">
                                        <div className="flex-c col-md-8 col-lg-6 mt-4">
                                            <div className="flex-r align-items-center justify-content-between mb-2">
                                                <h3>Promo Code</h3>
                                            </div>
                                            <span className="divider mb-3" />
                                            <div className="flex-r justify-content-between mb-3">
                                                <span className="fw-500">
                                                    Applied Code
                                                </span>
                                                <span>
                                                    {user?.appliedTeamPromo ===
                                                    null
                                                        ? 'None'
                                                        : user?.appliedTeamPromo}
                                                </span>
                                            </div>
                                            <div className="flex-r justify-content-between mb-3">
                                                <span className="fw-500">
                                                    Client Seats
                                                </span>
                                                <span>{user?.cSeats}</span>
                                            </div>
                                            <div
                                                className={`flex-r justify-content-between ${
                                                    user?.appliedTeamPromo ===
                                                    null
                                                        ? 'mb-5'
                                                        : 'mb-3'
                                                }`}>
                                                <span className="fw-500">
                                                    Provider Seats
                                                </span>
                                                <span>{user?.pSeats}</span>
                                            </div>
                                            {user?.appliedTeamPromoState && (
                                                <div
                                                    className={`flex-r justify-content-between ${
                                                        user?.appliedTeamPromo ===
                                                        null
                                                            ? 'mb-3'
                                                            : 'mb-5'
                                                    }`}>
                                                    <span className="fw-500">
                                                        Status
                                                    </span>
                                                    <span>
                                                        {user?.appliedTeamPromoState ===
                                                        true
                                                            ? 'Active'
                                                            : 'Disabled'}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div className="flex-c row align-items-center">
                                    <div className="flex-c col-md-8 col-lg-6">
                                        {subs && !initValue && (
                                            <>
                                                <div className="flex-r align-items-end justify-content-between">
                                                    <h3 className="mb-2">
                                                        Plan Details
                                                    </h3>
                                                    {subs &&
                                                        subs.customerId !==
                                                            null && (
                                                            <a
                                                                onClick={
                                                                    onRefresh
                                                                }
                                                                title="Refresh"
                                                                className="brand-secondary hover fw-500 mb-2">
                                                                Refresh
                                                            </a>
                                                        )}
                                                </div>
                                                <span className="divider mb-3" />
                                            </>
                                        )}

                                        {!subs &&
                                            !initValue &&
                                            user?.trialEverStarted ===
                                                false && (
                                                <div className="flex-c tile tile-trial tile-full mb-4">
                                                    <div className="flex-r align-items-end mb-4">
                                                        <span className="font-20 lh-20 fw-500">
                                                            Free Trial
                                                        </span>
                                                        <span className="font-14 px-1">
                                                            /
                                                        </span>
                                                        <span className="font-14">
                                                            30 Days
                                                        </span>
                                                    </div>
                                                    <div className="flex-r align-items-end mb-2">
                                                        <span className="font-32 lh-32">
                                                            $
                                                        </span>
                                                        <span className="font-40 lh-32">
                                                            0
                                                        </span>
                                                        <span>/month</span>
                                                    </div>
                                                    <div className="flex-r mb-3">
                                                        <span className="fw-500 mr-1">
                                                            10
                                                        </span>
                                                        <span>Seats</span>
                                                    </div>
                                                    <Link
                                                        className="btn btn-white hover"
                                                        href="javascript:void(0);"
                                                        onClick={fireTrial}
                                                        title="Try for Free">
                                                        Try for Free
                                                    </Link>
                                                    <span className="font-14 white text-c pt-3 pb-1">
                                                        No payment information
                                                        required
                                                    </span>
                                                </div>
                                            )}

                                        {!subs && user?.trialEverStarted && (
                                            <>
                                                <div
                                                    className={`flex-r align-items-start justify-content-center blip mb-4 ${
                                                        !user?.trialEverStarted
                                                            ? 'mt-4'
                                                            : ''
                                                    }`}>
                                                    <i className="fa-solid fa-circle-info"></i>
                                                    <span className="pl-2">
                                                        No active subscription
                                                        or trial. Please review
                                                        our available
                                                        subscription options and
                                                        subscribe today!
                                                    </span>
                                                </div>
                                            </>
                                        )}
                                        {mySub !== null && (
                                            <>
                                                <div className="flex-r justify-content-between mb-3">
                                                    <span className="fw-500">
                                                        Name
                                                    </span>
                                                    <span>
                                                        {mySub.status ===
                                                        'trialing'
                                                            ? '30 Day Trial'
                                                            : mySub.name}
                                                    </span>
                                                </div>
                                                <div className="flex-r justify-content-between mb-3">
                                                    <span className="fw-500">
                                                        Client Seats
                                                    </span>
                                                    <span>
                                                        {mySub.status ===
                                                        'trialing'
                                                            ? '10'
                                                            : mySub.seatCount}
                                                    </span>
                                                </div>
                                                <div className="flex-r justify-content-between mb-3">
                                                    <span className="fw-500">
                                                        Price
                                                    </span>
                                                    <span>
                                                        {mySub.status ===
                                                        'trialing'
                                                            ? 'FREE'
                                                            : '$' + mySub.price}
                                                    </span>
                                                </div>
                                                <div className="flex-r justify-content-between mb-3">
                                                    <span className="fw-500">
                                                        Interval
                                                    </span>
                                                    <span>
                                                        {mySub.status !==
                                                        'trialing'
                                                            ? mySub.interval
                                                                  .charAt(0)
                                                                  .toUpperCase() +
                                                              mySub.interval.slice(
                                                                  1
                                                              ) +
                                                              'ly'
                                                            : '1 Month'}
                                                    </span>
                                                </div>
                                                <div className="flex-r justify-content-between mb-3">
                                                    <span className="fw-500">
                                                        Type
                                                    </span>
                                                    <span>
                                                        {mySub.status ===
                                                        'trialing'
                                                            ? 'Trial'
                                                            : mySub.type
                                                                  .charAt(0)
                                                                  .toUpperCase() +
                                                              mySub.type.slice(
                                                                  1
                                                              )}
                                                    </span>
                                                </div>
                                                <div
                                                    className={`flex-r justify-content-between ${
                                                        mySub.currentPeriodStart !==
                                                        null
                                                            ? 'mb-3'
                                                            : ''
                                                    }`}>
                                                    <span className="fw-500">
                                                        Status
                                                    </span>
                                                    <span>
                                                        {mySub.status ===
                                                        'trialing'
                                                            ? 'Active'
                                                            : mySub.status ===
                                                              null
                                                            ? 'Pending Payment'
                                                            : mySub.status
                                                                  .charAt(0)
                                                                  .toUpperCase() +
                                                              mySub.status.slice(
                                                                  1
                                                              ) +
                                                              `${
                                                                  mySub.cancelAtPeriodEnd
                                                                      ? ' / Canceled'
                                                                      : ' / Auto-Renew'
                                                              }`}
                                                    </span>
                                                </div>
                                                {!mySub.status && (
                                                    <Link
                                                        className={`btn btn-secondary mt-4 ${
                                                            !mySub.status
                                                                ? 'mb-4'
                                                                : 'mb-5'
                                                        }`}
                                                        target="_blank"
                                                        to={subs.sessionUrl}
                                                        title="Pay Now">
                                                        Pay Now
                                                    </Link>
                                                )}
                                                {mySub.currentPeriodStart !==
                                                    null && (
                                                    <div
                                                        className={`flex-r justify-content-between ${
                                                            mySub.currentPeriodEnd !==
                                                            null
                                                                ? 'mb-3'
                                                                : ''
                                                        }`}>
                                                        <span className="fw-500">
                                                            {mySub.status ===
                                                            'trialing'
                                                                ? 'Trial '
                                                                : null}
                                                            Started on
                                                        </span>
                                                        <span>
                                                            {subStartDate}
                                                        </span>
                                                    </div>
                                                )}
                                                {mySub.currentPeriodEnd !==
                                                    null && (
                                                    <>
                                                        <div className="flex-r justify-content-between">
                                                            <span className="fw-500">
                                                                {mySub.status ===
                                                                'trialing'
                                                                    ? 'Trial Ends on'
                                                                    : mySub.cancelAtPeriodEnd
                                                                    ? 'Active Through'
                                                                    : 'Renews on'}
                                                            </span>
                                                            <span>
                                                                {mySub.status ===
                                                                'trialing'
                                                                    ? trialEndDate
                                                                    : subEndDate}
                                                            </span>
                                                        </div>
                                                        {mySub.status !==
                                                            'trialing' && (
                                                            <div
                                                                className={`flex-r align-items-center justify-content-center blip ${
                                                                    mySub.cancelAtPeriodEnd
                                                                        ? 'blip-red'
                                                                        : ''
                                                                } mt-4 ${
                                                                    mySub.cancelAtPeriodEnd
                                                                        ? 'mb-5'
                                                                        : ''
                                                                }`}>
                                                                <i className="fa-solid fa-circle-info"></i>
                                                                <span className="pl-2">
                                                                    {mySub.cancelAtPeriodEnd
                                                                        ? `Your plan
                                                                        will
                                                                        automatically
                                                                        end on: `
                                                                        : `Your plan
                                                                        will
                                                                        automatically
                                                                        renew on: `}{' '}
                                                                    <span className="fw-500">
                                                                        {
                                                                            subEndDate
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                                {mySub.status ===
                                                    'trialing' && (
                                                    <div
                                                        className={`flex-r align-items-start justify-content-center blip mt-4 ${
                                                            (!subs &&
                                                                !initValue) ||
                                                            (subs &&
                                                                mySub.status ===
                                                                    'trialing')
                                                                ? 'mb-4'
                                                                : 'mb-5'
                                                        }`}>
                                                        <i className="fa-solid fa-circle-info mt-1"></i>
                                                        <div className="flex-c align-items-start pl-2">
                                                            <span className="mb-2">
                                                                Your trial will
                                                                not renew. Your
                                                                trial will
                                                                automatically
                                                                become inactive
                                                                30 days after
                                                                the start date.
                                                            </span>
                                                            <span>
                                                                Your trial is
                                                                set to expire
                                                                on:{' '}
                                                                <span className="fw-500">
                                                                    {
                                                                        trialEndDate
                                                                    }
                                                                </span>
                                                                .
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                                {mySub.status !== null &&
                                                    mySub.status !==
                                                        'trialing' &&
                                                    subs.customerId !== null &&
                                                    !mySub.cancelAtPeriodEnd && (
                                                        <button
                                                            className="btn btn-cancel mt-3"
                                                            onClick={() => {
                                                                triggerModal(
                                                                    'cancelsub'
                                                                )
                                                            }}
                                                            title="Cancel Subscription">
                                                            Cancel Subscription
                                                        </button>
                                                    )}
                                            </>
                                        )}

                                        {((!subs && !initValue) ||
                                            (subs &&
                                                mySub.status ===
                                                    'trialing')) && (
                                            <Link
                                                className="fw-500 brand-secondary text-c mb-5"
                                                to="/pricing"
                                                title="View Paid Subscriptions">
                                                View Paid Subscriptions
                                            </Link>
                                        )}

                                        {subs && !mySub.status && (
                                            <p
                                                className="fw-500 red mt-0 text-c mb-5"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    triggerModal('removesub')
                                                }}
                                                title="Remove Subscription">
                                                Remove Subscription
                                            </p>
                                        )}

                                        {initValue && (
                                            <form
                                                onSubmit={handleSubmit(
                                                    providerSignup
                                                )}
                                                className="flex-c"
                                                onKeyDown={handleKeyDown}>
                                                <h3 className="my-4">
                                                    Team Details
                                                </h3>

                                                <div className="input-wrapper">
                                                    <label
                                                        className="pl-1"
                                                        aria-describedby="Team Name">
                                                        Team Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        aria-required="true"
                                                        {...register('team', {
                                                            required: true
                                                        })}
                                                        className="form-control mt-2"
                                                        id="team"
                                                        value={teamValue}
                                                        onChange={e =>
                                                            setTeam(
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder="team name"
                                                    />
                                                    <div className="input-desc red">
                                                        {errors.team &&
                                                            'Please provide your team name.'}
                                                    </div>
                                                </div>

                                                <div className="input-wrapper">
                                                    <label
                                                        htmlFor="display"
                                                        className="pl-1"
                                                        aria-describedby="Display Name">
                                                        Display Name
                                                    </label>
                                                    <select
                                                        name="display"
                                                        aria-required="true"
                                                        {...register(
                                                            'display',
                                                            {
                                                                required: true
                                                            }
                                                        )}
                                                        className="form-control mt-2"
                                                        id="display"
                                                        value={displayValue}
                                                        onChange={e =>
                                                            setDisplay(
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder="please select">
                                                        <option
                                                            defaultValue
                                                            disabled
                                                            value="">
                                                            please select
                                                        </option>
                                                        <option
                                                            value={
                                                                user?.firstname +
                                                                user?.lastname
                                                            }>
                                                            {user?.firstname}{' '}
                                                            {user?.lastname}
                                                        </option>
                                                        <option
                                                            value={teamValue}>
                                                            {teamValue}
                                                        </option>
                                                    </select>
                                                    <span className="flex-c font-14 mt-2">
                                                        You may choose either
                                                        your personal name or
                                                        your team name as your
                                                        display name. Your
                                                        display name is what the
                                                        community will see on
                                                        your profile.
                                                    </span>
                                                    <div className="input-desc red">
                                                        {errors.display &&
                                                            'Please select your display name.'}
                                                    </div>
                                                </div>

                                                <h3 className="mt-4 mb-3">
                                                    Agreements
                                                </h3>

                                                <p className="m-0">
                                                    By creating an account you
                                                    are agreeing to the Cardeon{' '}
                                                    <Link
                                                        className="brand-secondary fw-500"
                                                        to="/privacy"
                                                        title="Privacy Policy">
                                                        Privacy Policy
                                                    </Link>{' '}
                                                    and{' '}
                                                    <Link
                                                        className="brand-secondary fw-500"
                                                        to="/terms"
                                                        title="Terms of Service">
                                                        Terms of Service
                                                    </Link>{' '}
                                                    set forth by Eva, LLC
                                                </p>
                                                <p className="m-0 mt-3">
                                                    I understand Cardeon does
                                                    not process any payments or
                                                    store financial information.
                                                </p>
                                                <p className="m-0 mt-3">
                                                    I understand canceling a
                                                    subscription will only stop
                                                    automatic renewal. Cardeon
                                                    does not issue refunds for
                                                    subscription cancellations.
                                                </p>
                                                <p className="m-0 mt-3">
                                                    I agree to waive Cardeon of
                                                    any wrongdoing that may
                                                    occur as a result of payment
                                                    processing.
                                                </p>

                                                <div className="divider mt-4" />

                                                <div className="flex-c">
                                                    <div
                                                        className={`flex-r align-items-center ml-3 mt-3 ${
                                                            errors.agree
                                                                ? 'mb-0'
                                                                : 'mb-3'
                                                        }`}>
                                                        <input
                                                            type="checkbox"
                                                            aria-required="true"
                                                            {...register(
                                                                'agree',
                                                                {
                                                                    required: true
                                                                }
                                                            )}
                                                            className="m-0"
                                                            id="agree"
                                                            value={agreeValue}
                                                            onChange={e =>
                                                                setAgree(
                                                                    !agreeValue
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            className="pl-2"
                                                            aria-describedby="I agree to all of the above">
                                                            I agree to all of
                                                            the above
                                                        </label>
                                                    </div>
                                                    <div
                                                        className={`input-desc red ml-3 ${
                                                            errors.agree
                                                                ? 'mb-3'
                                                                : 'mb-0'
                                                        }`}>
                                                        {errors.agree &&
                                                            'You must agree to the conditions above.'}
                                                    </div>
                                                </div>

                                                <div className="divider" />
                                                <button
                                                    className="btn btn-secondary mt-4"
                                                    onClick={handleSubmit(
                                                        providerSignup
                                                    )}
                                                    title={
                                                        planSelection ===
                                                        'trial'
                                                            ? 'Start Free Trial'
                                                            : 'Start Subscription'
                                                    }>
                                                    {planSelection === 'trial'
                                                        ? 'Start Free Trial'
                                                        : 'Start Subscription'}
                                                </button>
                                            </form>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}

                        {tabValue === 'account' && (
                            <>
                                <div className="flex-c row align-items-center mt-2">
                                    <div className="flex-c col-12 flex-auto text-c pt-5">
                                        <div className="flex-r align-items-center mt-4">
                                            <div className="flex-c flex-1 align-items-lg-end">
                                                {initValue && (
                                                    <button
                                                        className="touch touch-pop"
                                                        onClick={goBack}
                                                        type="button"
                                                        title="Cancel">
                                                        <i className="fa-solid fa-arrow-left"></i>
                                                    </button>
                                                )}
                                            </div>
                                            <h1 className="flex-3 text-c">
                                                Account
                                            </h1>
                                            <div className="flex-1" />
                                        </div>
                                        <p className="lh-20 mt-1 mb-3">
                                            Review your account settings below.
                                        </p>
                                    </div>
                                </div>
                                <div className="flex-c row align-items-center">
                                    <div className="flex-c col-md-8 col-lg-6 mt-4">
                                        {!initValue && (
                                            <>
                                                <h3 className="mb-2 mt-4">
                                                    Details
                                                </h3>
                                                <span className="divider mb-3" />
                                                <div className="flex-r justify-content-between mb-3">
                                                    <span className="fw-500">
                                                        First Name
                                                    </span>
                                                    <span>
                                                        {user?.firstname}
                                                    </span>
                                                </div>
                                                <div className="flex-r justify-content-between mb-3">
                                                    <span className="fw-500">
                                                        Last Name
                                                    </span>
                                                    <span>
                                                        {user?.lastname}
                                                    </span>
                                                </div>
                                                <div className="flex-r justify-content-between mb-3">
                                                    <span className="fw-500">
                                                        Birthday
                                                    </span>
                                                    <span>{birthdayValue}</span>
                                                </div>

                                                <div className="flex-r justify-content-between mb-5">
                                                    <span className="fw-500">
                                                        User Type
                                                    </span>
                                                    <span>{user?.intent}</span>
                                                </div>

                                                <h3 className="mb-2">
                                                    Promotional Emails
                                                </h3>
                                                <span className="divider mb-3" />
                                                <div className="flex-r mb-5">
                                                    <input
                                                        type="checkbox"
                                                        name="emailpromo"
                                                        onChange={() => {
                                                            togglePromoMail(
                                                                !userInfo.emailPromo
                                                            )
                                                        }}
                                                        checked={
                                                            userInfo.emailPromo
                                                        }
                                                    />
                                                    <label
                                                        htmlFor="female"
                                                        className="ml-2">
                                                        {userInfo.emailPromo
                                                            ? 'Subscribed'
                                                            : 'Unsubscribed'}
                                                    </label>
                                                </div>

                                                <h3 className="mb-2">
                                                    Credentials
                                                </h3>
                                                <span className="divider mb-3" />
                                                <div className="flex-r justify-content-between mb-3">
                                                    <span className="fw-500">
                                                        Email
                                                    </span>
                                                    <span>{user?.email}</span>
                                                </div>
                                                <div className="flex-r justify-content-between mb-5">
                                                    <span className="fw-500">
                                                        Password
                                                    </span>
                                                    {user?.isVerified ? (
                                                        <Link
                                                            onClick={() => {
                                                                sendForgetPasswordCode()
                                                            }}
                                                            type="button"
                                                            to={`/forget-password?frm=account&email=${user?.email}`}
                                                            className="brand-secondary hover fw-500">
                                                            Reset Password
                                                        </Link>
                                                    ) : (
                                                        <span>**********</span>
                                                    )}
                                                </div>

                                                <h3 className="mb-2">
                                                    Privacy
                                                </h3>
                                                <span className="divider mb-3" />
                                                <div
                                                    className={`flex-r align-items-center ${
                                                        userInfo.profileVisible
                                                            ? 'mb-4'
                                                            : ''
                                                    }`}>
                                                    <input
                                                        className="m-0"
                                                        type="radio"
                                                        id="showProfile"
                                                        name="profileVisible"
                                                        checked={
                                                            userInfo.profileVisible
                                                        }
                                                        onChange={e => {
                                                            handleUserInfoChange(
                                                                e.target.name,
                                                                !userInfo.profileVisible
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        className="mr-2 ml-2"
                                                        htmlFor="showProfile">
                                                        Visible
                                                    </label>
                                                    <input
                                                        className="m-0 ml-2"
                                                        type="radio"
                                                        id="hideProfile"
                                                        name="profileVisible"
                                                        checked={
                                                            !userInfo.profileVisible
                                                        }
                                                        onChange={e => {
                                                            handleUserInfoChange(
                                                                e.target.name,
                                                                !userInfo.profileVisible
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        className="ml-2"
                                                        htmlFor="hideProfile">
                                                        Hidden
                                                    </label>
                                                </div>
                                                {!userInfo.profileVisible && (
                                                    <div className="flex-r align-items-center justify-content-center blip text-c my-3">
                                                        While hidden, you may
                                                        not search for members,
                                                        but you may message
                                                        existing connections.
                                                    </div>
                                                )}
                                                <button
                                                    className="btn btn-secondary mb-5"
                                                    onClick={() => {
                                                        updatePrivacy()
                                                    }}
                                                    title="Update Privacy">
                                                    Update Privacy
                                                </button>

                                                <div className="flex-r align-items-center justify-content-between mb-2">
                                                    <h3>Status</h3>
                                                    <div className="pill pill-xs bckg-g-l green m-0">
                                                        <span className="display-hide display-sm-flex">
                                                            {user?.status}
                                                        </span>
                                                    </div>
                                                </div>
                                                <span className="divider mb-3" />
                                                <div className="flex-r justify-content-between mb-5">
                                                    <span className="fw-500">
                                                        Account Verified
                                                    </span>
                                                    <span>
                                                        {user?.isVerified ===
                                                        true
                                                            ? 'Verified'
                                                            : 'Unverified'}
                                                    </span>
                                                </div>
                                                <button
                                                    className="btn btn-cancel mt-4"
                                                    onClick={() => {
                                                        setShowDeleteModal(true)
                                                    }}
                                                    title="Delete Account">
                                                    Delete Account
                                                </button>
                                            </>
                                        )}

                                        {initValue && (
                                            <form
                                                onSubmit={handleSubmit(
                                                    providerSignup
                                                )}
                                                className="flex-c"
                                                onKeyDown={handleKeyDown}>
                                                <h3 className="my-4">
                                                    Team Details
                                                </h3>

                                                <div className="input-wrapper">
                                                    <label
                                                        className="pl-1"
                                                        aria-describedby="Team Name">
                                                        Team Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        aria-required="true"
                                                        {...register('team', {
                                                            required: true
                                                        })}
                                                        className="form-control mt-2"
                                                        id="team"
                                                        value={teamValue}
                                                        onChange={e =>
                                                            setTeam(
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder="team name"
                                                    />
                                                    <div className="input-desc red">
                                                        {errors.team &&
                                                            'Please provide your team name.'}
                                                    </div>
                                                </div>

                                                <div className="input-wrapper">
                                                    <label
                                                        htmlFor="display"
                                                        className="pl-1"
                                                        aria-describedby="Display Name">
                                                        Display Name
                                                    </label>
                                                    <select
                                                        name="display"
                                                        aria-required="true"
                                                        {...register(
                                                            'display',
                                                            {
                                                                required: true
                                                            }
                                                        )}
                                                        className="form-control mt-2"
                                                        id="display"
                                                        value={displayValue}
                                                        onChange={e =>
                                                            setDisplay(
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder="please select">
                                                        <option
                                                            defaultValue
                                                            disabled
                                                            value="">
                                                            please select
                                                        </option>
                                                        <option
                                                            value={
                                                                user?.firstname +
                                                                user?.lastname
                                                            }>
                                                            {user?.firstname}{' '}
                                                            {user?.lastname}
                                                        </option>
                                                        <option
                                                            value={teamValue}>
                                                            {teamValue}
                                                        </option>
                                                    </select>
                                                    <span className="flex-c font-14 mt-2">
                                                        You may choose either
                                                        your personal name or
                                                        your team name as your
                                                        display name. Your
                                                        display name is what the
                                                        community will see on
                                                        your profile.
                                                    </span>
                                                    <div className="input-desc red">
                                                        {errors.display &&
                                                            'Please select your display name.'}
                                                    </div>
                                                </div>

                                                <h3 className="mt-4 mb-3">
                                                    Agreements
                                                </h3>

                                                <p className="m-0">
                                                    By creating an account you
                                                    are agreeing to the Cardeon{' '}
                                                    <Link
                                                        className="brand-secondary fw-500"
                                                        to="/privacy"
                                                        title="Privacy Policy">
                                                        Privacy Policy
                                                    </Link>{' '}
                                                    and{' '}
                                                    <Link
                                                        className="brand-secondary fw-500"
                                                        to="/terms"
                                                        title="Terms of Service">
                                                        Terms of Service
                                                    </Link>{' '}
                                                    set forth by Eva, LLC
                                                </p>
                                                <p className="m-0 mt-3">
                                                    I understand Cardeon does
                                                    not process any payments or
                                                    store financial information.
                                                </p>
                                                <p className="m-0 mt-3">
                                                    I understand canceling a
                                                    subscription will only stop
                                                    automatic renewal. Cardeon
                                                    does not issue refunds for
                                                    subscription cancellations.
                                                </p>
                                                <p className="m-0 mt-3">
                                                    I agree to waive Cardeon of
                                                    any wrongdoing that may
                                                    occur as a result of payment
                                                    processing.
                                                </p>

                                                <div className="divider mt-4" />

                                                <div className="flex-c">
                                                    <div
                                                        className={`flex-r align-items-center ml-3 mt-3 ${
                                                            errors.agree
                                                                ? 'mb-0'
                                                                : 'mb-3'
                                                        }`}>
                                                        <input
                                                            type="checkbox"
                                                            aria-required="true"
                                                            {...register(
                                                                'agree',
                                                                {
                                                                    required: true
                                                                }
                                                            )}
                                                            className="m-0"
                                                            id="agree"
                                                            value={agreeValue}
                                                            onChange={e =>
                                                                setAgree(
                                                                    !agreeValue
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            className="pl-2"
                                                            aria-describedby="I agree to all of the above">
                                                            I agree to all of
                                                            the above
                                                        </label>
                                                    </div>
                                                    <div
                                                        className={`input-desc red ml-3 ${
                                                            errors.agree
                                                                ? 'mb-3'
                                                                : 'mb-0'
                                                        }`}>
                                                        {errors.agree &&
                                                            'You must agree to the conditions above.'}
                                                    </div>
                                                </div>

                                                <div className="divider" />
                                                <button
                                                    onClick={handleSubmit(
                                                        providerSignup
                                                    )}
                                                    className="btn btn-secondary mt-4"
                                                    title={
                                                        planSelection ===
                                                        'trial'
                                                            ? 'Start Free Trial'
                                                            : 'Start Subscription'
                                                    }>
                                                    {planSelection === 'trial'
                                                        ? 'Start Free Trial'
                                                        : 'Start Subscription'}
                                                </button>
                                            </form>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}

                        {tabValue === 'profile' && (
                            <div className="flex-c row align-items-center mt-2">
                                <div className="flex-c col-md-8 col-lg-6 pt-5">
                                    <div className="flex-c mt-4">
                                        <div
                                            className={`align-self-center pill pill-sm pill-sm-sq bckg-g-l m-0 ${
                                                user.visible ? 'green' : 'red'
                                            }`}>
                                            <span>
                                                {user?.visible
                                                    ? 'Active'
                                                    : 'Inactive'}
                                            </span>
                                        </div>
                                        <div className="flex-c mt-4">
                                            <div
                                                className="flex-c align-self-center img-user img-user-xl img-frame"
                                                style={{
                                                    backgroundColor:
                                                        user?.gravatar
                                                }}>
                                                {user?.profilemedia ? (
                                                    <img
                                                        src={resolveUrl(
                                                            user?.profilemedia
                                                        )}
                                                        alt={user?.name}
                                                        title="Profile Image"
                                                    />
                                                ) : (
                                                    <div className="flex-r align-items-center justify-content-center flex-grow-1 white fw-500">
                                                        <span className="font-64 fw-500">
                                                            {user?.firstname
                                                                ? user
                                                                      ?.firstname[0]
                                                                : ''}
                                                            {user?.firstname
                                                                ? user
                                                                      ?.lastname[0]
                                                                : ''}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                            <h1 className="flex-3 text-lg-c font-28 mt-3">
                                                {user?.intent == 'Provider' &&
                                                user?.team != null ? (
                                                    <span className="fw-500">
                                                        {user.team?.name}
                                                    </span>
                                                ) : (
                                                    <>
                                                        {user?.firstname}{' '}
                                                        {user?.lastname},{' '}
                                                        <span className="fw-300">
                                                            {user?.age}
                                                        </span>
                                                    </>
                                                )}
                                            </h1>
                                        </div>
                                        <div className="flex-c mt-5">
                                            <span className="fw-500 font-20 mb-3">
                                                Details
                                            </span>
                                            <div className="flex-r">
                                                <div className="flex-r pill pill-sm border-g300">
                                                    <i
                                                        className="fa-solid fa-user-circle font-16"
                                                        title="My User Type"></i>
                                                    <span className="font-14 ml-2">
                                                        {user.intent}
                                                    </span>
                                                </div>
                                                {user.state && (
                                                    <div className="flex-r pill pill-sm border-g300 ml-1">
                                                        <i
                                                            className="fa-solid fa-user-circle font-16"
                                                            title="My Location"></i>
                                                        <span className="font-14 ml-2">
                                                            USA: {user?.state}
                                                        </span>
                                                    </div>
                                                )}
                                                {user?.gender !==
                                                    'undefined' && (
                                                    <div className="flex-r pill pill-sm border-g300 ml-1">
                                                        <i
                                                            className={`fa-solid font-16 ${
                                                                user?.gender ===
                                                                'male'
                                                                    ? 'fa-mars'
                                                                    : 'fa-venus'
                                                            }`}
                                                            title="My Gender"></i>
                                                        <span className="font-14 ml-2">
                                                            {user?.gender}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="flex-c mt-5">
                                            <span className="fw-500 font-20 mb-3">
                                                About
                                            </span>
                                            <div className="basic-wrapper">
                                                {user?.summary
                                                    ? user.summary
                                                    : `Hello! Welcome to my profile, my name is ${user?.firstname}.`}
                                            </div>
                                        </div>

                                        {user?.scoreTeams &&
                                            user?.teamData?.length > 0 &&
                                            user?.intent == 'Client' && (
                                                <div className="flex-c mt-5">
                                                    <span className="fw-500 font-20 mb-3">
                                                        Teams
                                                    </span>
                                                    {user?.team?.map(
                                                        (item, index) => (
                                                            <div
                                                                key={index}
                                                                className={`flex-r align-items-center basic-wrapper ${
                                                                    user.team
                                                                        .length -
                                                                        1 !==
                                                                    index
                                                                        ? 'mb-2'
                                                                        : ''
                                                                }`}>
                                                                <div
                                                                    className="flex-c img-user img-user-md mr-3"
                                                                    style={{
                                                                        backgroundColor:
                                                                            item.gravatar
                                                                    }}>
                                                                    {item.profilemedia ? (
                                                                        <img
                                                                            src={resolveUrl(
                                                                                item.profilemedia
                                                                            )}
                                                                            className="white fw-500"
                                                                            alt={
                                                                                item.initial
                                                                            }
                                                                            title="Profile Image"
                                                                        />
                                                                    ) : (
                                                                        <div className="flex-r align-items-center justify-content-center flex-grow-1 white fw-500">
                                                                            <span>
                                                                                {
                                                                                    item.initial
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <span className="fw-500">
                                                                    {item.name}
                                                                </span>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            )}

                                        {user?.scoreBadges &&
                                            user?.dimensions &&
                                            user?.intent == 'Client' && (
                                                <div className="flex-c mt-5">
                                                    <span className="fw-500 font-20 mb-3">
                                                        Badges
                                                    </span>
                                                    {user?.badges?.map(
                                                        (item, index) => (
                                                            <div
                                                                key={index}
                                                                className={`flex-r align-items-center basic-wrapper ${
                                                                    user.badges
                                                                        .length -
                                                                        1 !==
                                                                    index
                                                                        ? 'mb-2'
                                                                        : ''
                                                                }`}>
                                                                <div className="flex-c img-user img-user-md mr-3">
                                                                    <img
                                                                        src={resolveUrl(
                                                                            item.url
                                                                        )}
                                                                        className="white fw-500"
                                                                        alt={
                                                                            item.initial
                                                                        }
                                                                        title="Profile Image"
                                                                    />
                                                                </div>
                                                                <div className="flex-c">
                                                                    <span className="fw-500 mb-1">
                                                                        {
                                                                            item.box
                                                                        }
                                                                    </span>
                                                                    <span className="font-14">
                                                                        {
                                                                            item.pack
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            )}

                                        {user?.scoreEngagements &&
                                            user?.dimensions !== null &&
                                            user?.intent == 'Client' && (
                                                <div className="flex-c mt-5">
                                                    <span className="fw-500 font-20 mb-3">
                                                        Engagements
                                                    </span>
                                                    <div className="flex-r basic-wrapper">
                                                        <div className="flex-r flex-1 justify-content-center align-items-center">
                                                            <div className="flex-c align-items-center justify-content-center bckg-yellow circle-sm">
                                                                <i
                                                                    className="fa-solid fa-star font-12 white"
                                                                    title="Team Member User Type"></i>
                                                            </div>
                                                            <span className="fw-500 font-18 ml-2 mr-1">
                                                                {
                                                                    user?.cards
                                                                        ?.score
                                                                }
                                                            </span>
                                                            <span className="gray-700 font-18">
                                                                Points
                                                            </span>
                                                        </div>
                                                        <span className="flex-r divider-y bckg-g400"></span>
                                                        <div className="flex-r flex-1 justify-content-center align-items-center">
                                                            <div className="flex-c align-items-center justify-content-center bckg-secondary circle-sm">
                                                                <i
                                                                    className="fa-solid fa-layer-group font-12 white"
                                                                    title="Team Member User Type"></i>
                                                            </div>
                                                            <span className="fw-500 font-18 ml-2 mr-1">
                                                                {
                                                                    user?.cards
                                                                        ?.count
                                                                }
                                                            </span>
                                                            <span className="gray-700 font-18">
                                                                Cards
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                        {user?.scoreEngagements &&
                                            user?.dimensions !== null &&
                                            user?.intent == 'Client' && (
                                                <>
                                                    <div className="flex-c mt-5">
                                                        <span className="fw-500 font-20 mb-3">
                                                            Dimensions
                                                        </span>
                                                    </div>
                                                    <DimensionContent />
                                                </>
                                            )}

                                        {user?.scoreVisible &&
                                            user?.dimensions != null &&
                                            user?.intent == 'Client' && (
                                                <>
                                                    <div className="flex-c mt-5">
                                                        <span className="fw-500 font-20 mb-3">
                                                            Summary
                                                        </span>
                                                    </div>
                                                    <SummaryContent />
                                                </>
                                            )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {modalValue === true && (
                    <div
                        className="modal-background"
                        role="dialog"
                        onClick={onCloseModal}>
                        <div
                            className="col-sm-8 col-md-6 col-lg-4"
                            onClick={e => e.stopPropagation()}
                            role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">
                                        Cancel Subscription
                                    </h5>
                                    <i
                                        className="fa-solid fa-xmark"
                                        onClick={onCloseModal}
                                        title="Close Modal"></i>
                                </div>
                                <div className="flex-c modal-body pb-4">
                                    {modalType === 'cancelsub' ? (
                                        <div>
                                            <p className="lh-24 mb-4">
                                                Your subscription will remain
                                                active until the current pay
                                                period ends. Your subscription
                                                will no longer auto-renew. You
                                                may start a new subscription at
                                                a later time. Would you like to
                                                continue?
                                            </p>
                                        </div>
                                    ) : (
                                        <div>
                                            <p className="lh-24 mb-3">
                                                Are you sure you want to remove
                                                the subscription?
                                            </p>
                                        </div>
                                    )}
                                    <p className="lh-24 fw-500 m-0 mb-1">
                                        This action is final.
                                    </p>
                                </div>
                                <div className="flex-c text-c modal-footer">
                                    <button
                                        className="btn btn-cancel mb-4"
                                        onClick={
                                            modalType === 'cancelsub'
                                                ? onCancelSub
                                                : removeSubscription
                                        }
                                        title={
                                            modalType === 'cancelsub'
                                                ? 'Cancel Subscription'
                                                : 'Remove Subscription'
                                        }>
                                        {modalType === 'cancelsub'
                                            ? 'Cancel Subscription'
                                            : 'Remove Subscription'}
                                    </button>
                                    <a
                                        className="gray-700 fw-500 mb-3"
                                        onClick={onCloseModal}
                                        title="Return">
                                        Return
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {modal === true && <TermsModal />}
                {showDeleteModal && (
                    <DeleteAccountModal
                        showModal={setShowDeleteModal}
                        title={"Confirm delete"}
                        children={
                            <>
                                <p className="lh-24 mt-2 mb-4">
                                    Are you sure you want to delete your
                                    account? This action can not be undone.
                                </p>
                                <button
                                    className="btn btn-cancel mt-3 mb-4"
                                    onClick={deleteSelf}
                                    to={'/account'}
                                    title="Yes, Delete my Account">
                                    Yes, Delete my Account
                                </button>
                                <div className="text-c mb-3">
                                    <a
                                        className="gray-700 hover fw-500"
                                        onClick={e => {
                                            setShowDeleteModal(false)
                                            e.stopPropagation()
                                        }}
                                        title="Return">
                                        Return
                                    </a>
                                </div>
                            </>
                        }
                    />
                )}
            </>
        )
    }
}

export default Account
