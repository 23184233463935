import { createSlice } from '@reduxjs/toolkit'
import {
    getTeam,
    updateProviderTeam,
    applyTeamPromo,
    getSubProviders,
    removeProvider
} from '../../actions/team'

const initialState = {
    loading: false,
    team: {},
    subProviders: []
}

const pendingCase = state => {
    state.loading = true
}

const rejectedCase = (state, action) => {
    state.loading = false
}

const teamSlice = createSlice({
    name: 'team',
    initialState,
    reducers: {},
    extraReducers: builder => {
        // Get team
        builder
            .addCase(getTeam.pending, pendingCase)
            .addCase(getTeam.fulfilled, (state, action) => {
                state.loading = false
                state.team = action.payload
            })
            .addCase(getTeam.rejected, rejectedCase)

        // Update Team
        builder
            .addCase(updateProviderTeam.pending, pendingCase)
            .addCase(updateProviderTeam.fulfilled, (state, action) => {
                state.loading = false
                state.team = action.payload
            })
            .addCase(updateProviderTeam.rejected, rejectedCase)

        // Apply Team Promo
        builder
            .addCase(applyTeamPromo.pending, pendingCase)
            .addCase(applyTeamPromo.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(applyTeamPromo.rejected, rejectedCase)

        // Get sub providers
        builder
            .addCase(getSubProviders.pending, pendingCase)
            .addCase(getSubProviders.fulfilled, (state, action) => {
                state.loading = false
                state.subProviders = action.payload
            })
            .addCase(getSubProviders.rejected, rejectedCase)

        // Remove provider
        builder
            .addCase(removeProvider.pending, pendingCase)
            .addCase(removeProvider.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(removeProvider.rejected, rejectedCase)
    }
})

export default teamSlice.reducer
