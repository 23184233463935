import { createSlice } from '@reduxjs/toolkit'

const selectedPlanSlice = createSlice({
    name: 'planSelection',
    initialState: {
        value: ''
    },
    reducers: {
        selectedPlan: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { selectedPlan } = selectedPlanSlice.actions

export default selectedPlanSlice.reducer
