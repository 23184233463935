import { useNavigate } from 'react-router-dom'

// dev
// export let API = 'https://dev.cardeon.io'

// prod
export let API = 'https://api.cardeon.io'

export function GetHeaders(idToken) {
    return {
        Accept: 'application/json',
        Authorization: `Bearer ${idToken}`
    }
}

export function HandleUIErrors(err) {
    const navigate = useNavigate()
    if (err.message === 401) {
        navigate('/')
    } else {
        navigate('/error')
    }
}

export function HandleErrors(response) {
    if (!response.ok) {
        throw Error(response.status)
    }
    return response
}

export function HandleResponse(response) {
    if (!response.data) {
        throw Error(response.status)
    }
    return response
}
