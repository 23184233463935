import React from 'react'
import { useState } from 'react'
import GeneralModal from '../Modals/generalModal'
import { getImgSrc } from '../../config/helpers'

function BadgeInput({
    title,
    content,
    selectedBadge,
    setSelectedBadge,
    handleFileChange
}) {
    const [showModal, setShowModal] = useState(false)

    return (
        <>
            {showModal && (
                <GeneralModal
                    showModal={setShowModal}
                    title={"Edit Badge"}
                    children={
                        <>
                            <p className="lh-24 mt-2 mb-4">
                                Replace or remove the existing box badge.
                            </p>
                            <label className="btn btn-secondary mt-3 mb-4">
                                <input
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={e => {
                                        handleFileChange(e)
                                        setShowModal(false)
                                    }}
                                />
                                <span>Replace</span>
                            </label>
                            <div className="text-c mb-3">
                                <p
                                    className="gray-700 hover fw-500 m-0"
                                    onClick={() => {
                                        setSelectedBadge(null)
                                        setShowModal(false)
                                    }}
                                    style={{ cursor: 'pointer' }}>
                                    Remove
                                </p>
                            </div>
                        </>
                    }
                />
            )}
            {selectedBadge ? (
                <div className="input-wapper mb-5">
                    <div className="mb-2">
                        <label>{title}</label>
                    </div>
                    <div className="file-upload-done-wrapper bckg-white border-bottom-g300 border-r-6 flex-r justify-content-between align-items-center">
                        <img
                            className="img-file"
                            src={getImgSrc(selectedBadge)}
                            alt="Box badge image"
                            title="Box Badge Image"
                        />
                        <span
                            className="brand-secondary fw-500 hover mr-3"
                            onClick={() => {
                                setShowModal(true)
                            }}
                            title="Edit">
                            Edit
                        </span>
                    </div>
                </div>
            ) : (
                <div className="input-wrapper mb-5">
                    <div className="mb-2">
                        <label>Badge</label>
                    </div>
                    <label className="form-control p-4">
                        <div
                            className="flex-c align-items-center"
                            style={{ cursor: 'pointer' }}>
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                            <i className="fa-solid fa-image brand-secondary font-32"></i>
                            <h3 className="gray-900 mt-3">Box Image</h3>
                            <p className="text-c font-14 lh-20 m-0">
                                {content}
                            </p>
                        </div>
                    </label>
                </div>
            )}
        </>
    )
}

export default BadgeInput
