import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import { appPlans } from '../config/slice/plans.slice'
import { selectedPlan } from '../config/slice/selectedPlan.slice'
import { modalState } from '../config/slice/component.slice'
import { modalConvState } from '../config/slice/modalConversion.slice'
import subsService from '../services/subs.service'
import { toast } from 'react-toastify'
import { SubsFoundModal } from '../components/Modals/subsFound'
import { ConversionModal } from '../components/Modals/conversion'

const title = 'Pricing | Cardeon - Subscription Plans & Free Trials'
const url = 'https://cardeon.io/pricing'
const desc =
    'Pricing subscriptions by seat count. Cardeon provides subscriptions for life coaching by total number of seats.'

function Pricing() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [durationValue, setDuration] = useState('year')
    const plans = useSelector(state => state.plans.value)
    const { loading: userLoading, user: user } = useSelector(
        state => state.user
    )
    const subs = useSelector(state => state.subs.value)
    const modal = useSelector(state => state.modal.value)
    const modalConv = useSelector(state => state.modalConv.value)
    const yearlyPlans = plans && plans.filter(x => x.interval !== 'month')
    const monthlyPlans = plans && plans.filter(x => x.interval !== 'year')

    useEffect(() => {
        async function init() {
            if (!plans) {
                try {
                    const plans = await subsService.getPlans()
                    dispatch(appPlans(plans))
                } catch (e) {
                    navigate('/error')
                }
            }
            setLoading(false)
        }
        init()
    })

    async function startTrial(e) {
        e.preventDefault()
        if (Object.keys(user).length > 0) {
            if (subs || user.trialEverStarted) {
                document.body.classList.add('o-flow-y-hide')
                dispatch(modalState(true))
            } else {
                dispatch(selectedPlan('trial'))
                navigate('/account')
            }
        } else {
            dispatch(selectedPlan('trial'))
            navigate('/signup')
        }
    }

    async function selectPlan(item, e) {
        e.preventDefault()
        if (Object.keys(user).length > 0) {
            if (subs && subs.subscription.status === 'active') {
                document.body.classList.add('o-flow-y-hide')
                dispatch(modalState(true))
            } else if (subs && subs.subscription.status === 'trialing') {
                document.body.classList.add('o-flow-y-hide')
                dispatch(modalConvState(true))
            } else {
                dispatch(selectedPlan(item))
                navigate('/account')
            }
        } else {
            dispatch(selectedPlan(item))
            navigate('/signup')
        }
    }

    const toggle = async () => {
        if (durationValue === 'year') {
            setDuration('month')
        } else {
            setDuration('year')
        }
    }

    if (loading) {
        return (
            <div className="pricing flex-c flex-grow-1 align-items-center justify-content-center bckg-white">
                <span className="gray-600 font-28">
                    <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                </span>
            </div>
        )
    } else {
        return (
            <div className="pricing flex-c flex-grow-1 interior-view">
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={desc} />
                    <link rel="canonical" href={url} />
                    <meta property="og:url" content={url} />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={desc} />
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={desc} />
                </Helmet>
                <div className="flex-c container">
                    <div className="flex-c row">
                        <div className="flex-c col-12 pt-lg-5">
                            <div className="flex-c align-items-center text-c">
                                <h1 className="mt-4 mb-2">Cardeon Pricing</h1>
                                <p className="lh-20 mt-1 mb-1">
                                    All subscriptions automatically renew. Cancel anytime.
                                </p>
                                <p className="lh-20 mt-0">
                                    * Per active user, per month, when paying monthly
                                </p>
                                {/* {plans.length > 0 && (
                                    <a
                                        onClick={toggle}
                                        className="brand-secondary hover fw-500 mb-4">
                                        {durationValue !== 'year'
                                            ? 'View Yearly Plans'
                                            : 'View Monthly Plans'}
                                    </a>
                                )} */}
                            </div>

                            {/* {plans.length > 0 && (
                                <>
                                    {durationValue === 'year' && (
                                        <>
                                            <h3 className="flex-r align-items-center font-28 fw-500 mb-3 mt-3">
                                                Yearly
                                            </h3>
                                            <div className="flex-r flex-wrap">
                                                {[...yearlyPlans]
                                                    .sort((a, b) =>
                                                        a.amount > b.amount
                                                            ? 1
                                                            : -1
                                                    )
                                                    .map((item, index) => (
                                                        <div
                                                            key={index}
                                                            className="flex-c tile tile-sale">
                                                            <div className="flex-c mb-2">
                                                                <span className="font-24 fw-500 mb-1">
                                                                    {item.name}
                                                                </span>
                                                                <div className="flex-r mb-3">
                                                                    <span className="fw-500 brand-secondary mr-1">
                                                                        {
                                                                            item.seats
                                                                        }
                                                                    </span>
                                                                    <span>
                                                                        Seats
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="flex-r align-items-end mb-3">
                                                                <div className="flex-r align-items-start">
                                                                    <span className="font-20">
                                                                        $
                                                                    </span>
                                                                    <span className="font-48 fw-700 lh-40">
                                                                        {
                                                                            item.amount
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <span>
                                                                    /{' '}
                                                                    {
                                                                        item.interval
                                                                    }
                                                                </span>
                                                            </div>
                                                            <span className="font-14 mb-4">
                                                                This plan will
                                                                save you{' '}
                                                                <span className="fw-500 brand-secondary">
                                                                    {
                                                                        item.description
                                                                    }
                                                                </span>{' '}
                                                                annually.
                                                            </span>
                                                            <button
                                                                className="btn btn-secondary mb-2"
                                                                onClick={e =>
                                                                    selectPlan(
                                                                        item,
                                                                        e
                                                                    )
                                                                }
                                                                title="Select">
                                                                Select
                                                            </button>
                                                        </div>
                                                    ))}
                                            </div>
                                        </>
                                    )}

                                    {durationValue === 'month' && (
                                        <>
                                            <h3 className="font-28 fw-500 mb-3 mt-3">
                                                Monthly
                                            </h3>
                                            <div className="flex-r flex-wrap">
                                                {[...monthlyPlans]
                                                    .sort((a, b) =>
                                                        a.amount > b.amount
                                                            ? 1
                                                            : -1
                                                    )
                                                    .map((item, index) => (
                                                        <div
                                                            key={index}
                                                            className="flex-c tile tile-sale">
                                                            <div className="flex-c mb-2">
                                                                <span className="font-24 fw-500 mb-1">
                                                                    {item.name}
                                                                </span>
                                                                <div className="flex-r mb-3">
                                                                    <span className="fw-500 brand-secondary mr-1">
                                                                        {
                                                                            item.seats
                                                                        }
                                                                    </span>
                                                                    <span>
                                                                        Seats
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="flex-r align-items-end mb-3">
                                                                <div className="flex-r align-items-start">
                                                                    <span className="font-20">
                                                                        $
                                                                    </span>
                                                                    <span className="font-48 fw-700 lh-40">
                                                                        {
                                                                            item.amount
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <span>
                                                                    /{' '}
                                                                    {
                                                                        item.interval
                                                                    }
                                                                </span>
                                                            </div>
                                                            <span className="font-14 mb-4">
                                                                Save{' '}
                                                                <span className="fw-500 brand-secondary">
                                                                    {
                                                                        item.description
                                                                    }
                                                                </span>{' '}
                                                                by selecting
                                                                annual billing.
                                                            </span>
                                                            <button
                                                                className="btn btn-secondary mb-2"
                                                                onClick={e =>
                                                                    selectPlan(
                                                                        item,
                                                                        e
                                                                    )
                                                                }>
                                                                Select
                                                            </button>
                                                        </div>
                                                    ))}
                                            </div>
                                        </>
                                    )}
                                </>
                            )} */}

                            {/* {plans.length === 0 && (
                                <div className="flex-r align-items-center justify-content-center blip mt-3">
                                    <i className="fa-solid fa-circle-info"></i>
                                    <span className="pl-2">
                                        Check back soon for available
                                        subscriptions.
                                    </span>
                                </div>
                            )} */}

                            {/* <h3 className="font-28 fw-500 mb-3 mt-5">
                                Free Trial
                            </h3>
                            <div className="flex-c tile tile-trial tile-sale">
                                <div className="flex-c mb-2">
                                    <span className="font-24 fw-500 mb-1">
                                        Free Trial
                                    </span>
                                    <div className="flex-r mb-3">
                                        <span className="fw-500 mr-1">10</span>
                                        <span>Seats</span>
                                    </div>
                                </div>
                                <div className="flex-r align-items-end mb-3">
                                    <div className="flex-r align-items-start">
                                        <span className="font-20">$</span>
                                        <span className="font-48 fw-700 lh-40">
                                            0
                                        </span>
                                    </div>
                                    <span>/ month</span>
                                </div>
                                <span className="font-14 mb-4">
                                    Trial lasts 30 Days.
                                </span>
                                <button
                                    className="btn btn-white fw-600 mb-2"
                                    onClick={startTrial}
                                    title="Try for Free">
                                    Try for Free
                                </button>
                            </div> */}

                            {user.id && (
                                <Link
                                    className="fw-500 brand-secondary mt-3"
                                    to="/account"
                                    title="Continue to my account">
                                    Continue to my account
                                </Link>
                            )}
                        </div>
                    </div>
                    <div className="display-hide flex-lg-r row">
                        <div className="flex-c col-lg-6 col-xl-4 mt-5" />
                        <div className="flex-c col-lg-6 col-xl-4 mt-5">
                            <div className="flex-c align-items-center border-r-16-top bckg-secondary shadow p-2">
                                <p className="white fw-500 lh-20 m-0">Best Value</p>
                            </div>
                        </div>
                        <div className="display-hide flex-xl-r col-lg-6 col-xl-4 mt-5" />
                    </div>
                    <div className="flex-r row mt-5 mt-lg-0">
                        <div className="flex-c col-lg-6 col-xl-4 mb-4 mb-xl-5">
                            <div className="flex-c shadow tile tile-full tile-padded">
                                <p className="font-24 fw-700 m-0 mb-1">Free</p>
                                <p className="lh-20 m-0 mb-2">A quick and easy way to try Cardeon. No payment information required.</p>
                                <div className="flex-r align-items-end mt-4">
                                    <p className="font-36 fw-400 lh-40 m-0">$</p>
                                    <p className="font-40 fw-700 lh-40 m-0">0</p>
                                    <p className="gray-700 lh-20 m-0 mb-1 ml-1">USD/mo</p>
                                </div>
                                <p className="font-14 lh-20 m-0 mt-2 mb-4">$0. Yes, free of charge, no trial period... and we mean it.</p>
                                <Link
                                    type="button"
                                    className="btn btn-white mb-2"
                                    title="Get Started"
                                    // to="/signup"
                                    to="/maintenance">
                                    Get Started
                                </Link>
                                <ul className="mt-4">
                                    <li className="fw-400"><i className="fa-solid fa-check font-14 mr-2"></i>Assessment</li>
                                    <li className="fw-400"><i className="fa-solid fa-check font-14 mr-2"></i>Review current progress</li>
                                    <li className="fw-400"><i className="fa-solid fa-check font-14 mr-2"></i>Account configuration</li>
                                    <li className="fw-400"><i className="fa-solid fa-check font-14 mr-2"></i>Access to all card types</li>
                                    <li className="fw-400"><i className="fa-solid fa-check font-14 mr-2"></i>Limit of <strong>6</strong> cards every 24 hours</li>
                                </ul>
                            </div>
                        </div>
                        <div className="flex-c col-lg-6 col-xl-4 mb-4 mb-xl-5">
                            <div className="flex-c display-lg-hide align-items-center border-r-16-top bckg-secondary shadow p-2">
                                <p className="white fw-500 lh-20 m-0">Best Value</p>
                            </div>
                            <div className="flex-c shadow tile tile-full tile-padded border-r-reset-top">
                                <p className="font-24 fw-700 m-0 mb-1">Full</p>
                                <p className="lh-20 m-0 mb-2">Full access to all standard features. Excludes professional features.</p>
                                <div className="flex-r align-items-end mt-4">
                                    <p className="font-36 fw-400 lh-40 m-0">$</p>
                                    <p className="font-40 fw-700 lh-40 m-0">5</p>
                                    <p className="gray-700 lh-20 m-0 mb-1 ml-1">USD/mo*</p>
                                </div>
                                <p className="font-14 lh-20 m-0 mt-2 mb-4">$4 per active user per month when paying per full year.</p>
                                <Link
                                    type="button"
                                    className="btn btn-secondary border-secondary mb-2"
                                    title="Get Started"
                                    // to="/signup"
                                    to="/maintenance">
                                    Get Started
                                </Link>
                                <ul className="mt-4">
                                    <li className="fw-400"><i className="fa-solid fa-check font-14 mr-2"></i>Includes all free plan features</li>
                                    <li className="fw-400"><i className="fa-solid fa-check font-14 mr-2"></i>Historical progress review</li>
                                    <li className="fw-400"><i className="fa-solid fa-check font-14 mr-2"></i>Invite friends &amp; family to your team</li>
                                    <li className="fw-400"><i className="fa-solid fa-check font-14 mr-2"></i>Share your progress with your team</li>
                                    <li className="fw-400"><i className="fa-solid fa-check font-14 mr-2"></i>Limit of <strong>12</strong> cards every 24 hours</li>
                                </ul>
                            </div>
                        </div>
                        <div className="flex-c col-lg-6 col-xl-4 mb-5">
                            <div className="flex-c shadow tile tile-full tile-padded">
                                <p className="font-24 fw-700 m-0 mb-1">Professional</p>
                                <p className="lh-20 m-0 mb-2">For clinicians: invite, manage and review your client's progress.</p>
                                <div className="flex-r align-items-end mt-4">
                                    <p className="font-36 fw-400 lh-40 m-0">$</p>
                                    <p className="font-40 fw-700 lh-40 m-0">10</p>
                                    <p className="gray-700 lh-20 m-0 mb-1 ml-1">USD/mo*</p>
                                </div>
                                <p className="font-14 lh-20 m-0 mt-2 mb-4">$8 per active user per month when paying per year.</p>
                                <Link
                                    type="button"
                                    className="btn btn-white mb-2"
                                    title="Get Started"
                                    // to="/signup"
                                    to="/maintenance">
                                    Get Started
                                </Link>
                                <ul className="mt-4">
                                    <li className="fw-400"><i className="fa-solid fa-check font-14 mr-2"></i>Clients default to Full plan</li>
                                    <li className="fw-400"><i className="fa-solid fa-check font-14 mr-2"></i>Review clients historical progress</li>
                                    <li className="fw-400"><i className="fa-solid fa-check font-14 mr-2"></i>Invite clients to your team</li>
                                    <li className="fw-400"><i className="fa-solid fa-check font-14 mr-2"></i>Manage your team & clients</li>
                                    <li className="fw-400"><i className="fa-solid fa-check font-14 mr-2"></i>1 team in 1 space</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {modal && <SubsFoundModal />}
                {modalConv && <ConversionModal />}
            </div>
        )
    }
}

export default Pricing
