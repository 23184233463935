import { createAsyncThunk } from '@reduxjs/toolkit'
import teamService from '../services/team.service'

export const getTeam = createAsyncThunk(
    'team/getTeam',
    async (options, { rejectWithValue }) => {
        try {
            const data = await teamService.getTeam()
            options?.callBacks?.success?.(data)
            return data
        } catch (error) {
            options?.callBacks?.err?.()
            return rejectWithValue('An error occured')
        }
    }
)

export const updateProviderTeam = createAsyncThunk(
    'team/updateProviderTeam',
    async (options, { rejectWithValue }) => {
        try {
            const data = await teamService.updateProviderTeam(options.payload)
            options?.callBacks?.success?.(data)
            return data
        } catch (error) {
            options?.callBacks?.err?.()
            return rejectWithValue('An error occured')
        }
    }
)

export const applyTeamPromo = createAsyncThunk(
    'team/applyTeamPromo',
    async (options, { rejectWithValue }) => {
        try {
            const data = await teamService.applyTeamPromo(options.teamPromo)
            options?.callBacks?.success?.(data)
            return data
        } catch (error) {
            options?.callBacks?.err?.(error)
            return rejectWithValue('An error occured')
        }
    }
)
export const getSubProviders = createAsyncThunk(
    'team/getSubProviders',
    async (options, { rejectWithValue }) => {
        try {
            const data = await teamService.getSubProviders()
            options?.callBacks?.success?.(data)
            return data
        } catch (error) {
            options?.callBacks?.err?.()
            return rejectWithValue('An error occured')
        }
    }
)
export const removeProvider = createAsyncThunk(
    'team/removeProvider',
    async (options, { rejectWithValue }) => {
        try {
            const data = await teamService.removeProvider(
                options.teamId,
                options.payload
            )
            options?.callBacks?.success?.(data)
            return data
        } catch (error) {
            options?.callBacks?.err?.()
            return rejectWithValue('An error occured')
        }
    }
)
