import React, { useEffect, useState, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { getMemberSelf, resetPassword } from '../actions/members'
import { userSubs } from '../config/slice/subs.slice'
import { selectedPlan } from '../config/slice/selectedPlan.slice'
import authService from '../services/auth.service'
import memberService from '../services/members.service'
import subsService from '../services/subs.service'
import 'react-toastify/dist/ReactToastify.css'
import { resetUser } from '../config/slice/user.slice'

const title = 'Reset password | Cardeon'
const url = 'https://cardeon.io/signup'
const desc =
    'Reset your Cardeon account password. Start scaling, evolving and enhance your existing wellness coaching services.'

function ResetPassword() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { search } = useLocation()
    let params = new URLSearchParams(search)
    let email = params.get('email')
    let frm = params.get('frm')
    const [loading, setLoading] = useState(false)
    const [passwordValue, setPassword] = useState('')
    const [confirmPasswordValue, setConfirmPassword] = useState('')
    const [passwordRestrictValue, setPasswordRestrict] = useState(false)
    const [confirmPassMatch, setConfirmPassMatch] = useState(false)

    const {
        loading: userLoading,
        user: user,
        code
    } = useSelector(state => state.user)
    const {
        register,
        setValue,
        formState: { errors },
        handleSubmit
    } = useForm()

    const letters = /^[a-zA-Z]+$/
    const lowercase = /([a-z]+)/g
    const capitalize = /([A-Z]+)/g
    const numbers = /([0-9]+)/g
    const characters = /[!@#$%^&*]/g
    const regEmail = /^\w+([\+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    const passLength = passwordValue.length >= 8 ? 1 : 0
    const passLowercase = lowercase.test(passwordValue) ? 1 : 0
    const passUppercase = capitalize.test(passwordValue) ? 1 : 0
    const passNumbers = numbers.test(passwordValue) ? 1 : 0
    const passCharacters = characters.test(passwordValue) ? 1 : 0
    const passOptions =
        passLowercase + passUppercase + passNumbers + passCharacters
    const passCheck = passwordValue.length >= 8 && passOptions >= 3
    const optionsCheck = passOptions >= 3 ? 3 : passOptions
    const progressBar = ((passLength + optionsCheck) / 4) * 100 + '%'

    useEffect(() => {
        if (!email) {
            navigate('/signin')
        }
    }, [])

    const updatePasswordFunc = e => {
        e.preventDefault()
        if (passCheck === false) {
            setPasswordRestrict(true)
            toast.error(
                'The password you provided does not meet the minimum requirements.',
                {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                }
            )
            return
        }
        if (passwordValue !== confirmPasswordValue) {
            setConfirmPassMatch(false)
            return
        }

        setLoading(true)
        const prettyJson = {
            email: email,
            code: code,
            password: passwordValue
        }
        dispatch(
            resetPassword({
                payload: prettyJson,
                callBacks: {
                    success: data => {
                        setLoading(false)
                        toast.success('Password reset successful', {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        })
                        if (frm) {
                            authService.logout().then(() => {
                                navigate('/signin')
                                dispatch(resetUser())
                                dispatch(selectedPlan(''))
                                dispatch(userSubs(''))
                            })
                        }
                        navigate(`/signin`)
                    },
                    err: err => {
                        setLoading(false)
                        toast.error(err?.message ?? 'Password update error', {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        })
                    }
                }
            })
        )
    }

    const handleKeyDown = async event => {
        if (event.key === 'Enter') {
            updatePasswordFunc(event)
        }
    }

    const handlePassMatch = (a, b) => {
        if (a === b) {
            setConfirmPassMatch(true)
        } else {
            setConfirmPassMatch(false)
        }
    }

    if (loading) {
        return (
            <div className="auth flex-c flex-grow-1 align-items-center justify-content-center bckg-white">
                <span className="gray-600 font-28">
                    <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                </span>
            </div>
        )
    } else {
        return (
            <div className="signin flex-c flex-grow-1 interior-view">
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={desc} />
                    <link rel="canonical" href={url} />
                    <meta property="og:url" content={url} />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={desc} />
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={desc} />
                </Helmet>
                <div className="flex-c container">
                    <div className="flex-c row align-items-center">
                        <div className="flex-c col-12 col-sm-8 col-md-6 col-lg-10 pt-5">
                            <div className="flex-c flex-lg-r align-items-lg-center mt-4">
                                <div className="flex-c flex-1">
                                    {frm && (
                                        <Link
                                            className="flex-r align-items-lg-center"
                                            to={`/${frm}`}>
                                            <button
                                                className="touch touch-pop"
                                                type="button"
                                                title="Cancel">
                                                <i className="fa-solid fa-arrow-left"></i>
                                            </button>
                                        </Link>
                                    )}
                                </div>
                                <h1 className="flex-3 text-lg-c mt-3 mt-lg-0">
                                    Reset Password
                                </h1>
                                <div className="flex-1" />
                            </div>
                            <p className="text-lg-c lh-20 mt-1 mb-3">
                                Reset your account password.
                            </p>
                        </div>
                    </div>
                    <div className="flex-c row align-items-center mt-4">
                        <div className="flex-c col-sm-8 col-md-6 col-lg-4">
                            <form
                                onSubmit={handleSubmit(updatePasswordFunc)}
                                className="flex-c"
                                onKeyDown={handleKeyDown}>
                                <div className="input-wrapper mb-1">
                                    <label
                                        className="pl-1"
                                        aria-describedby="Password">
                                        Password
                                    </label>
                                    <input
                                        type="password"
                                        aria-required="true"
                                        {...register('password', {
                                            required: true,
                                            pattern: {
                                                value: passCheck
                                            }
                                        })}
                                        className="form-control mt-2"
                                        id="password"
                                        value={passwordValue}
                                        onChange={e => {
                                            setPassword(e.target.value)
                                            setPasswordRestrict(false)
                                            handlePassMatch(
                                                e.target.value,
                                                confirmPasswordValue
                                            )
                                        }}
                                        placeholder="password"
                                    />
                                    <div
                                        className={`input-desc red ${
                                            errors.password ? 'mb-2' : 'mb-0'
                                        }`}>
                                        {errors.password &&
                                            'Please provide a valid password.'}
                                    </div>
                                    <div
                                        className={`input-desc ${
                                            passwordRestrictValue
                                                ? 'red'
                                                : 'gray-800'
                                        } mb-2`}>
                                        Your password must be at least 8
                                        characters in length and contain at
                                        least 3 of the following 4: Lower case
                                        letters (a-z), Uppercase letters (A-Z),
                                        Numbers(i.e. 0-9) or Special Characters
                                        (e.g. !@#$%^&amp;*)
                                    </div>
                                </div>

                                <div className="progress-bar mb-3">
                                    <div
                                        className="progress-bar-state"
                                        style={{ width: progressBar }}
                                    />
                                </div>

                                <div className="flex-r flex-wrap mb-4">
                                    <div
                                        className={`pill pill-sm ${
                                            passLength
                                                ? 'bckg-s-l'
                                                : 'bckg-g300'
                                        }`}>
                                        {passLength ? (
                                            <i
                                                className={`fa-solid fa-check ${
                                                    passLength
                                                        ? 'brand-secondary'
                                                        : 'gray-800'
                                                }`}></i>
                                        ) : (
                                            <></>
                                        )}
                                        <span
                                            className={`${
                                                passLength
                                                    ? 'brand-secondary'
                                                    : 'gray-800'
                                            } ${passLength ? 'pl-1' : ''}`}>
                                            8+ Characters
                                        </span>
                                    </div>

                                    <div
                                        className={`pill pill-sm ${
                                            passLowercase
                                                ? 'bckg-s-l'
                                                : 'bckg-g300'
                                        }`}>
                                        {passLowercase ? (
                                            <i
                                                className={`fa-solid fa-check ${
                                                    passLowercase
                                                        ? 'brand-secondary'
                                                        : 'gray-800'
                                                }`}></i>
                                        ) : (
                                            <></>
                                        )}
                                        <span
                                            className={`${
                                                passLowercase
                                                    ? 'brand-secondary'
                                                    : 'gray-800'
                                            } ${passLowercase ? 'pl-1' : ''}`}>
                                            Lowercase
                                        </span>
                                    </div>

                                    <div
                                        className={`pill pill-sm ${
                                            passUppercase
                                                ? 'bckg-s-l'
                                                : 'bckg-g300'
                                        }`}>
                                        {passUppercase ? (
                                            <i
                                                className={`fa-solid fa-check ${
                                                    passUppercase
                                                        ? 'brand-secondary'
                                                        : 'gray-800'
                                                }`}></i>
                                        ) : (
                                            <></>
                                        )}
                                        <span
                                            className={`${
                                                passUppercase
                                                    ? 'brand-secondary'
                                                    : 'gray-800'
                                            } ${passUppercase ? 'pl-1' : ''}`}>
                                            Uppercase
                                        </span>
                                    </div>

                                    <div
                                        className={`pill pill-sm ${
                                            passNumbers
                                                ? 'bckg-s-l'
                                                : 'bckg-g300'
                                        }`}>
                                        {passNumbers ? (
                                            <i
                                                className={`fa-solid fa-check ${
                                                    passNumbers
                                                        ? 'brand-secondary'
                                                        : 'gray-800'
                                                }`}></i>
                                        ) : (
                                            <></>
                                        )}
                                        <span
                                            className={`${
                                                passNumbers
                                                    ? 'brand-secondary'
                                                    : 'gray-800'
                                            } ${passNumbers ? 'pl-1' : ''}`}>
                                            Numbers
                                        </span>
                                    </div>

                                    <div
                                        className={`pill pill-sm ${
                                            passCharacters
                                                ? 'bckg-s-l'
                                                : 'bckg-g300'
                                        }`}>
                                        {passCharacters ? (
                                            <i
                                                className={`fa-solid fa-check ${
                                                    passCharacters
                                                        ? 'brand-secondary'
                                                        : 'gray-800'
                                                }`}></i>
                                        ) : (
                                            <></>
                                        )}
                                        <span
                                            className={`${
                                                passCharacters
                                                    ? 'brand-secondary'
                                                    : 'gray-800'
                                            } ${passCharacters ? 'pl-1' : ''}`}>
                                            Special Characters
                                        </span>
                                    </div>
                                </div>
                                <div className="input-wrapper">
                                    <label
                                        className="pl-1"
                                        aria-describedby="Password">
                                        Confirm New Password
                                    </label>
                                    <input
                                        type="password"
                                        aria-required="true"
                                        className="form-control mt-2"
                                        value={confirmPasswordValue}
                                        onChange={e => {
                                            setConfirmPassword(e.target.value)
                                            handlePassMatch(
                                                e.target.value,
                                                passwordValue
                                            )
                                        }}
                                        placeholder="confirm password"
                                    />
                                    {confirmPasswordValue.length > 0 && (
                                        <div
                                            className={`input-desc ${
                                                confirmPassMatch
                                                    ? 'confirm'
                                                    : 'red'
                                            } mb-2`}>
                                            {confirmPassMatch
                                                ? 'Passwords match'
                                                : 'Passwords do not match'}
                                        </div>
                                    )}
                                </div>
                                <button
                                    onClick={updatePasswordFunc}
                                    className="btn btn-secondary mt-2"
                                    title="Update Password">
                                    Update Password
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ResetPassword
