import { createAsyncThunk } from '@reduxjs/toolkit'
import othersService from '../services/others.service'

export const getLookups = createAsyncThunk(
    'others/getLookups',
    async (options, { rejectWithValue }) => {
        try {
            const data = await othersService.getLookups()
            options?.callBacks?.success?.()
            return data
        } catch (error) {
            options?.callBacks?.err?.()
            return rejectWithValue('An error occured')
        }
    }
)
