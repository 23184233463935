import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import authService from '../services/auth.service'
import { resetUser } from '../config/slice/user.slice'
import { userSubs } from '../config/slice/subs.slice'
import { selectedPlan } from '../config/slice/selectedPlan.slice'
import { toast } from 'react-toastify'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { sendConfirmCode, verifyConfirmCode } from '../actions/members'
import { useEffect } from 'react'

const title = 'Verification | Cardeon'
const url = 'https://cardeon.io/signin'
const desc = 'Verify your Cardeon account.'

function Verification() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { search } = useLocation()
    let params = new URLSearchParams(search)
    let email = params.get('email')
    const [inputValue, setInput] = useState('')
    const [loading, setLoading] = useState(false)
    const { loading: userLoading, user: user } = useSelector(
        state => state.user
    )
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm()

    const handleKeyDown = async event => {
        if (event.key === 'Enter') {
            verifyCode()
        }
    }

    useEffect(() => {
        if (email) {
            dispatch(
                sendConfirmCode({
                    payload: { email: email },
                    callBacks: {
                        success: () => {
                            setLoading(false)
                        },
                        err: err => {
                            setLoading(false)
                        }
                    }
                })
            )
        }
    }, [email])

    const verifyCode = async () => {
        if (inputValue.length == 6) {
            setLoading(true)
            const prettyJson = {
                email: user.email,
                code: inputValue
            }

            dispatch(
                verifyConfirmCode({
                    payload: prettyJson,
                    callBacks: {
                        success: data => {
                            setLoading(false)

                            if (data.message == 'Invalid Code') {
                                toast.error('Invalid Code', {
                                    position: 'top-right',
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined
                                })
                            } else {
                                navigate('/content')
                            }
                        },
                        err: err => {
                            setLoading(false)
                        }
                    }
                })
            )
        } else {
            toast.error('Invalid Code', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    }

    const resendCode = async () => {
        if (Object.keys(user).length > 0) {
            setLoading(true)
            const prettyJson = {
                email: user.email
            }

            dispatch(
                sendConfirmCode({
                    payload: prettyJson,
                    callBacks: {
                        success: () => {
                            setLoading(false)
                            toast.success('Code resent', {
                                position: 'top-right',
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined
                            })
                        },
                        err: err => {
                            setLoading(false)
                        }
                    }
                })
            )
        } else {
            navigate('/signin')
        }
    }

    const onLogout = async () => {
        try {
            authService.logout().then(() => {
                navigate('/signin')
                dispatch(resetUser())
                dispatch(selectedPlan(''))
                dispatch(userSubs(''))
            })
        } catch (e) {
            setLoading(false)
            toast.error('Unknown Error', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    }

    return (
        <div className="signin flex-c flex-grow-1 interior-view">
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={desc} />
                <link rel="canonical" href={url} />
                <meta property="og:url" content={url} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={desc} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={desc} />
            </Helmet>
            <div className="flex-c container">
                <div className="flex-c row">
                    <div className="flex-c col-12 pt-5">
                        <div className="flex-c align-items-center">
                            <div className="flex-c text-c">
                                <h1 className="mt-4">Enter Code</h1>
                                <p className="lh-20 mt-1">
                                    A one time passcode was sent to the email
                                    address associated with your account. <br />
                                    Please enter the code to verify your
                                    account.
                                </p>
                                <p className="lh-20 mt-1">
                                    Didn't receive a code?{' '}
                                    <a
                                        className="brand-secondary hover fw-500"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            resendCode()
                                        }}>
                                        Resend Code
                                    </a>
                                </p>
                                <p className="lh-20 mt-1">
                                    Would you like to verify later?{' '}
                                    <a
                                        className="brand-secondary hover fw-500"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            onLogout()
                                        }}>
                                        Sign Out
                                    </a>
                                </p>
                            </div>
                            <div className="flex-c col-sm-8 col-md-6 col-lg-4 mt-4">
                                <form
                                    onSubmit={handleSubmit(verifyCode)}
                                    className="flex-c"
                                    onKeyDown={handleKeyDown}>
                                    <div className="input-wrapper">
                                        <label
                                            className="pl-1"
                                            aria-describedby="Code">
                                            One Time Passcode
                                        </label>
                                        <input
                                            type="text"
                                            {...register('code', {
                                                required: true
                                            })}
                                            className="form-control mt-2"
                                            id="code"
                                            value={inputValue}
                                            onChange={e =>
                                                setInput(e.target.value)
                                            }
                                            placeholder="Code"
                                            required
                                        />
                                        <div className="input-desc red">
                                            {errors.code && 'Invalid code'}
                                        </div>
                                    </div>
                                    <button
                                        onClick={handleSubmit(verifyCode)}
                                        className="btn btn-secondary mt-2">
                                        Continue
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Verification
